import axios from 'axios'

class APIPerformance {

    getUrlDomain(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + nome_metodo;

    }

    async initFilter(user, prodotto) {


        let formData = new FormData();

        formData.append("username", user);
        formData.append("prodotto", prodotto);

        return axios.post(this.getUrlDomain("InvoiceWebPerformanceInitFilterApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }




}

export default new APIPerformance({
    url: "http://emtool.local/public/api/alertduedate/"
})
