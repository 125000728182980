var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"text-center d-flex align-center justify-center",staticStyle:{"max-width":"300px"},attrs:{"fluid":"","fill-height":""}},[_c('v-row',[(_vm.isPwdChangedOnce > 0 && (_vm.viewClienti == 1))?_c('v-col',{staticClass:"mb-15",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":false,"icon":"","id":"no-background-hover","to":"/vediFattureClienti"}},on),[_c('v-img',{staticClass:" ",staticStyle:{"margin-top":"16px"},attrs:{"alt":"Emt logo","width":"60","contain":"","src":require("@/assets/fatture_clienti2.png"),"transition":"scale-transition"}})],1),_c('div',{staticClass:"btnText"},[_vm._v("Fatture Clienti")])]}}],null,false,2979888009)},[_c('span',[_vm._v("Fatture Clienti")])])],1):_vm._e(),(_vm.isPwdChangedOnce > 0 && _vm.isAgila == 1 && _vm.viewClientiAgila == 1)?_c('v-col',{staticClass:"mb-15",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":false,"icon":"","id":"no-background-hover","to":"/vediFattureClientiAgila"}},on),[_c('v-img',{staticClass:" ",staticStyle:{"margin-top":"16px"},attrs:{"alt":"Emt logo","width":"60","contain":"","src":require("@/assets/fatture_clienti2.png"),"transition":"scale-transition"}})],1),_c('div',{staticClass:"btnText"},[_vm._v("Fatture Clienti")])]}}],null,false,311928043)},[_c('span',[_vm._v("Fatture Clienti")])])],1):_vm._e(),(_vm.isPwdChangedOnce > 0 && _vm.viewFornitori == 1)?_c('v-col',{staticClass:"mb-15",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":false,"icon":"","id":"no-background-hover","to":"/vediFattureFornitori"}},on),[_c('v-img',{staticClass:" ",staticStyle:{"margin-top":"16px"},attrs:{"alt":"Emt logo","width":"60","contain":"","src":require("@/assets/fatture_fornitori2.png"),"transition":"scale-transition"}})],1),_c('div',{staticClass:"btnText"},[_vm._v("Fatture Fornitori")])]}}],null,false,2009649605)},[_c('span',[_vm._v("Fatture Fornitori")])])],1):_vm._e(),(_vm.isPwdChangedOnce > 0 && _vm.viewFornFatt == 1 && _vm.isSU == 0)?_c('v-col',{staticClass:"mb-15",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":false,"icon":"","id":"no-background-hover","to":"/vediFornitoriFatture"}},on),[_c('v-img',{staticClass:" ",staticStyle:{"margin-top":"16px"},attrs:{"alt":"Emt logo","width":"60","contain":"","src":require("@/assets/fatture_fornitori2.png"),"transition":"scale-transition"}})],1),_c('div',{staticClass:"btnText"},[_vm._v("Fatture")])]}}],null,false,3091625977)},[_c('span',[_vm._v("Fatture")])])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }