<template>
    <v-container class="w-container-edit-type-doc v-overflow editUtente"
        style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);">


        <v-form style="max-width:500px;" v-model="valid" ref="form" lazy-validation>


            <v-row>

                <v-col cols="12" md="12" xs="12">

                    <v-text-field v-model="document_type.nome" id="nome" label="Nome">
                    </v-text-field>

                </v-col>


            </v-row>

            <v-row>


                <v-col cols="12" md="12">

                    <div id="d_fido_ant"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot"><label for="input-29"
                                        class="v-label v-label--active theme--light"
                                        style="left: 0px; right: auto; position: absolute">Durata (GG)</label>

                                    <vue-autonumeric v-model="document_type.giorni_durata" :options="{
            digitGroupSeparator: '',
            decimalCharacter: ',',
            decimalCharacterAlternative: '',
            currencySymbol: '',
            currencySymbolPlacement: 's',
            roundingMethod: 'U',
            minimumValue: '0',
            decimalPlaces: '0'
        }"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>

                </v-col>

            </v-row>




        </v-form>








    </v-container>
</template>

<script>
import apiusers from "../utils/users/apiusers";
import apibanche from "../utils/banche/apibanche";
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';
import apidocumenti from "../utils/documenti/apidocumenti";

import {
    bus
} from "../main";

import router from ".././router";

import Vue from "vue";
import VModal from 'vue-js-modal';



export default ({
    mounted() {

        /*eslint-disable no-undef*/

        if (window.innerWidth <= 768) {

            $("#btnUserInfo").css("transform", "translateX(25px)");

        }

        //var pointerVue = this;
        this.$root.$children[0].checkLogout();


        this.$root.$children[0].normalDiv1 = true;
        this.$root.$children[0].normalDiv2 = true;

        this.$root.$children[0].invoiceDiv1 = false;
        this.$root.$children[0].invoiceDiv2 = false;
        this.$root.$children[0].invoiceDiv3 = false;
        this.$root.$children[0].invoiceDiv4 = false;
        this.$root.$children[0].invoiceDiv3Agila = false;

        this.$root.$children[0].calcolatorDiv1 = false;

        this.$root.$children[0].nomeAziendaSelezionata = "";

        // var namesurname = window.$cookies.get("namesurname");

        //  this.$root.$children[0].curTitle = namesurname;
        //pointerVue.$root.$children[0].invoiceTitle = "Banche";

        Vue.use(VModal, {
            dialog: true
        });

        /*eslint-disable no-undef*/

        var pointerVue = this;

        //window.$cookies.get("aziende_user")

        this.setupButtons();

        if (this.$route.params.id == 0) {

            this.$root.$children[0].invoiceTitle = "Aggiungi Tipo Documento";


        }

        if (this.$route.params.id > 0) {

            this.$root.$children[0].invoiceTitle = "Modifica Tipo Documento";

            setTimeout(() => {



                this.syncDocumentType();


            }, 100);

        }

        if (this.$route.params.id > 0) {

            this.$root.$children[0].curTitle = "Modifica Utente";

        }

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    this.validate();

                    break;

                case "btn_back":

                    router.push({
                        path: "/documentType"
                    });
                    break;

                default:
                    break;
            }
        });

        $(document).ready(function () {



            $('body').on('click', '.btn-confirm-fido-ant', function (event) {
                console.log(event);

                pointerVue.banca.fido_anticipi = pointerVue.fidoAnt;

                pointerVue.$root.$children[0].setFieldActive("#d_fido_ant");

                pointerVue.$modal.hide('popupFidoAnt');

            });


            $('body').on('click', '.btn-cancel-fido-ant', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupFidoAnt');

            });

            $('body').on('click', '#d_fido_ant', function (event) {
                console.log(event);


                pointerVue.$modal.show('popupFidoAnt');

            });


            $('body').on('click', '#cont_companyid', function (event) {
                console.log(event);

                pointerVue.sheetAziende = true;

            });



            $('body').on('click', '#cont_idmailgroup', function (event) {
                console.log(event);

                pointerVue.sheetGruppi = true;

            });




            $('body').on('click', '#d_importo_user', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupImporto');

            });


            $('body').on('click', '.btn-confirm-massimale', function (event) {
                console.log(event);

                pointerVue.utente.max_easyer = pointerVue.valoreMassimale;

                pointerVue.$root.$children[0].setFieldActive("#d_importo_user");

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '.btn-cancel-massimale', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImporto');

            });






            $('body').on('click', '.btn-confirm-fido-cassa', function (event) {
                console.log(event);

                pointerVue.banca.fido_cassa = pointerVue.fidoCassa;

                pointerVue.$root.$children[0].setFieldActive("#d_fido_cassa");

                pointerVue.$modal.hide('popupFidoCassa');

            });


            $('body').on('click', '.btn-cancel-fido-cassa', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupFidoCassa');

            });

            $('body').on('click', '#d_fido_cassa', function (event) {
                console.log(event);


                pointerVue.$modal.show('popupFidoCassa');

            });





        });

    },

    components: {
        VueAutonumeric,
    },


    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        filteredGruppi() {

            return _.orderBy(this.gruppi.filter(item => {
                if (!this.searchGruppi) return this.gruppi;
                return (item.name.toLowerCase().includes(this.searchGruppi.toLowerCase()));
            }), 'headline')
        },



    },

    data: () => ({


        fidoAnt: null,
        fidoCassa: null,

        sheetGruppi: false,

        searchGruppi: "",

        document_type: {
            nome: "",
            giorni_durata: null
        },

        showPicker2: false,

        valoreMassimale: null,

        changePsw: false,

        titolo: "",
        currDipendenteObj: {},

        gruppi_checked: [],

        isCurrentUserSU: "N",

        aziende: [],
        prefissi: [],

        dipendenti: [],
        currAzienda: "",

        gruppi: [],

        selNuclei: null,

        tab: null,

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        utente: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Cognome richiesto",
        ],

        aziendaRules: [
            (v) => !!v || "Azienda richiesta",
        ],

        usernameRules: [
            (v) => !!v || "Username richiesto",
        ],

        select: null,

        // items: [
        //     'Informazioni Generali', 'Gruppo', 'Autenticazione', 'QR Code'
        // ],

        items: [
            'Informazioni', 'Gruppo', 'Nota Spese'
        ],

        checkbox: false,

        tipo_qrcode: [{
            "id": "USR_PWD",
            "value": "Utente E Password"
        },
        {
            "id": "FCODE_PWD",
            "value": "Utente E Codice Fiscale"
        }
        ]

    }),

    methods: {


        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.banca.companyid = azienda.companyid;

        },

        manageClickGruppi: async function (gruppo) {

            console.log("GRP SEL: ", gruppo);

            this.sheetGruppi = false;

            this.banca.idmailgroup = gruppo.idmailgroup;

        },

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/save_update_data-invoiceweb.png",
                    title: "Salva",
                    width: 30

                },
                {
                    text: "Parenti",
                    icon: "mdi-arrow-left",
                    link: "/parenti",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro",
                    image: "https://app.emtool.eu/public/_lib/img/back-invoiceweb.png",
                    width: 35

                },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var that = this;

            console.log("DOC_TYPE: ", that.document_type);

            var errore = "";

            if (that.document_type.nome == "") {
                errore = "Specificare il nome";
            }

            if (errore == "") {
                if (that.document_type.giorni_durata == "") {
                    errore = "Specificare una durata maggiore di 0 e minore di 100";
                }
            }

            if (errore == "") {
                if (that.document_type.giorni_durata == null) {
                    errore = "Specificare una durata maggiore di 0 e minore di 100";
                }
            }

            if (errore == "") {
                if (that.document_type.giorni_durata > 99) {
                    errore = "Specificare una durata maggiore di 0 e minore di 100";
                }
            }

            if (errore == "") {

                console.log("ok");


                that.saveData();

            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });


            }



        },

        saveData: async function () {

            var that = this;

            console.log("DOC_TYPE: ", that.document_type);

            var v_token = window.$cookies.get("token");

            var response = null;

            if (that.$route.params.id == 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                response = await apidocumenti.insertDocumentType(
                    that.document_type,
                    v_token
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from insertDocumentType", res);

                    if (res.data.Result == "OK") {
                        that.$swal({
                            icon: "success",
                            text: "Dati salvati correttamente",
                            showConfirmButton: false,
                            timer: 2000
                        });

                        setTimeout(() => {

                            that.dialogMsg = false;
                            that.setupButtons();

                            router.push({
                                path: "/documentType"
                            });

                        }, 200);
                    }
                    else {
                        that.$swal({
                            icon: "error",
                            text: res.data.ErrDetails,
                            showConfirmButton: false,
                            timer: 3000
                        });
                    }



                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );

            }

            if (that.$route.params.id > 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                response = await apidocumenti.updateDocumentType(
                    that.document_type,
                    v_token,
                    that.$route.params.id
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from updateDocumentType", res);

                    that.$swal({
                        icon: "success",
                        text: "Dati salvati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        router.push({
                            path: "/documentType"
                        });

                    }, 200);

                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );

            }








        },

        syncUtente: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getUtente(
                that.$route.params.id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getUtente", res);

                try {

                    this.aziende = res.data.aziende;
                    this.prefissi = res.data.prefix;
                    this.gruppi = res.data.models;
                    this.utente = res.data.curr_user;

                    this.utente.codice_fiscale = res.data.val_codice_fiscale;

                    if (res.data.curr_user.models != null) {

                        var grp_selected = res.data.curr_user.models.split(",");

                        console.log("GRP SEL: ", grp_selected);

                        if (grp_selected.length > 0) {

                            for (var i = 0; i < this.gruppi.length; i++) {

                                if (this.gruppi[i] !== undefined) {

                                    for (var x = 0; x < grp_selected.length; x++) {

                                        console.log("GRP SEL 2: ", grp_selected[x]);

                                        if (parseInt(this.gruppi[i].id) == parseInt(grp_selected[x])) {

                                            this.gruppi[i].checked = this.gruppi[i].id;

                                        }

                                    }

                                }

                            }

                        }


                    }




                    $("#itemAzienda").addClass("item-has-value");


                    console.log("USER INFO EASYER: ", res.data.info_easyer);


                    if (res.data.info_easyer != null) {

                        this.utente.enable_easyer = res.data.info_easyer.enable;

                        this.utente.max_easyer = res.data.info_easyer.max_value;

                        this.utente.datestart_easyer = res.data.info_easyer.period_from;

                        this.utente.dateend_easyer = res.data.info_easyer.period_to;


                    }






                    //console.log("ok");

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.createUtenteNewApi(
                v_token,
                "invoice"
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from syncAziende", res);

                try {

                    this.aziende = res.data.aziende;

                    this.prefissi = res.data.prefix;

                    this.gruppi = res.data.models;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        syncMailGroup: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apibanche.getGruppi(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from syncMailGroup", res);

                this.gruppi = res.data.invoice_mail_group;



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



        syncDocumentType: async function () {

            var that = this;

           
            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidocumenti.getDocumentType(
                that.$route.params.id
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getDocumentType", res);

                this.document_type = res.data.Result;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



        syncPrefix: async function () {

            var that = this;

            //var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getPrefix().then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPrefix", res);

                try {

                    this.prefissi = res.data.tel_prefix;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.cl_importo {
    padding-left: 0px !important;
}


.itemAzienda {
    margin-left: 15px;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}


.itemGruppo {
    margin-left: 15px;
}

.headerSheetGruppi {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}





.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.w-container-edit-type-doc {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.editUtente .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}

.editUtente .v-input {
    font-size: 1.2em !important;
}

.editUtente .v-select {
    font-size: 1.2em !important;
}

.editUtente .v-label {
    font-size: 1em !important;
}

.v-tabs:not(.v-tabs--vertical) .v-tab {
    white-space: normal;
    font-size: 12px;
    padding: 0px;
}

@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}

.theme--light.v-tabs>.v-tabs-bar,
.theme--light.v-tabs-items {
    background-color: transparent;
}
</style>
