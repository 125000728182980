<template>
    <div class="cont-table" id="approvazioneGrid">

        <div class="spin">
            <ion-spinner name="crescent"></ion-spinner>
        </div>

        <div class="row cont_tickets cont_approvazione">
            <div class=" " style="margin:0 auto;">
                <div class="card">

                    <div class="card-body" style="padding:0px">

                        <div class="table-responsive" style="">

                            <table id="myTable" style="margin-top:57px">
                                <thead>
                                    <tr>

                                        <th class='all'>
                                            <div class="cl_checkall">
                                                <ion-checkbox name='emailgestoreall' class='checkall' slot='start'>
                                                </ion-checkbox>
                                            </div>
                                        </th>
                                        <th class='all'>
                                            <div class='cl_num'>N.</div>
                                        </th>
                                        <th id="col_data" class='all'>Data</th>
                                        <th class='all'>Utente</th>
                                        <th class='desktop'>Descrizione</th>
                                        <th class='desktop'>Azienda</th>
                                        <th class='desktop'>CDC</th>
                                        <th class='all' style="text-align:right;padding-right:5px">Importo (€)</th>
                                        <th class='all' style="text-align:center">Tipo</th>
                                        <th class='all' style="text-align:center">Stato</th>


                                    </tr>
                                </thead>

                                <tfoot>

                                    <tr>
                                        <th colspan="10">
                                        </th>
                                    </tr>
                                </tfoot>

                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="conttipi">

            <template>
                <div class="text-center">

                    <v-bottom-sheet v-model="sheetTypes">
                        <v-sheet class="text-center" height="530px" style="padding-bottom:100px;">

                            <div style="text-align:right!important">
                                <v-btn class='closeTypesSheet' icon @click="sheetTypes = !sheetTypes">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                            </div>


                            <div class="py-3 c_title_tipi">
                                Tipi
                            </div>

                            <v-list-item v-for="tile in allTypes" :key="tile.title" @click="changeType(tile.title)">
                                <v-list-item-avatar>

                                    <v-avatar :size="tile.sizeAvatar" tile>

                                        <v-img alt="" contain :src="tile.img" :max-width="tile.width"
                                            :max-height="tile.height" />

                                    </v-avatar>

                                </v-list-item-avatar>
                                <v-list-item-title style="text-align:left!important;font-size:9px!important">{{
                                        tile.title
                                }}</v-list-item-title>
                            </v-list-item>

                        </v-sheet>
                    </v-bottom-sheet>

                </div>
            </template>

        </div>

        <div class="contstati">

            <template>
                <div class="text-center">

                    <v-bottom-sheet v-model="sheetStatus">
                        <v-sheet class="text-center" height="430px" style="padding-bottom:100px;">

                            <div style="text-align:right!important">

                                <v-btn class='closeStatusSheet' icon @click="sheetStatus = !sheetStatus">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                            </div>

                            <div class="py-3 c_title_stati">
                                Stati
                            </div>

                            <v-list-item v-for="tile in allStatus" :key="tile.title" @click="filterStatus(tile.title)">
                                <v-list-item-avatar>

                                    <v-avatar size="25px" tile>

                                        <v-img alt="" contain :src="tile.img" max-width="25" max-height="25" />

                                    </v-avatar>

                                </v-list-item-avatar>
                                <v-list-item-title style="text-align:left!important;font-size:9px!important">{{
                                        tile.title
                                }}</v-list-item-title>
                            </v-list-item>

                        </v-sheet>
                    </v-bottom-sheet>

                </div>
            </template>

        </div>

        <div class="contaziende">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Aziende
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetAziende = !sheetAziende">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                        @click="manageClickAziende(item)">

                                        <v-img alt="Clienti Logo" class="  " contain src="@/assets/aziende-invoice.png"
                                            max-width="24" max-height="24" transition="scale-transition" />

                                        <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>

                                    </v-list-item>

                                    <div style="min-height:150px;height:150px">&nbsp;</div>

                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>




        <div class="contcdc">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetCDC" class="vcdcsheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetCDC" fixed style="position:fixed;">
                                CDC
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetCDC = !sheetCDC">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchCDC">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredCDC" :key="item.projectid"
                                        @click="manageClickCDC(item)">

                                        <v-img alt="CDC Logo" class="  " contain
                                            src="@/assets/icon_cdc_easyer_50_drop.png" max-width="24" max-height="24"
                                            transition="scale-transition" />

                                        <v-list-item-title class='itemCDC'>{{ item.projectname }}</v-list-item-title>

                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>




        <!-- <div class="contcdc">

            <template>
                <div class="text-center">

                    <v-bottom-sheet v-model="sheetCDC">
                        <v-sheet class="text-center" height="530px" style="padding-bottom:100px;">

                            <div style="text-align:right!important">

                                <v-btn class='closeCDCSheet' icon @click="sheetCDC = !sheetCDC">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                            </div>


                            <div class="py-3 c_title_cdc">
                                CDC
                            </div>

                            <v-list-item v-for="tile in allCDC" :key="tile.idprojects_cdc"
                                @click="changeCDC(tile.idprojects_cdc)">
                                <v-list-item-avatar>

                                    <v-avatar :size="tile.sizeAvatar" tile>

                                        <v-img alt="" :src="tile.image" contain :max-width="tile.width"
                                            :max-height="tile.height" />

                                    </v-avatar>

                                </v-list-item-avatar>
                                <v-list-item-title style="text-align:left!important;font-size:9px!important">{{
                                        tile.name
                                }}</v-list-item-title>
                            </v-list-item>

                        </v-sheet>
                    </v-bottom-sheet>

                </div>
            </template>

        </div> -->




        <div class="contcambiastati">

            <template>
                <div class="text-center">

                    <v-bottom-sheet v-model="sheetChangeStatus">
                        <v-sheet class="text-center" height="420px" style="padding-bottom:100px;">

                            <div style="text-align:right!important">

                                <v-btn class='closeChangeStatusSheet' icon
                                    @click="sheetChangeStatus = !sheetChangeStatus">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>


                            </div>


                            <div class="py-3 c_title_change_status">
                                Cambia Stato In
                            </div>

                            <v-list-item v-for="tile in allChangeStatus" :key="tile.title"
                                @click="changeStatus(tile.id)">
                                <v-list-item-avatar>

                                    <v-avatar size="25px" tile>

                                        <v-img alt="" contain :src="tile.img" max-width="25" max-height="25" />

                                    </v-avatar>

                                </v-list-item-avatar>
                                <v-list-item-title style="text-align:left!important;font-size:9px!important">{{
                                        tile.title
                                }}</v-list-item-title>
                            </v-list-item>

                        </v-sheet>
                    </v-bottom-sheet>

                </div>
            </template>

        </div>





    </div>
</template>

<script>
import apispese from "../utils/spese/apispese";
import apiapprovazione from "../utils/approvazione/apiapprovazione";

import {
    bus
} from "../main";
import router from ".././router";

/* import $ from 'jquery'; */

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {
        /*     let self = this;
        fetch('https://jsonplaceholder.typicode.com/posts')
          .then(response => response.json())
          .then(json => self.$data.items = json) */

        //var res = apiprofile.getProfile(1,2);

        /*eslint-disable no-undef*/

        var pointerVue = this;

        window.perpage = 30;
        bus.$off("bottomIconClickEvent");

        window.curStatus = 4; // di default stato da approvare

        // var b = window.$cookies.get("b");
        // var $rotta = "";

        pointerVue.$root.$children[0].syncMsg();

        $(document).ready(
            function () {

                $("body").off();

                // window.uname = window.$cookies.get("username");
                // var uname = window.uname;

                // const args = [uname, true, ['bar', 5], {
                //     foo: 'baz'
                // }];
                // try {
                //     console.log("entering user");
                //     window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                //     console.log("passed");

                // } catch (error) {
                //     // alert(error);
                // }

            }
        );

        $(document).ready(function () {

            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });

            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));

                console.log("CHECKALL: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row");

                    $("tr").eq(0).removeClass("active-row");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row");


                }

                pointerVue.hideShowButtonsSelRow();


            });


            $('body').on('mouseover', '#myTable img', function () {

                $(this).addClass('transition');

            });



            $('body').on('mouseout', '#myTable img', function () {


                $(this).removeClass('transition');


            });


            $('body').on('click', '.selstato', function () {

                pointerVue.sheetStatus = true;

            });










        });

        setTimeout(() => {
            this.syncProfile();
        }, 100);
        // console.log (res);


        setTimeout(() => {
            this.syncAziende();
        }, 100);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_delete":

                    this.deleteRow();

                    break;

                case "btn_change_type":

                    this.sheetTypes = true;

                    break;



                case "btn_exit":
                    router.push({
                        path: "/dash"
                    });
                    break;

                case "btn_add":
                    router.push({
                        path: "/editSpesa/0"
                    });
                    break;

                case "btn_edit":


                    console.log("ID: ", window.id_par_sel);


                    router.push({
                        path: "/editSpesaDetailsAdmin/" + window.id_par_sel
                    });


                    break;

                case "btn_download":

                    console.log("CURR ITEM: ", this.currItemRow);

                    var url = this.getUrlFolderDocuments(this.currItemRow.photo_file_name);

                    console.log("URL DOWN: ", url);

                    window.open(url, "_blank");

                    break;


                case "btn_send_for_appr":

                    this.sendForApprove();

                    break;


                case "btn_set_company":

                    this.sheetAziende = true;

                    break;

                case "btn_set_cdc":

                    this.syncCDC();

                    break;

                case "btn_change_status":

                    this.sheetChangeStatus = true;

                    break;

                default:
                    break;

            }
        });

        // this.setupButtons();

        this.setupButtonsGrid();

        //wait loading
        /* var v_token = window.$cookies.get("token");
        var v_company = window.$cookies.get("sel_filter_azienda_utenti"); */
        window.start = 0;
        //console.log("DOMAIN: " + pointerVue.getUrlDomain("getListingRSA"));

        // console.log("HOSTNAME: " + $(location).attr('hostname'));
        // console.log("PROTOCOL: " + $(location).attr('protocol'));
        //var pointerVue = this;

        /*         $.ajax({
                    url: pointerVue.getUrlDomain("getUtenti") + '?username=' + v_token + "&company=" + v_company,
                    data: {

                        currDay: window.currDay,
                        currDayTo: window.currDayTo,
                        curStatus: window.curStatus,
                        curPiano: window.curPiano,
                        start: window.start,
                        length: 30,

                    },
                    success: function (dati) {

                        window.dataset = dati.data;
                        window.loading = 0;
                        window.start = +30;
                        pointerVue.setupGrid();

                    }
                }); */

        /*  window.loading = 0;
         window.start = +30; */
        pointerVue.setupGrid();

        this.hideShowButtons(false);

    },
    data: () => ({

        allTypes: [{
            img: 'https://app.emtool.eu/public/_lib/img/hotel_easyer.png',
            title: 'Hotel',
            "id": 1,
            icon: "mdi-account-lock-open",
            "color": "red",
            width: 32,
            height: 32,
            sizeAvatar: "32px"
        },

        {
            img: 'https://app.emtool.eu/public/_lib/img/carburante_easyer.png',
            title: 'Carburante',
            "id": 2,
            icon: "mdi-account-lock-open",
            "color": "red",
            width: 32,
            height: 32,
            sizeAvatar: "32px"
        },

        {
            img: 'https://app.emtool.eu/public/_lib/img/vitto_easyer.png',
            title: 'Vitto',
            "id": 3,
            icon: "mdi-account-lock-open",
            "color": "red",
            width: 32,
            height: 32,
            sizeAvatar: "32px"
        },

        {
            img: 'https://app.emtool.eu/public/_lib/img/aereo_easyer.png',
            title: 'Viaggio',
            "id": 4,
            icon: "mdi-account-lock-open",
            "color": "red",
            width: 32,
            height: 32,
            sizeAvatar: "32px"

        },

        {
            img: 'https://app.emtool.eu/public/_lib/img/spese/17.png',
            title: 'Noleggio',
            "id": 5,
            icon: "mdi-account-lock-open",
            "color": "red",
            width: 25,
            height: 25,
            sizeAvatar: "25px"

        },

        {
            img: 'https://app.emtool.eu/public/_lib/img/altro_easyer.png',
            title: 'Altro',
            "id": 6,
            icon: "mdi-account-lock-open",
            "color": "red",
            width: 32,
            height: 32,
            sizeAvatar: "32px"

        },

        ],




        allChangeStatus: [

            {
                img: 'https://app.emtool.eu/public/_lib/img/in_invio_easyer.png',
                title: 'Spesa Da Inviare',
                "id": 1,
                icon: "mdi-account-lock-open",
                "color": "red"
            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/approvato_easyer.png',
                title: 'Spesa Approvata',
                "id": 2,
                icon: "mdi-account-lock-open",
                "color": "red"
            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/negato.png',
                title: 'Spesa Negata',
                "id": 3,
                icon: "mdi-account-lock-open",
                "color": "red"
            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/in_approv_easyer.png',
                title: 'Spesa Da Approvare',
                "id": 4,
                icon: "mdi-account-lock-open",
                "color": "red"
            },


        ],

        allStatus: [
            {
                img: 'https://app.emtool.eu/public/_lib/img/filter_all_easyer.png',
                title: 'Tutti',
                "id": 0,
                icon: "mdi-account-lock-open",
                "color": "red"
            },


            {
                img: 'https://app.emtool.eu/public/_lib/img/in_invio_easyer.png',
                title: 'Spesa Da Inviare',
                "id": 1,
                icon: "mdi-account-lock-open",
                "color": "red"
            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/approvato_easyer.png',
                title: 'Spesa Approvata',
                "id": 2,
                icon: "mdi-account-lock-open",
                "color": "red"
            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/negato.png',
                title: 'Spesa Negata',
                "id": 3,
                icon: "mdi-account-lock-open",
                "color": "red"
            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/in_approv_easyer.png',
                title: 'Spesa Da Approvare',
                "id": 4,
                icon: "mdi-account-lock-open",
                "color": "red"
            },


        ],



        aziende: [],

        cdc: [],


        sheetCDC: false,


        sheetStatus: false,

        sheetChangeStatus: false,

        sheetAziende: false,
        search: "",


        searchCDC: "",


        sheetTypes: false,

        showPicker: false,
        profile: {},

        currItemRow: {},

        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    computed: {

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        filteredCDC() {
            return _.orderBy(this.cdc.filter(item => {
                if (!this.searchCDC) return this.cdc;
                return (item.projectname.toLowerCase().includes(this.searchCDC.toLowerCase()));
            }), 'headline')
        },


    },

    methods: {

        changeStatus: async function (status_id) {

            this.sheetChangeStatus = false;

            var that = this;

            var v_token = window.$cookies.get("token");

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });


            console.log("V_TOKEN: ", v_token);
            console.log("ID SEL: ", ids);
            console.log("ID STATUS: ", status_id);


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiapprovazione.changeStatus(
                v_token,
                ids,
                status_id
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from changeStatus", res);

                that.$swal({
                    icon: "success",
                    text: "Stato cambiato correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });


                setTimeout(() => {

                    that.hideShowButtons(false);

                    window.table.ajax.reload();

                    $(".checkall").prop("checked", false);


                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );








        },

        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.setAzienda(azienda.companyid);



        },

        manageClickCDC: async function (cdc) {

            console.log("CDC SEL: ", cdc);

            this.sheetCDC = false;

            this.changeCDC(cdc.projectid);

        },




        getUrlFolderDocuments: function (nome_file) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + "public/easyer/" + nome_file;

        },


        filterStatus: async function (status) {

            console.log(status);

            this.sheetStatus = false;

            var v_filter = 0;

            if (status == "Spesa Da Inviare") {
                v_filter = 1;
            }
            else if (status == "Spesa Approvata") {
                v_filter = 2;
            }
            else if (status == "Spesa Negata") {
                v_filter = 3;
            }
            else if (status == "Spesa Da Approvare") {
                v_filter = 4;
            }


            window.curStatus = v_filter;

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();



        },

        changeType: async function (status) {
            console.log(status);

            this.sheetTypes = false;

            var v_token = window.$cookies.get("token");

            var that = this;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apispese.changeTypeSpese(
                v_token,
                ids,
                status
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from changeTypeSpese", res);

                that.$swal({
                    icon: "success",
                    text: "Tipo cambiato correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });


                setTimeout(() => {

                    that.hideShowButtons(false);

                    window.table.ajax.reload();

                    $(".checkall").prop("checked", false);


                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );




        },


        changeCDC: async function (id_cdc) {
            console.log(status);

            this.sheetCDC = false;

            var v_token = window.$cookies.get("token");

            var that = this;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);
            console.log("ID CDC:", id_cdc);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiapprovazione.changeCDC(
                v_token,
                ids,
                id_cdc
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from changeCDC", res);

                that.$swal({
                    icon: "success",
                    text: "CDC impostato correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });


                setTimeout(() => {

                    that.hideShowButtons(false);

                    window.table.ajax.reload();

                    $(".checkall").prop("checked", false);


                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );




        },





        syncCDC: async function () {

            var id_azienda = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    id_azienda = $(this).attr("val-company-id");

                }

            });

            console.log("ID AZI:", id_azienda);


            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiapprovazione.getCDC(
                v_token,
                id_azienda
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getCDC", res);

                this.cdc = res.data.cdc;


                setTimeout(() => {

                    this.sheetCDC = true;

                }, 200);




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },

        hideShowButtonsSelRow: function () {

            var tot = 0;
            var tot_send_for_appr = 0;
            var tot_send_with_type = 0;
            var is_same_company = 1;
            var old_company_id = -1;
            var tot_send_with_cmp = 0;
            var tot_send_with_cdc = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                    var val_status_id = $(this).attr("val-status-id");

                    if (val_status_id == 1) // stato aperto
                    {

                        tot_send_for_appr = tot_send_for_appr + 1;

                    }

                    var val_type_id = $(this).attr("val-type-id");

                    if (val_type_id != 0) // tipo impostato
                    {

                        tot_send_with_type = tot_send_with_type + 1;

                    }

                    var val_company_id = $(this).attr("val-company-id");

                    if (old_company_id == -1) {

                        old_company_id = val_company_id;

                    }
                    else {

                        if (old_company_id != val_company_id) {

                            is_same_company = 0;

                        }


                    }


                    if (val_company_id != 0) // azienda impostata
                    {

                        tot_send_with_cmp = tot_send_with_cmp + 1;

                    }

                    var val_cdc_id = $(this).attr("val-cdc-id");

                    if (val_cdc_id != 0) // cdc impostato
                    {

                        tot_send_with_cdc = tot_send_with_cdc + 1;

                    }




                }

            });


            console.log("TOT SEL: ", tot);
            console.log("TOT SEL FOR APPR: ", tot_send_for_appr);
            console.log("TOT SEL WITH TYPE: ", tot_send_with_type);
            console.log("IS SAME COMPANY: ", is_same_company);
            console.log("TOT SEL WITH CMP: ", tot_send_with_cmp);
            console.log("TOT SEL WITH CDC: ", tot_send_with_cdc);


            setTimeout(() => {

                var pulsantis = [];



                if (tot == 1) {

                    pulsantis.push({
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/edit-row-invoiceweb.png",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                        title: "Modifica",
                        width: 30

                    });


                    pulsantis.push({
                        text: "",
                        icon: "mdi-download-outline",
                        link: "/download",
                        id: "btn_download",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/download_easyer2.png",
                        // class: "inverted",
                        title: "Scarica",
                        width: 26
                    });


                }

                if (tot > 0) {


                    pulsantis.push({
                        text: "Cambia Tipo",
                        icon: "mdi-refresh",
                        image: "https://app.emtool.eu/public/_lib/img/cambio_stato_easyer.png",
                        // class: "inverted",
                        link: "/info",
                        id: "btn_change_type",
                        disabled: false,
                        title: "Cambia Tipo",
                        width: 28
                    });

                    pulsantis.push({
                        text: "Imposta Azienda",
                        icon: "mdi-refresh",
                        image: "https://app.emtool.eu/public/_lib/img/aziende_easyer.png",
                        link: "/info",
                        id: "btn_set_company",
                        disabled: false,
                        title: "Imposta Azienda",
                        width: 24
                    });

                    if (is_same_company == 1) {

                        if (tot == tot_send_with_cmp) {

                            pulsantis.push({
                                text: "Imposta CDC",
                                icon: "mdi-refresh",
                                image: "https://app.emtool.eu/public/_lib/img/icon_cdc_easyer_50.png",
                                link: "/info",
                                id: "btn_set_cdc",
                                disabled: false,
                                title: "Imposta CDC",
                                width: 22
                            });



                        }



                    }


                    if ((tot == tot_send_with_cmp) && (tot == tot_send_with_cdc)) {


                        pulsantis.push({
                            text: "Cambia Stato",
                            icon: "mdi-refresh",
                            image: "https://app.emtool.eu/public/_lib/img/invia_in_appr_easyer.png",
                            // class: "inverted",
                            link: "/info",
                            id: "btn_change_status",
                            disabled: false,
                            title: "Cambia Stato",
                            width: 28
                        });


                    }



                    pulsantis.push({
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/delete-invoiceweb.png",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        title: "Elimina",
                        width: 30
                    });

                }


                this.$root.$children[0].bnavbuttons = pulsantis;



            }, 100);



        },

        setupGrid: function () {

            // var that = this;
            //  var pathLocal = that.$withBase('/');

            var pointerVue = this;

            var filtri = `<img src='/easyer/img/update-left-rotation.png' style='border:0!important;' class='selaggiorna' title='Aggiorna' /><img src='/easyer/img/Filtro.png' style='border:0!important;' class='selstato' title='Filtro Stato' />`;

            //var dateTest = new Date();
            //dateTest = dateTest.setDate(dateTest.getDate() + 365);
            //const dateTest2 = new Date(dateTest).toJSON().slice(0, 10);

            //console.log("DATE: " + dateTest2) //2015-07-23

            const date = new Date().toJSON().slice(0, 10)

            //console.log("DATE: " + date) //2015-07-23

            //  var $futureTime = dateTest2;



            $(document).ready(function () {

                var v_token = window.$cookies.get("token");
                // var v_company = window.$cookies.get("sel_filter_azienda_utenti");

                window.columns = [

                    {
                        data: 'sel_row',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '0.2%',
                    },

                    {
                        data: 'DT_RowIndex',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '0.2%',
                    },

                    {
                        data: 'created_at',
                        orderable: false,
                        name: 'created_at',
                        width: '0.2%',

                        render: function (data, row) {
                            console.log(data, row);

                            var date = "";
                            try {

                                date = data.substring(0, 10);
                            } catch (error) {
                                console.log(error);
                            }

                            var hour = "";
                            try {

                                hour = data.split(" ")[1].slice(0, 5);
                            } catch (error) {
                                console.log(error);
                            }

                            var html = "<div class='font-row-grid-datetime-mobile'><div class='cl-time'>" + hour + "</div><div class='cl-date'>" + date + "</div></div>";

                            return html;
                        }

                    },

                    {
                        data: 'user_sender',
                        orderable: false,
                        name: 'user_sender',
                        width: '4%'
                    },



                    {
                        data: 'description',
                        orderable: false,
                        name: 'description',
                        width: '4%'
                    },

                    {
                        data: 'fld_name',
                        orderable: false,
                        name: 'fld_name',
                        width: '4%'
                    },

                    // {
                    //     data: 'name',
                    //     orderable: false,
                    //     name: 'name',
                    //     width: '4%'
                    // },

                    {
                        data: 'full_name_prj',
                        orderable: false,
                        name: 'full_name_prj',
                        width: '4%'
                    },

                    {
                        data: 'amount',
                        orderable: false,
                        name: 'amount',
                        width: '2%'
                    },

                    {
                        data: 'tipology',
                        orderable: false,
                        name: 'tipology',
                        width: '0.6%'
                    },

                    {
                        data: 'status',
                        orderable: false,
                        name: 'status',
                        width: '0.6%'
                    },

                ];



                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: false,
                    paging: false,
                    serverSide: false,

                    /*  pageSize: 30, */
                    "sDom": "lfrti",

                    data: window.dataset,

                    ajax: {
                        url: pointerVue.getUrlDomain("EasyERGetSpeseAdminApi") + '?username=' + v_token,
                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start = window.perpage;

                            valori.start = 0;
                            valori.length = window.perpage;
                            valori.currDay = window.currDay;
                            valori.currDayTo = window.currDayTo;
                            valori.curStatus = window.curStatus;
                            valori.curPiano = window.curPiano;

                            var valSearch = $("#myTable_filter input").val();

                            var search = {
                                "value": $("#myTable_filter input").val(),
                                "regex": false
                            };

                            if (valSearch.length > 0) {
                                valori.search = search;
                                valori.columns = window.columns;
                                console.log(window.columns);
                            }

                            window.valori = valori;
                            return valori;

                        }

                    },


                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        //$('.p_amount').number(true, 2, ',', '.');

                        setTimeout(() => {

                            pointerVue.showSpinner = false;
                            pointerVue.$root.$children[0].showProgress = false;

                        }, 1000);



                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {

                            //   $("#myTable_filter").width($("#myTable").width());

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();

                        // $('.p_valore').number(true, 1, ',', '.');
                        // $('.p_totale').number(true, 2, ',', '.');
                    }

                });

                //Events
                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();
                            var valori = window.valori;
                            valori.start = window.start;

                            $.ajax({
                                url: pointerVue.getUrlDomain("EasyERGetSpeseAdminApi") + '?username=' + v_token,
                                data: window.valori,
                                success: function (data) {
                                    $(".spin").hide();
                                    window.loading = 0;
                                    window.start += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log(index, val);
                                        window.table.row.add(val).draw(false);
                                        //  window.dataset.push(val);
                                    }
                                    console.log(data.data);
                                },
                            });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                //alert (pathLocal);

                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    <div class="text_title">Approvazione</div>
                      ` + filtri + `

                </div>
            `);

                $('body').on('click', '.selaggiorna', function () {

                    pointerVue.hideShowButtons(false);

                    window.table.ajax.reload();

                });

                $('body').on('click', '.inviamessaggio', function () {

                    console.log("EVENT INVIAMESSAGGIO");

                    var tot = 0;
                    var ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }

                            ids = ids + $(this).attr("data-id");

                            tot = tot + 1;

                        }

                    });

                    console.log("IDS: ", ids);

                    if (tot > 0) {

                        window.$cookies.set("id_users_doc_send", ids, "9y");

                        router.push({
                            path: "/sendMessage"
                        });

                    }

                    if (tot == 0) {

                        pointerVue.$swal({
                            icon: "error",
                            text: "Select the users to send the message to",
                            showConfirmButton: false,
                            timer: 2000
                        });


                    }



                });



                $('body').on('click', '.paginate_button', function () {
                    $(".spin").show();

                });

                $('body').on('click', '.refreshButton', function () {

                    $(".spin").show();
                    window.table._fnDraw();

                });


                $('body').on('click', '.cont_approvazione tr', function () {

                    console.log("TR-ACTIVE");

                    setTimeout(() => {

                        var row_data = window.table.row(this).data();

                        if (row_data != null) {

                            pointerVue.currItemRow = row_data;

                            var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            var id_sel = $(this).find(".id_row").attr("data-id");


                            var set_cls_active = 0;

                            if (last_id_sel == "undefined") {
                                set_cls_active = 1;
                            } else {
                                if (last_id_sel != id_sel) {
                                    set_cls_active = 1;
                                }
                            }

                            console.log("ROM IS ACTIVE: ", set_cls_active);



                            console.log("THIS: ", this);

                            var hasClassActive = $(this).hasClass("active-row");

                            if (hasClassActive) {

                                console.log("ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", false);

                                $(this).removeClass("active-row");

                            }

                            if (!hasClassActive) {

                                console.log("NOT ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", true);

                                $(this).addClass("active-row");



                            }

                            window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            if (window.id_par_sel == undefined) {
                                window.id_par_sel = id_sel;
                            }

                            console.log("ID SELECTED: ", window.id_par_sel);

                            pointerVue.hideShowButtonsSelRow();


                        }



                    }, 100);

                });





                $('body').on('click', '.val_status', function (event) {
                    event.stopPropagation();

                    var checked = $(this).is(":checked");

                    console.log("CHECKED: ", checked);

                    var v_row = $(this).parent().parent();

                    console.log("V_ROW: ", v_row);

                    if (checked) {

                        $(v_row).addClass("active-row");

                        var item = window.table.row(v_row).data();

                        console.log("ITEM ROW: ", item);


                        window.id_par_sel = item.id;


                    }

                    if (!checked) {

                        $(v_row).removeClass("active-row");

                    }

                    pointerVue.hideShowButtonsSelRow();

                });



                // }

                //setup ionic calendar top
                setTimeout(function () {

                    $("body").on("ionChange", ".dateBook", function (opt) {

                        console.log(opt);
                        var date = this.value;
                        //var dateMysql = date.toJSON().slice(0, 19).replace('T', ' ')   ;
                        dateMysql = date.substr(0, 10);

                        console.log(dateMysql);

                        $.ajax({
                            url: "{{ route('smartbook.updateDate') }}",
                            data: {
                                "id": $(this).data("id"),
                                "date": dateMysql
                            },
                        });

                    });

                    $(".selectstatusall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curStatus = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    $(".selectpianiall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curPiano = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    window.currDay = date;
                    window.currDayTo = date;

                }, 600);

                window.parentiAddedEvents = true;

            });

        },


        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var pulsantis = [];

            


                if (v_enable == true) {

                    pulsantis = [

                   

                        {
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/usr__NM__icon_edit_row_16.png",
                            class: "inverted",
                            link: "/info",
                            id: "btn_edit",
                            disabled: false,
                            title: "Modifica",
                            width: 22

                        },

                        {
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/usr__NM__icon_delete_32.png",
                            class: "inverted",
                            link: "/info",
                            id: "btn_delete",
                            disabled: false,
                            title: "Elimina",
                            width: 22

                        },



                    ];

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/xbox-cross.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                    },

                    {
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/usr__NM__icon_edit_row_16.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                    },

                    {
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/usr__NM__icon_delete_32.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                    },

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/qrconfirm",
                        id: "btn_qrcode",
                        image: "https://app.smart-book.it/public/smartbook/ICON_3.png",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                    {
                        text: "Pdf",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/sb-export-pdf-50.png",
                        class: "inverted",
                        link: "/pdf",
                        id: "btn_pdf",
                        disabled: false,
                    },

                ];

                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },


        sendForApprove: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.$swal

                .fire({
                    title: "Manda In Approvazione",
                    html: "Vuoi mandare in approvazione gli elementi selezionati ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_invoiceweb.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_invoiceweb.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });

                        console.log("IDS SEL:", ids);



                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;



                        var response = await apispese.sendForAppr(
                            v_token,
                            ids
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from sendForAppr", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati inviati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.hideShowButtons(false);

                                $(".checkall").prop("checked", false);

                                that.$root.$children[0].syncMsg();


                                window.table.ajax.reload();


                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },


        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiapprovazione.getAziende(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getAziende", res);


                this.aziende = res.data.aziende;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },


        setAzienda: async function (id_azienda) {

            var v_token = window.$cookies.get("token");

            var that = this;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);
            console.log("ID AZIENDA:", id_azienda);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;



            var response = await apiapprovazione.setAzienda(
                v_token,
                ids,
                id_azienda
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from setAzienda", res);

                that.$swal({
                    icon: "success",
                    text: "Azienda impostata correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.hideShowButtons(false);

                    $(".checkall").prop("checked", false);



                    window.table.ajax.reload();


                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },


        deleteRow: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.$swal

                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare gli elementi selezionati ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_invoiceweb.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_invoiceweb.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });

                        console.log("IDS SEL:", ids);



                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;



                        var response = await apispese.deleteMultiSpese(
                            v_token,
                            ids
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteMultiSpese", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.hideShowButtons(false);

                                $(".checkall").prop("checked", false);

                                that.$root.$children[0].syncMsg();

                                window.table.ajax.reload();


                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },

    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.itemAzienda {
    margin-left: 15px;
}

.itemCDC {
    margin-left: 15px;
}

.c_title_tipi {
    text-align: left !important;
    margin-left: 44px !important;
}

.c_title_stati {
    text-align: left !important;
    margin-left: 44px !important;
}

.c_title_cdc {
    text-align: left !important;
    margin-left: 44px !important;
}

.c_title_change_status {
    text-align: left !important;
    margin-left: 44px !important;
}


.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetCDC {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}


.selaggiorna {
    max-width: 25px;
    min-height: 25px;
    margin-left: 13px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.selstato {
    max-width: 25px;
    min-height: 25px;
    margin-left: 13px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}


.transition {
    -webkit-transform: scale(2.5);
    -moz-transform: scale(2.5);
    -o-transform: scale(2.5);
    transform: scale(2.5);
}


.contstati v-bottom-sheet.v-dialog {

    align-self: flex-end !important;
    margin-top: 79px !important;
}

#approvazioneGrid .closeStatusSheet {
    position: absolute;
    right: 0;
}

#approvazioneGrid .closeChangeStatusSheet {
    position: absolute;
    right: 0;
}

#approvazioneGrid .closeCDCSheet {
    position: absolute;
    right: 0;
}



#approvazioneGrid .closeTypesSheet {
    position: absolute;
    right: 0;
}

#approvazioneGrid .v-application .text-center {

    overflow: auto;
}

#approvazioneGrid .v-list-item {
    margin-left: 20px;
}

#approvazioneGrid .v-list-item__title {
    font-size: 12px;
}

#approvazioneGrid .v-list-item {
    min-height: 42px;
}

#approvazioneGrid .v-list-item__icon {
    margin: 10px 0px;
}


.cont-table .spin {

    display: flex;
    position: absolute;
    z-index: 100;
    min-width: 100vw;
    min-height: 100vh;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
}

.dataTable tbody tr,
.dataTable tbody tr td {
    vertical-align: middle;
    padding: 6px !important;
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #f5cf2e;

}

.pay a {
    color: #f5cf2e;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #f5cf2e;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.table-responsive {
    max-width: 100vw;
}

ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    background-image: url(/smartb/img/FiltroNucleo.png);
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);
}

.cont_approvazione #myTable {
    margin-right: 3px;
    width: 900px !important;
}

.cont_approvazione table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_approvazione table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
}

.cont_approvazione th {
    padding-left: 5px !important;
}

.cont_approvazione ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_approvazione .table-responsive {
    padding: 0px;
    min-width: 80vw;
    max-width: 96vw;
}

.cont_approvazione #myTable_filter {
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
}

.cont_approvazione div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cont_approvazione #myTable_wrapper {
    min-width: initial;
    min-width: 1000px;
    max-width: 1000px;
    width: 1000px;
}


.cont_approvazione .text_title {
    background-color: #0000b9;
    color: white;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    font-size: 10px;
    min-width: 50px;
    text-align: left;
}

.cont_approvazione .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}

.swal2-styled.swal2-cancel {
    border: 0 !important;
    border-radius: .25em !important;
    background: initial !important;
    background-color: #fff !important;
    color: #fff !important;
    font-size: 1em !important;
}

.swal2-styled.swal2-confirm {
    background-color: #fff !important;
    color: black !important;
    background: initial !important;
}


.cont_approvazione .cl_checkall {
    padding-left: 1px;
    padding-top: 2px;
}

.cont_approvazione .checkall {
    width: 13px;
    height: 13px;
}

.cont_approvazione .inviamessaggio {
    max-width: 25px;
    min-height: 25px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.cont_approvazione .cont_buttons {
    display: flex;
    align-items: center;
    float: left;
    min-width: 50px;
    padding-left: 10px;
    justify-content: inherit;

}


.cont_approvazione .font-row-grid-datetime-mobile {
    font-size: 10px !important;
    padding: 5px !important;
    width: 64px !important;
    padding-left: 0px !important;
    height: 10px;
}

.cl-time {
    margin-top: -15px;
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cont_approvazione .cl_importo {
    text-align: right;
}

.cont_approvazione .cl_tipology {
    text-align: center;
    padding-left: 5px;
}

#content_attachment {
    width: 50px !important;
    /* margin: 25px !important; */
    margin-right: 25px !important;
    margin-left: 25px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;

}

#content_attachment {
    -webkit-transition: all .4s ease-in-out !important;
    -moz-transition: all .4s ease-in-out !important;
    -o-transition: all .4s ease-in-out !important;
    -ms-transition: all .4s ease-in-out !important;
}


@media screen and (max-width: 960px) {


    .cont_approvazione .cl_cdc {
        display: inline;
    }

    .cont_approvazione .val_status {
        margin-top: -7px !important
    }

    .cont_approvazione table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
    .cont_approvazione table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
        padding-left: 36px !important;
    }

    .cont_approvazione .cl_checkall {
        padding-left: 30px;
        padding-top: 2px;
    }


    .cont_approvazione .font-row-grid-datetime-mobile2 {
        font-size: 10px !important;
        padding: 5px !important;
        width: 82px !important;
        padding-left: 19px !important;
        height: 10px;
        display: inline-block;
    }


    .cont_approvazione .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_approvazione #myTable {
        /* margin-top:54px; */
    }

    .cont_approvazione #myTable_filter {
        top: 56px;
        position: fixed;

    }

}

@media screen and (max-width: 1024px) {

    .contIcon {
        display: inherit;
    }

    .cont_approvazione .table-responsive {
        padding: 0px;
        min-width: 80vw;
        max-width: 96vw;
        left: 0px;
        position: fixed;
    }

    .cont_approvazione #myTable {
        max-width: 100vw !important;
        margin-right: 0px;
        min-width: 100vw !important;
        position: relative;
        width: 100vw !important;
    }

    .cont_approvazione #myTable_wrapper {
        min-width: initial;
        min-width: 100vw !important;
        max-width: 100vw !important;
        width: 100vw !important;
        overflow-x: hidden !important;
    }

    .cont_approvazione .cl_num {
        margin-left: -3px !important;
    }


}
</style>
