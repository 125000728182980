import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIDocumenti {


  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }


  async insertDocumentType(documento, user)
  {

    let formData = new FormData();

    formData.append("username",  user);
  
    for ( var key in documento ) {
      formData.append(key, documento[key]);
   }

    return axios.post(this.getUrlDomain("insertDocumentType"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async getDocumentType(v_id)
  {

    let formData = new FormData();

    formData.append("v_id",  v_id);
  
    return axios.post(this.getUrlDomain("getDocumentType"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async updateDocumentType(documento, user, v_id)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("v_id",  v_id);
  
    for ( var key in documento ) {
      formData.append(key, documento[key]);
   }

    return axios.post(this.getUrlDomain("updateDocumentType"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async deleteDocumentTyoe(v_id)
  {

    let formData = new FormData();

    formData.append("v_id",  v_id);
  
    return axios.post(this.getUrlDomain("deleteDocumentTyoe"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async getTipi(v_user)
  {

    let formData = new FormData();

    formData.append("v_user",  v_user);
  
    return axios.post(this.getUrlDomain("getTipi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async insertDocument(documento, user)
  {

    let formData = new FormData();

    formData.append("username",  user);
  
    for ( var key in documento ) {
      formData.append(key, documento[key]);
   }

    return axios.post(this.getUrlDomain("insertDocument"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async getDocumento(v_id, v_user)
  {

    let formData = new FormData();

    formData.append("v_user",  v_user);
    formData.append("v_id",  v_id);
  
    return axios.post(this.getUrlDomain("getDocumento"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async updateDocumento(documento, user, v_id)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("v_id",  v_id);
  
    for ( var key in documento ) {
      formData.append(key, documento[key]);
   }

    return axios.post(this.getUrlDomain("updateDocumento"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async deleteDocument(v_id)
  {

    let formData = new FormData();

    formData.append("v_id",  v_id);
  
    return axios.post(this.getUrlDomain("deleteDocument"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async getTipiWithAll(v_user)
  {

    let formData = new FormData();

    formData.append("v_user",  v_user);
  
    return axios.post(this.getUrlDomain("getTipiWithAll"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


}

export default new APIDocumenti({
  url: "http://emtool.local/public/api/smartbook/"
})
