import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIUnitaDiMisura {


  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async getGruppi(user)
  {

    let formData = new FormData();

    formData.append("username",  user);

    return axios.post(this.getUrlDomain("InvoiceWebBancheGetGruppi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async insertUDM(udm, user)
  {

    let formData = new FormData();

    formData.append("username",  user);
  
    for ( var key in udm ) {
      formData.append(key, udm[key]);
   }


    return axios.post(this.getUrlDomain("InvoiceWebUDMInsert"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }



  async getUDM(id, user, prodotto)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("id",  id);
    formData.append("prodotto",  prodotto);

    return axios.post(this.getUrlDomain("InvoiceWebEditUDM"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async updateUDM(udm, user, id)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("id",  id);
  
    for ( var key in udm ) {
      formData.append(key, udm[key]);
   }


    return axios.post(this.getUrlDomain("InvoiceWebUDMUpdate"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async deleteMultiUDM(user, id)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("id",  id);
  

    return axios.post(this.getUrlDomain("InvoiceWebUDMDelete"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


}

export default new APIUnitaDiMisura({
  url: "http://emtool.local/public/api/smartbook/"
})
