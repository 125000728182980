var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"w-container-edit-type-doc v-overflow editUtente",staticStyle:{"padding-top":"59px","background-color":"rgb(255 255 255 / 50%)"}},[_c('v-form',{ref:"form",staticStyle:{"max-width":"500px"},attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","xs":"12"}},[_c('v-text-field',{attrs:{"id":"nome","label":"Nome"},model:{value:(_vm.document_type.nome),callback:function ($$v) {_vm.$set(_vm.document_type, "nome", $$v)},expression:"document_type.nome"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted",attrs:{"id":"d_fido_ant"}},[_c('div',{staticClass:"v-input__control"},[_c('div',{staticClass:"v-input__slot"},[_c('div',{staticClass:"v-text-field__slot"},[_c('label',{staticClass:"v-label v-label--active theme--light",staticStyle:{"left":"0px","right":"auto","position":"absolute"},attrs:{"for":"input-29"}},[_vm._v("Durata (GG)")]),_c('vue-autonumeric',{attrs:{"options":{
        digitGroupSeparator: '',
        decimalCharacter: ',',
        decimalCharacterAlternative: '',
        currencySymbol: '',
        currencySymbolPlacement: 's',
        roundingMethod: 'U',
        minimumValue: '0',
        decimalPlaces: '0'
    }},model:{value:(_vm.document_type.giorni_durata),callback:function ($$v) {_vm.$set(_vm.document_type, "giorni_durata", $$v)},expression:"document_type.giorni_durata"}})],1)]),_c('div',{staticClass:"v-text-field__details"},[_c('div',{staticClass:"v-messages theme--light"},[_c('div',{staticClass:"v-messages__wrapper"})])])])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }