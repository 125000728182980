import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIValuta {


  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async getGruppi(user)
  {

    let formData = new FormData();

    formData.append("username",  user);

    return axios.post(this.getUrlDomain("InvoiceWebBancheGetGruppi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async insertValuta(valuta, user)
  {

    let formData = new FormData();

    formData.append("username",  user);
  
    for ( var key in valuta ) {
      formData.append(key, valuta[key]);
   }


    return axios.post(this.getUrlDomain("InvoiceWebValuteInsert"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }



  async getValuta(id, user, prodotto)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("id",  id);
    formData.append("prodotto",  prodotto);

    return axios.post(this.getUrlDomain("InvoiceWebEditValuta"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async updateValuta(valuta, user, id)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("id",  id);
  
    for ( var key in valuta ) {
      formData.append(key, valuta[key]);
   }


    return axios.post(this.getUrlDomain("InvoiceWebValutaUpdate"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async deleteMultiValuta(user, id)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("id",  id);
  

    return axios.post(this.getUrlDomain("InvoiceWebValutaDelete"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


}

export default new APIValuta({
  url: "http://emtool.local/public/api/smartbook/"
})
