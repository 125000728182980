<template>
    <div class="w-container-spesa v-overflow editUtente editSpesa"
        style="padding-top: 84px;background-color: rgb(255 255 255 / 50%);">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

                <div class="mainTitle" style="
                background-color: #0000b9;
                color: white;
                font-weight: bold;
                padding: 4px;
                font-size: 10px;
                min-width:100vw">{{ titolo }}</div>

            </v-col>

        </v-row>






        <div style="max-width:100%;" lazy-validation>




            <!--             <div v-if="showCamera" class="contCamera">

                <Camera ref="camera" />

            </div> -->

            <div v-if="showForm" class="contForm">

                <v-container class="w-container-users v-overflow editUtente"
                    style="background-color: rgb(255 255 255 / 50%);">


                    <v-form id="mainForm" style="max-width:100vw" v-model="valid" ref="form" lazy-validation>


                        <v-row>
                            <v-col cols="12" md="12" xs="12">
                                <div id="preview">

                                </div>


                                <v-col cols="12" md="12">

                                    <span class="files">
                                        <label style="margin-left:11px;font-size:11px"> </label>
                                        <input type="file" id="input-file-logo" name="logo" accept=".png,.jpg,.jpeg"
                                            class="dropify" data-max-width="6610" data-max-height="6200" />
                                    </span>

                                </v-col>


                                <img id="imgPreview" />
                            </v-col>
                        </v-row>

                        <v-row>

                            <v-col cols="12" md="12" xs="12">

                                <v-col cols="12" md="12">

                                    <v-menu v-model="showPicker" :close-on-content-click="false"
                                        transitions="scale-transition" offset-y max-width="290px" min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field id="fldInpData" v-model="spese.date" label="Data"
                                                persistent-hint readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="spese.date" no-title @input="showPicker = false"
                                            locale="it"></v-date-picker>
                                    </v-menu>

                                </v-col>

                            </v-col>



                        </v-row>

                        <v-row>
                            <v-col cols="12" md="12" xs="12">
                                <v-col cols="12" md="12">
                                    <v-text-field id="fldPiva" v-model="spese.piva" label="Partiva Iva" readonly>
                                    </v-text-field>
                                </v-col>
                            </v-col>
                        </v-row>

                        <v-row>

                            <v-col cols="12" md="12" xs="12">

                                <div id="d_importo"
                                    class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                    <div class="v-input__control">
                                        <div class="v-input__slot">
                                            <div class="v-text-field__slot"><label for="input-29"
                                                    class="v-label v-label--active theme--light"
                                                    style="left: 0px; right: auto; position: absolute">Importo</label>

                                                <vue-autonumeric v-model="spese.amount" :disabled="true"
                                                    id="fldInpAmount" :options="{
                                                        digitGroupSeparator: '.',
                                                        decimalCharacter: ',',
                                                        decimalCharacterAlternative: '.',
                                                        currencySymbol: '\u00a0€',
                                                        currencySymbolPlacement: 's',
                                                        roundingMethod: 'U',
                                                        minimumValue: '0'
                                                    }"></vue-autonumeric>

                                            </div>
                                        </div>

                                        <div class="v-text-field__details">
                                            <div class="v-messages theme--light">
                                                <div class="v-messages__wrapper"></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </v-col>

                        </v-row>




                    </v-form>
                </v-container>
            </div>




        </div>



        <div class="contpopupimporto">

            <template>
                <modal name="popupImporto" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Importo</label>

                            <div id="d_importo_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreImporto" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldImporto" id="fldImporto"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-importo" contain
                                    src="@/assets/btn_cancel3_invoiceweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition" />

                                <v-img alt="" class="btn-confirm-importo" contain
                                    src="@/assets/btn_confirm3_invoiceweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


    </div>
</template>

<script>

/* eslint-disable */
import { createWorker, PSM, OEM } from 'tesseract.js';
const worker = createWorker({
    logger: m => console.log(m),
});

import apiusers from "../utils/users/apiusers";
import apispese from "../utils/spese/apispese";

import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';

import {
    bus
} from "../main";

import router from ".././router";

import Vue from "vue";
import VModal from 'vue-js-modal';



/* import Camera from "./qrcode.vue" */


export default ({
    mounted() {

        this.$root.$children[0].checkLogout();

        Vue.use(VModal, {
            dialog: true
        });

        /*eslint-disable no-undef*/

        var pointerVue = this;


        this.setupButtons();


        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save_preview_image":
                    this.saveImage();
                    break;

                case "btn_back":
                    router.push({
                        path: "/spese"
                    });
                    break;



                case "btn_reco":
                    var hasImage = false;
                    try {

                        hasImage = $(".dropify-render img").attr("src").length;

                    } catch (error) {

                    }
                    if (hasImage) {


                        // this.recognizeWs();
                        this.recognize();

                    }
                    else {
                        this.$swal({
                            icon: "error",
                            text: "Per favore inserire un'immagine",
                            showConfirmButton: false,
                            timer: 2000
                        });

                    }


                    break;



                case "btn_save":

                    this.validate();

                    break;

                default:
                    break;
            }
        });

        $(document).ready(function () {




            setTimeout(() => {

                var oggDrop = $('.dropify').dropify({
                    messages: {
                        default: 'Drag',
                        replace: /* s */ '',
                        remove: 'Rimuovi',
                        error: 'File troppo grande'
                    }
                });

                $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
                $(".dropify-render").append("<img>");

                //console.log(oggDrop);

                oggDrop.on('dropify.afterClear', function (event, element) {

                    console.log(event, element);

                    pointerVue.attachRemove = true;
                    pointerVue.imageB64 = "";

                    pointerVue.spese.date = "";
                    pointerVue.spese.piva = "";

                    $("#fldPiva").val("");
                    $("#fldInpData").val("");
                    $("#fldInpAmount").val("");


                    console.log("pointerVue.spese", pointerVue.spese);

                    pointerVue.setupButtons();

                    document.getElementById("imgPreview").src = "";

                });

            }, 100);

            $('#input-file-logo').on('change', async function () {

                pointerVue.attachRemove = false;

                const imageFile = document.querySelector('#input-file-logo').files[0];

                //Resize start
                var reader = new FileReader();
                reader.onload = function (e) {
                    var img = document.createElement("img");
                    img.onload = function (event) {
                        // Dynamically create a canvas element
                        var canvas = document.createElement("canvas");

                        // var canvas = document.getElementById("canvas");
                        var ctx = canvas.getContext("2d");
                        ctx.mozImageSmoothingEnabled = false;
                        ctx.webkitImageSmoothingEnabled = false;
                        ctx.msImageSmoothingEnabled = false;
                        ctx.imageSmoothingEnabled = false;


                        var width = 600;
                        var height = 600;


                        // Actual resizing
                        canvas.width = width;
                        canvas.height = height;
                        ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, width, height);


                        // Show resized image in preview element
                        var dataurl = canvas.toDataURL(imageFile.type);
                        document.getElementById("imgPreview").src = dataurl;
                    }
                    img.src = e.target.result;
                }

                setTimeout(() => {
                    pointerVue.setupButtons();
                }, 600);


                reader.readAsDataURL(imageFile);


                //Resize end

                /*           const result = await pointerVue.toBase64(imageFile);
          
                          console.log(result);
                          pointerVue.imageB64 = result;
          
                          document.getElementById("imgPreview").src = result; */


                //pointerVue.recognize();



            });




            $('body').on('click', '#d_importo', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupImporto');

            });


            $('body').on('click', '.btn-confirm-importo', function (event) {
                console.log(event);

                pointerVue.spese.amount = pointerVue.valoreImporto;

                pointerVue.$root.$children[0].setFieldActive("#d_importo");

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '.btn-cancel-importo', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImporto');

            });





        });

    },

    components: {
        VueAutonumeric,
        /*   Camera */
    },


    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },

    data: () => ({

        showPicker: false,

        imageB64: "",
        result: "",
        valoreImporto: null,

        showCamera: true,

        showForm: true,

        showPicker2: false,

        valoreMassimale: null,

        changePsw: false,

        titolo: "Aggiungi Spesa",
        currDipendenteObj: {},

        gruppi_checked: [],

        aziende: [],
        prefissi: [],

        spese: {},

        dipendenti: [],
        currAzienda: "",

        gruppi: [],

        selNuclei: null,

        tab: null,

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        utente: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Cognome richiesto",
        ],

        aziendaRules: [
            (v) => !!v || "Azienda richiesta",
        ],

        usernameRules: [
            (v) => !!v || "Username richiesto",
        ],

        select: null,



        items: [
            'Informazioni', 'Gruppo', 'Nota Spese'
        ],

        checkbox: false,

        tipo_qrcode: [{
            "id": "USR_PWD",
            "value": "Utente E Password"
        },
        {
            "id": "FCODE_PWD",
            "value": "Utente E Codice Fiscale"
        }
        ]

    }),

    methods: {


        toBase64: function (file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });

        },



        getTotal: function (text) {

            var candidate = 0.0;
            var stringArray = text.split(/(\s+)/);

            for (var i in stringArray) {
                var actual = stringArray[i].replace(",", ".");
                var part = parseFloat(actual);
                //    console.log ( "part",part ); 
                if (part > candidate && actual.includes(".")) candidate = part;
            }

            var formated = candidate.toFixed(2);
            console.log("ar", formated);
            this.spese.amount = formated;




            var piva = "";
            var pivaTest = /^[0-9]{11}$/


            candidate = "";
            for (var i in stringArray) {

                var actual = stringArray[i];

                if (pivaTest.test(actual)) {
                    piva = actual;
                    console.log("piva", piva);

                    this.spese.piva = piva;

                }


                if ((actual.includes("-") || actual.includes("/")) && (actual.length == 10 || actual.length == 8)) candidate = actual;
            }


            var itaDate = "";
            var sign = "-";
            var arr = [];

            if (candidate.includes("-")) sign = "-";
            if (candidate.includes("/")) sign = "/";

            if (candidate.length > 0) {
                if (candidate.includes(sign)) {
                    var arr = candidate.split(sign);

                    var year = arr[2];
                    if (year.length == 2) {
                        year = "20" + year;
                    }
                    itaDate = year + "-" + arr[1] + "-" + arr[0]

                }
            }


            if (arr.length != 3) itaDate = "";

            $("#fldInpData").val(itaDate)
            this.spese.date = itaDate;




        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },

        recognizeWs: async function () {


            var pointerVue = this;
            var data = $("#imgPreview").attr("src");
            var data = $("#imgPreview").attr("src");
            console.log(" Data : ", data);


            this.$root.$children[0].showProgress = true;
            $.ajax({
                method: "POST",
                url: this.getUrlDomain("EasyEROcrApi"),
                data: {
                    "data": data

                },
                success: function (data) {
                    var text = data.res;


                    var tot = pointerVue.getTotal(text);
                    var acapo = text.replace(/(?:\r\n|\r|\n)/g, '<br>');
                    document.getElementById("preview").innerHTML = acapo;


                    pointerVue.$root.$children[0].showProgress = false;


                }
            });



        },


        recognize: async function () {

            this.$root.$children[0].showProgress = true;


            setTimeout(() => {

                $(".v-progress-circular__info").text("Searching Date");

            }, 100);

            const img = document.getElementById('imgPreview');

            console.log(img);
            await worker.load();
            await worker.loadLanguage('ita');
            await worker.initialize('ita', OEM.LSTM_ONLY);
            await worker.setParameters({
                tessedit_pageseg_mode: PSM.SINGLE_BLOCK,
            });

            var part = {
                rectangle: { top: 200, left: 0, width: 410, height: 200 },
            };



            setTimeout(() => {

                $(".v-progress-circular__info").text("Searching Total");

            }, 5000);

            const { data: { text } } = await worker.recognize(img);


            var tot = this.getTotal(text);
            var acapo = text.replace(/(?:\r\n|\r|\n)/g, '<br>');
            document.getElementById("preview").innerHTML = acapo;
            console.log(text);
            this.$root.$children[0].showProgress = false;



        },







        saveImage: async function () {

            var isCamera = null;

            var that = this;

            try {
                isCamera = this.$refs.camera.getImage();

            } catch (error) {
                console.log(error);
            }

            if (isCamera != null) {
                var dataImage = await this.$refs.camera.getImage();

                this.showCamera = false;

                that.spese.amount = null;
                that.valoreImporto = null;

                $("#fldInpAmount").val("");

                var img = document.getElementById("imgPreview");
                img.src = dataImage;
                console.log(dataImage);


                this.recognize();



                this.setupButtons();

            }
            else {
                console.log("No camera");
            }




        },

        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.utente.idcompany_logo = azienda.companyid;

        },


        setupButtons: async function () {

            setTimeout(() => {
                var hasImage = $(".dropify-render img").length;

                var pulsantis = [];

                pulsantis.push({
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/save_update_data-invoiceweb.png",
                    title: "Salva",
                    width: 30

                });

                /*   if ( hasImage == 1 )
                  { */
                pulsantis.push
                    (
                        {
                            text: "Salva",
                            icon: "mdi-content-save",
                            link: "/save",
                            id: "btn_reco",
                            disabled: false,
                            image: "https://app.emtool.eu/public/_lib/img/scontrino_easyer2.png",
                            //class: "inverted",
                            title: "Riconosci",
                            width: 22

                        });
                /*   } */


                /*   } */


                pulsantis.push({
                    text: "Parenti",
                    icon: "mdi-arrow-left",
                    link: "/parenti",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro",
                    image: "https://app.emtool.eu/public/_lib/img/back-invoiceweb.png",
                    width: 35


                });

                this.$root.$children[0].bnavbuttons = pulsantis;


            }, 100);

        },

        verifyAttachmentIsImage: function (content) {

            var elements = content.split(",");

            console.log("ELE: ", elements);

            var result = false;

            if (elements[0].indexOf("image") > 0) {
                result = true;
            }

            return result;

        },

        validate() {

            var that = this;

            var img = document.getElementById("imgPreview");

            console.log("SPESE: ", that.spese);

            console.log("DATA: ", $("#fldInpData").val());

            var errore = "";

            if (img.src == "") {
                errore = "Specificare un'allegato";
            }

            if (errore == "") {

                if ($("#fldInpData").val() == "") {

                    errore = "Specificare la data";

                }

            }


            if (errore == "") {

                if ($("#fldInpAmount").val() == "") {

                    errore = "Specificare l'importo";

                }

            }


            if (errore == "") {

                this.saveData();

            }
            else {


                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });

            }







        },

        saveData: async function () {

            var that = this;

            console.log("SPESE: ", that.spese);

            var img = document.getElementById("imgPreview");

            console.log("FOTO: ", img.src);

            var v_token = window.$cookies.get("token");


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apispese.validateInsertSpese(
                v_token,
                that.spese.date,
                that.spese.amount
            ).then(async (res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from validateInsertSpese", res);

                if (res.data.Result == "OK") {

                    console.log("OK");


                    that.showSpinner = true;
                    that.$root.$children[0].showProgress = true;

                    var response2 = await apispese.insertSpese(
                        that.spese,
                        img.src,
                        v_token
                    ).then((res2) => {

                        that.$root.$children[0].showProgress = false;
                        console.log("res from insertSpese", res2);

                        that.$swal({
                            icon: "success",
                            text: "Dati salvati correttamente",
                            showConfirmButton: false,
                            timer: 2000
                        });

                        setTimeout(() => {


                            router.push({
                                path: "/spese"
                            });

                        }, 200);

                    }).catch(err2 => {
                        that.$root.$children[0].showProgress = false;
                        console.log(err2);
                        var msg = err2.response.data.Error;

                        that.$swal({
                            icon: "error",
                            text: msg,
                            showConfirmButton: false,
                            timer: 8000
                        });
                        console.log("Errori", "Non è stato possibile salvare i dati");
                        console.log("response", response);

                    }

                    );







                }

                if (res.data.Result == "ERROR") {

                    console.log("ERROR");

                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });



                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );






            // that.showSpinner = true;
            // that.$root.$children[0].showProgress = true;

            // response = await apispese.insertSpese(
            //     that.spese,
            //     img.src,
            //     v_token
            // ).then((res) => {

            //     that.$root.$children[0].showProgress = false;
            //     console.log("res from insertSpese", res);

            //     that.$swal({
            //         icon: "success",
            //         text: "Dati salvati correttamente",
            //         showConfirmButton: false,
            //         timer: 2000
            //     });

            //     setTimeout(() => {


            //         router.push({
            //             path: "/spese"
            //         });

            //     }, 200);

            // }).catch(err => {
            //     that.$root.$children[0].showProgress = false;
            //     console.log(err);
            //     var msg = err.response.data.Error;

            //     that.$swal({
            //         icon: "error",
            //         text: msg,
            //         showConfirmButton: false,
            //         timer: 8000
            //     });
            //     console.log("Errori", "Non è stato possibile salvare i dati");
            //     console.log("response", response);

            // }

            // );













        },

        syncUtente: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getUtente(
                that.$route.params.id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getUtente", res);

                try {

                    this.aziende = res.data.aziende;
                    this.prefissi = res.data.prefix;
                    this.gruppi = res.data.models;
                    this.utente = res.data.curr_user;

                    this.utente.codice_fiscale = res.data.val_codice_fiscale;

                    var grp_selected = res.data.curr_user.models.split(",");

                    console.log("GRP SEL: ", grp_selected);

                    if (grp_selected.length > 0) {

                        for (var i = 0; i < this.gruppi.length; i++) {

                            if (this.gruppi[i] !== undefined) {

                                for (var x = 0; x < grp_selected.length; x++) {

                                    console.log("GRP SEL 2: ", grp_selected[x]);

                                    if (parseInt(this.gruppi[i].id) == parseInt(grp_selected[x])) {

                                        this.gruppi[i].checked = this.gruppi[i].id;

                                    }

                                }

                            }

                        }

                    }

                    $("#itemAzienda").addClass("item-has-value");

                    if (res.data.info_easyer != null) {

                        this.utente.enable_easyer = res.data.info_easyer.enable;

                        this.utente.max_easyer = res.data.info_easyer.max_value;

                        this.utente.datestart_easyer = res.data.info_easyer.period_from;

                        this.utente.dateend_easyer = res.data.info_easyer.period_to;


                    }






                    //console.log("ok");

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.createUtenteApi(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from createUtenteApi", res);

                try {

                    this.aziende = res.data.aziende;

                    this.prefissi = res.data.prefix;

                    this.gruppi = res.data.models;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        syncPrefix: async function () {

            var that = this;

            //var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getPrefix().then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPrefix", res);

                try {

                    this.prefissi = res.data.tel_prefix;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.itemAzienda {
    margin-left: 15px;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.w-container-spesa {
    width: 100%;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.editUtente .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}

.editUtente .v-input {
    font-size: 1.2em !important;
}

.editUtente .v-select {
    font-size: 1.2em !important;
}

.editUtente .v-label {
    font-size: 1em !important;
}

.v-tabs:not(.v-tabs--vertical) .v-tab {
    white-space: normal;
    font-size: 12px;
    padding: 0px;
}




@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}

.theme--light.v-tabs>.v-tabs-bar,
.theme--light.v-tabs-items {
    background-color: transparent;
}

#imgPreview {
    display: none;
}


.dropify-clear {
    border: 0px !important;
    margin-top: -7px !important;
    margin-right: -7px !important;
}

.dropify-clear img {
    /* display: none; */
    width: 20px !important;
}

.dropify-wrapper {
    height: 236px !important;
    width: 465px !important;
    margin-left: auto;


    display: flex;
    justify-content: center;
    align-items: center;


}


.editSpesa .v-form {
    overflow-y: auto;
    max-height: 100vh;
    padding-bottom: 300px;
    overflow-x: hidden;
    margin-top: 10px;

}

.dropify-wrapper .dropify-message {
    top: 25%;
}

#d_importo {
    padding: 16px;
    max-width: 500px;
}


@media screen and (min-width: 768px) {
    .editSpesa .v-form {
        max-width: 480px !important;
        margin: 0 auto;
        padding-top: 13px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 47px;


    }

    #d_importo {
        transform: translateX(10px);
    }
}


@media screen and (max-width: 768px) {
    .dropify-wrapper {
        margin-left: 0px !important;
    }
}

#preview {
    display: none;
}


.active-row {
    background: transparent !important;
    border: 1px solid #00000087 !important;
}


.v-progress-circular__info {
    min-width: 100px;
    transform: translateY(-32px);
    background: white;
}

.v-progress-circular {
    transform: translateY(44px);
}

.span.dropify-filename-inner {
    display: none;
}

.dropify-filename {
    display: none;
}
</style>
