import Vue from 'vue'
import Router from 'vue-router'
import Login from './components/login.vue'
import timesheet from './components/timesheet.vue'
import profile from "../../vuecommon/profile.vue";
import register from './components/register.vue'
import dash from './components/dash.vue'
import info from './components/info.vue'
import messages from './components/messages.vue'
import changepassword from './components/changepassword.vue'
import holiday from './components/holiday.vue'
import disease from './components/disease.vue'
import filterusers from './components/filterusers.vue'
import users from './components/users.vue'
import editUser from './components/editUser.vue'
import editSpesa from './components/editSpesa.vue'
import changepasswordadmin from './components/changepasswordadmin.vue'
import settings from './components/settings.vue'
import changepasswordinitial from './components/changepasswordinitial.vue'
import reservate from './components/reservate.vue'
import firma from './components/firma.vue'
import sendMessage from './components/sendMessage.vue'
import spese from './components/spese.vue'
import editSpesaDetails from './components/editSpesaDetails.vue'
import approvazione from './components/approvazione.vue'
import editSpesaDetailsAdmin from './components/editSpesaDetailsAdmin.vue'
import fattureClienti from './components/fattureClienti.vue'
import filterFatture from './components/filterFatture.vue'
import fattureFornitori from './components/fattureFornitori.vue'
import banche from './components/banche.vue'
import filterBanche from './components/filterBanche.vue'
import editBanca from './components/editBanca.vue'
import scadenziario from './components/scadenziario.vue'
import fatturazioneAutomatica from './components/fatturazioneAutomatica.vue'
import editFatturaCliente from './components/editFatturaCliente.vue'
import testTable from './components/testTable.vue'
import editFatturaFornitore from './components/editFatturaFornitore.vue'
import mailGruppo from './components/mailGruppo.vue'
import editMailGruppo from './components/editMailGruppo.vue'
import confInvioMail from './components/confInvioMail.vue'
import filterPerformance from './components/filterPerformance.vue'
import performanceFatturazione from './components/performanceFatturazione.vue'
import vediFattureClienti from './components/vediFattureClienti.vue'
import vediFattureFornitori from './components/vediFattureFornitori.vue'
import filterCondizioniPagamento from './components/filterCondizioniPagamento.vue'
import condizioniPagamento from './components/condizioniPagamento.vue'
import editCondizioniPagamento from './components/editCondizioniPagamento.vue'
import filterValute from './components/filterValute.vue'
import valute from './components/valute.vue'
import editValuta from './components/editValuta.vue'
import filterIva from './components/filterIva.vue'
import iva from './components/iva.vue'
import editIva from './components/editIva.vue'
import filterUnitaDiMisura from './components/filterUnitaDiMisura.vue'
import unitaDiMisura from './components/unitaDiMisura.vue'
import editUnitaDiMisura from './components/editUnitaDiMisura.vue'
import configNotifiche from './components/configNotifiche.vue'
import company from './components/company.vue'
import editCompany from './components/editCompany.vue'
import vediPerformanceFatturazione from './components/vediPerformanceFatturazione.vue'
import vediFattureClientiAgila from './components/vediFattureClientiAgila.vue'
import fattureClientiAgila from './components/fattureClientiAgila.vue'
import setManCookie from './components/setManCookie.vue'
import logoFirma from './components/logoFirma.vue'
import vediFornitoriFatture from './components/vediFornitoriFatture.vue'
import fornitoriFatture from './components/fornitoriFatture.vue'
import editFornitoreFattura from './components/editFornitoreFattura.vue'
import fatturazioneAutomaticaReale from './components/fatturazioneAutomaticaReale.vue'
import reservateRecoverPassword from './components/reservateRecoverPassword.vue'
import recoverpasswordprofile from './components/recoverpasswordprofile.vue'
import filterFattureAnticipate from './components/filterFattureAnticipate.vue'
import fattureAnticipate from './components/fattureAnticipate.vue'
import filterContab from './components/filterContab.vue'
import detailsContab from './components/detailsContab.vue'
import filterImmagineSfondo from './components/filterImmagineSfondo.vue'
import immagineSfondo from './components/immagineSfondo.vue'
import initImmagineSfondo from './components/initImmagineSfondo.vue'
import fattureClientiFornitori from './components/fattureClientiFornitori.vue'
import loadXmlFileSuppliersToImport from './components/loadXmlFileSuppliersToImport.vue'
import reloadGridXmlFiles from './components/reloadGridXmlFiles.vue'
import documentType from './components/documentType.vue'
import editDocumentType from './components/editDocumentType.vue'
import document from './components/document.vue'
import editDocument from './components/editDocument.vue'


Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/',
            name: 'home',
            component: Login
        },

        {
            path: '/dash',
            name: 'dash',
            component: dash
        },


        {
            path: '/timesheet',
            name: 'timesheet',
            component: timesheet
        },

        {
            path: '/category/edit-timesheet',
            name: 'timesheet',
            component: timesheet
        },

        {
            path: '/profile',
            name: 'profile',
            component: profile
        },



        {
            path: '/register',
            name: 'register',
            component: register
        },

        {
            path: '/category/modfiy-account',
            name: 'profile',
            component: profile
        }
        ,
        {
            path: '/category/timesheet',
            name: 'timesheet',
            component: timesheet
        }
        ,
        {
            path: '/info',
            name: 'info',
            component: info
        }

        ,
        {
            path: '/changepassword',
            name: 'changepassword',
            component: changepassword
        }

        ,
        {
            path: '/messages',
            name: 'messages',
            component: messages
        }


        ,
        {
            path: '/holiday',
            name: 'holiday',
            component: holiday
        }
        ,
        {
            path: '/disease',
            name: 'disease',
            component: disease
        }

        ,
        {
            path: '/filterusers',
            name: 'filterusers',
            component: filterusers
        }


        ,
        {
            path: '/users',
            name: 'users',
            component: users
        }

        ,
        {
            path: '/editUser/:id',
            name: 'editUser',
            component: editUser,
            props: true
        }
        ,
        {
            path: '/editSpesa/:id',
            name: 'editSpesa',
            component: editSpesa,
            props: true
        }

        ,

        {
            path: '/changepassword/:id',
            name: 'changepassword',
            component: changepasswordadmin,
            props: true
        },



        {
            path: '/settings',
            name: 'settings',
            component: settings
        },


        {
            path: '/changepasswordinitial',
            name: 'changepasswordinitial',
            component: changepasswordinitial
        }

        ,
        {
            path: '/reservate/:id',
            name: 'reservate',
            component: reservate,
            props: true
        }

        ,
        {
            path: '/firma',
            name: 'firma',
            component: firma
        }

        ,
        {
            path: '/sendMessage',
            name: 'sendMessage',
            component: sendMessage
        }

        ,
        {
            path: '/spese',
            name: 'spese',
            component: spese
        }

        ,
        {
            path: '/editSpesaDetails/:id',
            name: 'editSpesaDetails',
            component: editSpesaDetails,
            props: true
        }

        ,
        {
            path: '/approvazione',
            name: 'approvazione',
            component: approvazione
        }


        ,
        {
            path: '/editSpesaDetailsAdmin/:id',
            name: 'editSpesaDetailsAdmin',
            component: editSpesaDetailsAdmin,
            props: true
        }

        ,
        {
            path: '/fattureClienti',
            name: 'fattureClienti',
            component: fattureClienti
        }

        ,
        {
            path: '/filterFatture',
            name: 'filterFatture',
            component: filterFatture
        }

        ,
        {
            path: '/fattureFornitori',
            name: 'fattureFornitori',
            component: fattureFornitori
        }

        ,
        {
            path: '/banche',
            name: 'banche',
            component: banche
        }

        ,
        {
            path: '/filterBanche',
            name: 'filterBanche',
            component: filterBanche
        }

        ,
        {
            path: '/editBanca/:id',
            name: 'editBanca',
            component: editBanca,
            props: true
        }

        ,
        {
            path: '/scadenziario/:id',
            name: 'scadenziario',
            component: scadenziario,
            props: true
        }


        ,
        {
            path: '/fatturazioneAutomatica',
            name: 'fatturazioneAutomatica',
            component: fatturazioneAutomatica
        }

        ,
        {
            path: '/editFatturaCliente/:id',
            name: 'editFatturaCliente',
            component: editFatturaCliente,
            props: true
        }


        ,
        {
            path: '/testTable',
            name: 'testTable',
            component: testTable
        }

        ,
        {
            path: '/editFatturaFornitore/:id',
            name: 'editFatturaFornitore',
            component: editFatturaFornitore,
            props: true
        }

        ,
        {
            path: '/mailGruppo',
            name: 'mailGruppo',
            component: mailGruppo
        }

        ,
        {
            path: '/editMailGruppo/:id',
            name: 'editMailGruppo',
            component: editMailGruppo,
            props: true
        }

        ,
        {
            path: '/confInvioMail',
            name: 'confInvioMail',
            component: confInvioMail
        }

        ,
        {
            path: '/filterPerformance',
            name: 'filterPerformance',
            component: filterPerformance
        }

        ,
        {
            path: '/performanceFatturazione',
            name: 'performanceFatturazione',
            component: performanceFatturazione
        }

        ,
        {
            path: '/vediFattureClienti',
            name: 'vediFattureClienti',
            component: vediFattureClienti
        }

        ,
        {
            path: '/vediFattureFornitori',
            name: 'vediFattureFornitori',
            component: vediFattureFornitori
        }

        ,
        {
            path: '/filterCondizioniPagamento',
            name: 'filterCondizioniPagamento',
            component: filterCondizioniPagamento
        }

        ,
        {
            path: '/condizioniPagamento',
            name: 'condizioniPagamento',
            component: condizioniPagamento
        }

        ,
        {
            path: '/editCondizioniPagamento/:id',
            name: 'editCondizioniPagamento',
            component: editCondizioniPagamento,
            props: true
        }

        ,
        {
            path: '/filterValute',
            name: 'filterValute',
            component: filterValute
        }

        ,
        {
            path: '/valute',
            name: 'valute',
            component: valute
        }

        ,
        {
            path: '/editValuta/:id',
            name: 'editValuta',
            component: editValuta,
            props: true
        }

        ,
        {
            path: '/filterIva',
            name: 'filterIva',
            component: filterIva
        }

        ,
        {
            path: '/iva',
            name: 'iva',
            component: iva
        }

        ,
        {
            path: '/editIva/:id',
            name: 'editIva',
            component: editIva,
            props: true
        }

        ,
        {
            path: '/filterUnitaDiMisura',
            name: 'filterUnitaDiMisura',
            component: filterUnitaDiMisura
        }

        ,
        {
            path: '/unitaDiMisura',
            name: 'unitaDiMisura',
            component: unitaDiMisura
        }

        ,
        {
            path: '/editUnitaDiMisura/:id',
            name: 'editUnitaDiMisura',
            component: editUnitaDiMisura,
            props: true
        }


        ,
        {
            path: '/configNotifiche',
            name: 'configNotifiche',
            component: configNotifiche
        }

        ,
        {
            path: '/company',
            name: 'company',
            component: company
        }

        ,
        {
            path: '/editCompany/:id',
            name: 'editCompany',
            component: editCompany,
            props: true
        }


        ,
        {
            path: '/vediPerformanceFatturazione',
            name: 'vediPerformanceFatturazione',
            component: vediPerformanceFatturazione
        },


        {
            path: '/vediFattureClientiAgila',
            name: 'vediFattureClientiAgila',
            component: vediFattureClientiAgila
        },

        {
            path: '/fattureClientiAgila',
            name: 'fattureClientiAgila',
            component: fattureClientiAgila
        },

        {
            path: '/setManCookie',
            name: 'setManCookie',
            component: setManCookie
        },

        {
            path: '/logoFirma',
            name: 'logoFirma',
            component: logoFirma
        },

        {
            path: '/vediFornitoriFatture',
            name: 'vediFornitoriFatture',
            component: vediFornitoriFatture
        },

        {
            path: '/fornitoriFatture',
            name: 'fornitoriFatture',
            component: fornitoriFatture
        },

    
        {
            path: '/editFornitoreFattura/:id',
            name: 'editFornitoreFattura',
            component: editFornitoreFattura,
            props: true
        },

        {
            path: '/fatturazioneAutomaticaReale',
            name: 'fatturazioneAutomaticaReale',
            component: fatturazioneAutomaticaReale
        },


        {
            path: '/reservateRecoverPassword/:id',
            name: 'reservateRecoverPassword',
            component: reservateRecoverPassword,
            props:true
        },

        {
            path: '/recoverpasswordprofile',
            name: 'recoverpasswordprofile',
            component: recoverpasswordprofile,
            props: true
        },

        {
            path: '/filterFattureAnticipate',
            name: 'filterFattureAnticipate',
            component: filterFattureAnticipate,
            props: true
        },

        {
            path: '/fattureAnticipate',
            name: 'fattureAnticipate',
            component: fattureAnticipate,
            props: true
        },

        {
            path: '/filterContab',
            name: 'filterContab',
            component: filterContab,
            props: true
        },

        {
            path: '/detailsContab',
            name: 'detailsContab',
            component: detailsContab,
            props: true
        },

        {
            path: '/filterImmagineSfondo',
            name: 'filterImmagineSfondo',
            component: filterImmagineSfondo,
            props: true
        },

        {
            path: '/immagineSfondo',
            name: 'immagineSfondo',
            component: immagineSfondo,
            props: true
        },

        {
            path: '/initImmagineSfondo',
            name: 'initImmagineSfondo',
            component: initImmagineSfondo,
            props: true
        },

        {
            path: '/fattureClientiFornitori',
            name: 'fattureClientiFornitori',
            component: fattureClientiFornitori,
            props: true
        },

        {
            path: '/loadXmlFileSuppliersToImport',
            name: 'loadXmlFileSuppliersToImport',
            component: loadXmlFileSuppliersToImport,
            props: true
        },

        {
            path: '/reloadGridXmlFiles',
            name: 'reloadGridXmlFiles',
            component: reloadGridXmlFiles,
            props: true
        },

        {
            path: '/documentType',
            name: 'documentType',
            component: documentType,
            props: true
        },



        {
            path: '/editDocumentType/:id',
            name: 'editDocumentType',
            component: editDocumentType,
            props:true
        },

        
        {
            path: '/document',
            name: 'document',
            component: document,
            props: true
        },

        {
            path: '/editDocument/:id',
            name: 'editDocument',
            component: editDocument,
            props:true
        },
        

    ]
})


router.afterEach((to, from) => {
    console.log("from", from);
    console.log("going to " + to.fullPath)

    // console.log(router.app.$refs);


    /*         console.log(to);
    
            console.log("Current Refs");
            console.log(router.app.$refs);
    
            Vue.nextTick(function () {
                console.log("after NextTick");
                console.log(router.app.$refs);
            }) */
})


export default router
