<template>
    <v-container class="w-container v-overflow-invoice editFatturaCliente"
        style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);">



        <v-form v-model="valid" ref="form" lazy-validation>

            <template>

                <v-expansion-panels style="max-width:500px">

                    <v-expansion-panel>

                        <v-expansion-panel-header>
                            Informazioni Generali
                        </v-expansion-panel-header>

                        <v-expansion-panel-content>

                            <v-row>

                                <v-col cols="12" md="12">

                                    <ion-item id="itemGenere"
                                        style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                        <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                            position="floating">Tipologia </ion-label>

                                        <ion-select id="idinvoice_tipologia" name="idinvoice_tipologia"
                                            class='idinvoice_tipologia' cancel-Text="Chiudi" done-Text=""
                                            :value="fattura.idinvoice_tipologia" interface="action-sheet">

                                            <ion-select-option v-for="item in tipologie" :key="item.idinvoice_tipologia"
                                                :value='item.idinvoice_tipologia'>
                                                {{ item.descrizione }}</ion-select-option>

                                        </ion-select>
                                    </ion-item>

                                </v-col>


                            </v-row>

                            <v-row>

                                <v-col cols="12" md="12">

                                    <div id="cont_companyid" style="cursor:pointer">
                                        <ion-item id="itemCompany"
                                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                            <ion-label
                                                style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                                position="floating">Nostra Azienda </ion-label>

                                            <ion-select id="companyid" name="companyid" class='companyid'
                                                cancel-Text="Chiudi" done-Text="" :value="fattura.companyid"
                                                interface="action-sheet" disabled readonly>

                                                <ion-select-option v-for="item in aziende" :key="item.companyid"
                                                    :value='item.companyid'> {{ item.fld_name }}</ion-select-option>

                                            </ion-select>
                                        </ion-item>
                                    </div>

                                </v-col>

                            </v-row>


                            <v-row>

                                <v-col cols="12" md="12">

                                    <div id="cont_customerid" style="cursor:pointer">
                                        <ion-item id="itemCustomer"
                                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                            <ion-label
                                                style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                                position="floating">Cliente </ion-label>

                                            <ion-select id="company" name="company" class='company' cancel-Text="Chiudi"
                                                done-Text="" :value="fattura.company" interface="action-sheet" disabled
                                                readonly>

                                                <ion-select-option v-for="item in clienti" :key="item.companyid"
                                                    :value='item.companyid'> {{ item.fld_name }}</ion-select-option>

                                            </ion-select>
                                        </ion-item>
                                    </div>

                                </v-col>

                            </v-row>

                            <v-row>

                                <v-col cols="12" md="12">

                                    <v-menu v-model="showPicker" :close-on-content-click="false"
                                        transitions="scale-transition" offset-y max-width="290px" min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="fattura.data" id="data" label="Data" persistent-hint
                                                readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="fattura.data" no-title @input="showPicker = false"
                                            locale="it"></v-date-picker>
                                    </v-menu>
                                    <div class="cl-del-date-fattura">
                                        <img src="@/assets/elimina.png" id="btnDelDataFattura" title="Cancella" />
                                    </div>

                                </v-col>

                            </v-row>

                            <v-row>

                                <v-col cols="12" md="12">

                                    <v-menu v-model="showPicker2" :close-on-content-click="false"
                                        transitions="scale-transition" offset-y max-width="290px" min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="fattura.data_scadenza" id="data_scadenza"
                                                label="Data Scadenza" persistent-hint readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="fattura.data_scadenza" no-title
                                            @input="showPicker2 = false" locale="it"></v-date-picker>
                                    </v-menu>
                                    <div class="cl-del-date-scad-fattura">
                                        <img src="@/assets/elimina.png" id="btnDelDataScadFattura" title="Cancella" />
                                    </div>

                                </v-col>

                            </v-row>


                            <v-row>

                                <v-col cols="12" md="12">

                                    <v-text-field v-model="fattura.numero" label="Numero" id="numero"></v-text-field>

                                </v-col>

                            </v-row>

                            <v-row v-if="viewFattRif">

                                <v-col cols="12" md="12">

                                    <ion-item id="itemFattRif"
                                        style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                        <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                            position="floating">Fattura
                                            Riferimento </ion-label>

                                        <ion-select id="idinvoice_reference_nc" name="idinvoice_reference_nc"
                                            class='idinvoice_reference_nc' cancel-Text="Chiudi" done-Text=""
                                            :value="fattura.idinvoice_reference_nc" interface="action-sheet">

                                            <ion-select-option v-for="item in fattureRiferimento" :key="item.idinvoice"
                                                :value='item.idinvoice'>
                                                {{ item.numero }}</ion-select-option>

                                        </ion-select>
                                    </ion-item>

                                </v-col>


                            </v-row>

                            <v-row v-if="viewBanca">

                                <v-col cols="12" md="12">

                                    <ion-item id="itemBanca"
                                        style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                        <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                            position="floating">Banca
                                        </ion-label>

                                        <ion-select id="idinvoice_banca_no_order" name="idinvoice_banca_no_order"
                                            class='idinvoice_banca_no_order' cancel-Text="Chiudi" done-Text=""
                                            :value="fattura.idinvoice_banca_no_order" interface="action-sheet">

                                            <ion-select-option v-for="item in banche" :key="item.idinvoice_banche"
                                                :value='item.idinvoice_banche'>
                                                {{ item.nome }}</ion-select-option>

                                        </ion-select>
                                    </ion-item>

                                </v-col>


                            </v-row>

                            <v-row v-if="viewIban">

                                <v-col cols="12" md="12">

                                    <v-text-field v-model="valIban" label="Iban" id="iban" readonly></v-text-field>

                                </v-col>

                            </v-row>


                            <v-row v-if="viewTipoPag">

                                <v-col cols="12" md="12">

                                    <div id="cont_iva" style="cursor:pointer">
                                        <ion-item id="itemTipoPag"
                                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                            <ion-label
                                                style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                                position="floating">Tipo
                                                Pagamento</ion-label>

                                            <ion-select id="idinvoice_tipo_pagamento_no_order"
                                                name="idinvoice_tipo_pagamento_no_order"
                                                class='idinvoice_tipo_pagamento_no_order' cancel-Text="Chiudi"
                                                done-Text="" :value="fattura.idinvoice_tipo_pagamento_no_order"
                                                interface="action-sheet">

                                                <ion-select-option v-for="item in tipiPagamento"
                                                    :key="item.idinvoice_tipo_pagamento"
                                                    :value='item.idinvoice_tipo_pagamento'>
                                                    {{
                                                    item.descrizione
                                                    }}</ion-select-option>

                                            </ion-select>
                                        </ion-item>
                                    </div>


                                </v-col>


                            </v-row>




                            <v-row>

                                <v-col cols="12" md="12">

                                    <div id="cont_iva" style="cursor:pointer">
                                        <ion-item id="itemIva"
                                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                            <ion-label
                                                style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                                position="floating">IVA</ion-label>

                                            <ion-select id="iva" name="iva" class='iva' cancel-Text="Chiudi"
                                                done-Text="" :value="fattura.idinvoice_partita_iva"
                                                interface="action-sheet">

                                                <ion-select-option v-for="item in iva" :key="item.idinvoice_partita_iva"
                                                    :value='item.idinvoice_partita_iva'>
                                                    {{
                                                    item.desc_valuta
                                                    }}</ion-select-option>

                                            </ion-select>
                                        </ion-item>
                                    </div>


                                </v-col>


                            </v-row>




                            <v-row>

                                <v-col cols="12" md="12">
                                    <v-checkbox label="Includi Nel Report Mail" v-model="fattura.is_include_in_report"
                                        true-value="S" false-value="N" id="chkMinutes" class="cl-checkbox"></v-checkbox>
                                </v-col>

                            </v-row>


                        </v-expansion-panel-content>




                    </v-expansion-panel>


                    <v-expansion-panel v-if="viewSecOrdine">
                        <v-expansion-panel-header>
                            Ordine
                        </v-expansion-panel-header>

                        <v-expansion-panel-content>

                            <v-row>

                                <v-col cols="12" md="12">

                                    <ion-item id="itemNOrdine"
                                        style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                        <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                            position="floating">Numero Ordine </ion-label>

                                        <ion-select id="idinvoice_ordini" name="idinvoice_ordini"
                                            class='idinvoice_ordini' cancel-Text="Chiudi" done-Text=""
                                            :value="fattura.idinvoice_ordini" interface="action-sheet">

                                            <ion-select-option v-for="item in ordini" :key="item.idinvoice_ordini"
                                                :value='item.idinvoice_ordini'>
                                                {{ item.num }}</ion-select-option>

                                        </ion-select>
                                    </ion-item>

                                </v-col>


                            </v-row>

                            <v-row>

                                <v-col cols="12" md="12">

                                    <v-text-field v-model="codiceOrdine" label="Codice" id="codiceOrdine" readonly>
                                    </v-text-field>

                                </v-col>

                            </v-row>

                            <v-row>

                                <v-col cols="12" md="12">

                                    <v-text-field v-model="dataInizioOrdine" label="Data Inizio" id="dataInizioOrdine"
                                        readonly>
                                    </v-text-field>

                                </v-col>

                            </v-row>

                            <v-row>

                                <v-col cols="12" md="12">

                                    <v-text-field v-model="dataFineOrdine" label="Data Fine" id="dataFineOrdine"
                                        readonly>
                                    </v-text-field>

                                </v-col>

                            </v-row>

                            <v-row>

                                <v-col cols="12" md="12">

                                    <v-text-field v-model="importoTotaleOrdine" label="Importo Totale"
                                        id="importoTotaleOrdine" readonly>
                                    </v-text-field>

                                </v-col>

                            </v-row>

                            <v-row>

                                <v-col cols="12" md="12">

                                    <v-text-field v-model="fatturatoOrdine" label="Fatturato" id="fatturatoOrdine"
                                        readonly>
                                    </v-text-field>

                                </v-col>

                            </v-row>

                            <v-row>

                                <v-col cols="12" md="12">

                                    <v-textarea v-model="risorseOrdine" label="Risorse" id="risorseOrdine" readonly>
                                    </v-textarea>

                                </v-col>

                            </v-row>




                        </v-expansion-panel-content>



                    </v-expansion-panel>


                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            Altro
                        </v-expansion-panel-header>

                        <v-expansion-panel-content>

                            <v-row>

                                <v-col cols="12" md="12">

                                    <v-textarea v-model="fattura.note" label="Note" id="note">
                                    </v-textarea>

                                </v-col>

                            </v-row>

                            <v-row>

                                <v-col cols="12" md="12">

                                    <v-text-field v-model="fattura.riferimento" label="Referente" id="riferimento">
                                    </v-text-field>

                                </v-col>

                            </v-row>

                            <v-row>

                                <v-col cols="12" md="12">

                                    <v-textarea v-model="fattura.delivery_address" label="Indirizzo Consegna"
                                        id="delivery_address">
                                    </v-textarea>

                                </v-col>

                            </v-row>


                            <v-row>

                                <v-col cols="12" md="12">

                                    <ion-item id="itemTipoContenitore"
                                        style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                        <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                            position="floating">Tipo Contenitore </ion-label>

                                        <ion-select id="type_container" name="type_container" class='type_container'
                                            cancel-Text="Chiudi" done-Text="" :value="fattura.type_container"
                                            interface="action-sheet">

                                            <ion-select-option v-for="item in tipiContenitore" :key="item.id"
                                                :value='item.id'>
                                                {{ item.type_container }}</ion-select-option>

                                        </ion-select>
                                    </ion-item>

                                </v-col>


                            </v-row>

                            <v-row>

                                <v-col cols="12" md="12">

                                    <v-text-field v-model="fattura.container" label="Contenitore" id="contenitore">
                                    </v-text-field>

                                </v-col>

                            </v-row>

                            <v-row>

                                <v-col cols="12" md="12">

                                    <v-text-field v-model="fattura.total_packages" label="Pacchetti Totali"
                                        id="pacchetti_totali">
                                    </v-text-field>

                                </v-col>

                            </v-row>

                            <v-row>

                                <v-col cols="12" md="12">

                                    <div id="d_peso_netto"
                                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                        <div class="v-input__control">
                                            <div class="v-input__slot">
                                                <div class="v-text-field__slot"><label for="input-29"
                                                        class="v-label v-label--active theme--light"
                                                        style="left: 0px; right: auto; position: absolute"> Peso
                                                        Netto</label>

                                                    <vue-autonumeric v-model="fattura.net_weight" :disabled="true"
                                                        :options="{
                                                            digitGroupSeparator: '.',
                                                            decimalCharacter: ',',
                                                            decimalCharacterAlternative: '.',
                                                            currencySymbol: '',
                                                            currencySymbolPlacement: 's',
                                                            roundingMethod: 'U',
                                                            minimumValue: '0',
                                                            modifyValueOnWheel: false,
                                                        }"></vue-autonumeric>

                                                </div>
                                            </div>

                                            <div class="v-text-field__details">
                                                <div class="v-messages theme--light">
                                                    <div class="v-messages__wrapper"></div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </v-col>

                            </v-row>

                            <v-row>

                                <v-col cols="12" md="12">

                                    <div id="d_peso_lordo"
                                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                        <div class="v-input__control">
                                            <div class="v-input__slot">
                                                <div class="v-text-field__slot"><label for="input-29"
                                                        class="v-label v-label--active theme--light"
                                                        style="left: 0px; right: auto; position: absolute"> Peso
                                                        Lordo</label>

                                                    <vue-autonumeric v-model="fattura.gross_weight" :disabled="true"
                                                        :options="{
                                                            digitGroupSeparator: '.',
                                                            decimalCharacter: ',',
                                                            decimalCharacterAlternative: '.',
                                                            currencySymbol: '',
                                                            currencySymbolPlacement: 's',
                                                            roundingMethod: 'U',
                                                            minimumValue: '0',
                                                            modifyValueOnWheel: false,
                                                        }"></vue-autonumeric>

                                                </div>
                                            </div>

                                            <div class="v-text-field__details">
                                                <div class="v-messages theme--light">
                                                    <div class="v-messages__wrapper"></div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </v-col>

                            </v-row>


                            <v-row>

                                <v-col cols="12" md="12">

                                    <ion-item id="itemUnitaMisura"
                                        style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                        <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                            position="floating">Unità Di Misura </ion-label>

                                        <ion-select id="idinvoice_unita_di_misura_weight"
                                            name="idinvoice_unita_di_misura_weight"
                                            class='idinvoice_unita_di_misura_weight' cancel-Text="Chiudi" done-Text=""
                                            :value="fattura.idinvoice_unita_di_misura_weight" interface="action-sheet">

                                            <ion-select-option v-for="item in unitaDiMisura"
                                                :key="item.idinvoice_unita_di_misura"
                                                :value='item.idinvoice_unita_di_misura'>
                                                {{ item.simbolo }}</ion-select-option>

                                        </ion-select>
                                    </ion-item>

                                </v-col>


                            </v-row>



                        </v-expansion-panel-content>



                    </v-expansion-panel>


                </v-expansion-panels>


                <div class="cont-table">

                    <div class="spin">
                        <ion-spinner name="crescent"></ion-spinner>
                    </div>

                    <div class="row cont_tickets cont_dettagli_fattura">



                        <div class=" " style="margin:0 auto;">
                            <div class="card">


                                <div class="card-body" style="padding:0px">

                                    <div class="table-responsive" style="">

                                        <div class="cl_title_details">Dettagli</div>

                                        <table id="myTable" style="margin-top:57px">
                                            <thead>
                                                <tr>

                                                    <th class='all'>
                                                        <div class="cl_checkall_dett">
                                                            <ion-checkbox name='emailgestoreall'
                                                                style="width:10px!important;height:10px!important"
                                                                class='checkall_dett' slot='start'>
                                                            </ion-checkbox>
                                                        </div>
                                                    </th>

                                                    <th class='all'>Codice</th>
                                                    <th class='all'>Descrizione</th>
                                                    <th class='all'>UM</th>
                                                    <th class='all' style="text-align:right;padding-right:4px">Qtà</th>
                                                    <th class='all' style="text-align:right;padding-right:4px">Prezzo
                                                    </th>
                                                    <th class='all'>Valuta</th>
                                                    <th class='all' style="text-align:right;padding-right:4px">Subtotale
                                                    </th>


                                                </tr>
                                            </thead>

                                            <tfoot>



                                                <tr>

                                                    <th class='desktop col_footer'>&nbsp;</th>
                                                    <th class='desktop col_footer'>&nbsp;</th>
                                                    <th class='desktop col_footer'>Subtotale</th>
                                                    <th class='desktop col_footer'>&nbsp;</th>
                                                    <th class='desktop col_footer'>&nbsp;</th>
                                                    <th class='desktop col_footer'>&nbsp;</th>
                                                    <th class='desktop col_footer'>&nbsp;</th>
                                                    <th class='desktop col_footer_right' id="v_tot_subtotale">0,00</th>

                                                </tr>

                                                <tr>

                                                    <th class='desktop col_footer_no_border'>&nbsp;</th>
                                                    <th class='desktop col_footer_no_border'>&nbsp;</th>
                                                    <th class='desktop col_footer_no_border'>Trasporto</th>
                                                    <th class='desktop col_footer_no_border'>&nbsp;</th>
                                                    <th class='desktop col_footer_no_border'>&nbsp;</th>
                                                    <th class='desktop col_footer_no_border'>&nbsp;</th>
                                                    <th class='desktop col_footer_no_border'>&nbsp;</th>
                                                    <th class='desktop col_footer_right_no_border' id="v_tot_trasporto">
                                                        0,00
                                                    </th>

                                                </tr>

                                                <tr>

                                                    <th class='desktop col_footer_no_border'>&nbsp;</th>
                                                    <th class='desktop col_footer_no_border'>&nbsp;</th>
                                                    <th class='desktop col_footer_no_border'>Totale</th>
                                                    <th class='desktop col_footer_no_border'>&nbsp;</th>
                                                    <th class='desktop col_footer_no_border'>&nbsp;</th>
                                                    <th class='desktop col_footer_no_border'>&nbsp;</th>
                                                    <th class='desktop col_footer_no_border'>&nbsp;</th>
                                                    <th class='desktop col_footer_right_no_border' id="v_tot_totale">
                                                        0,00
                                                    </th>

                                                </tr>

                                                <tr>

                                                    <th class='desktop col_footer_no_border'>&nbsp;</th>
                                                    <th class='desktop col_footer_no_border'>&nbsp;</th>
                                                    <th class='desktop col_footer_no_border'>Iva</th>
                                                    <th class='desktop col_footer_no_border'>&nbsp;</th>
                                                    <th class='desktop col_footer_no_border'>&nbsp;</th>
                                                    <th class='desktop col_footer_no_border'>&nbsp;</th>
                                                    <th class='desktop col_footer_no_border'>&nbsp;</th>
                                                    <th class='desktop col_footer_right_no_border' id="v_tot_iva">0,00
                                                    </th>

                                                </tr>

                                                <tr>

                                                    <th class='desktop col_footer_no_border'>&nbsp;</th>
                                                    <th class='desktop col_footer_no_border'>&nbsp;</th>
                                                    <th class='desktop col_footer_no_border'>Tot. Pagare</th>
                                                    <th class='desktop col_footer_no_border'>&nbsp;</th>
                                                    <th class='desktop col_footer_no_border'>&nbsp;</th>
                                                    <th class='desktop col_footer_no_border'>&nbsp;</th>
                                                    <th class='desktop col_footer_no_border'>&nbsp;</th>
                                                    <th class='desktop col_footer_right_no_border' id="v_tot_pagare">
                                                        0,00
                                                    </th>

                                                </tr>



                                            </tfoot>


                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                </div>




            </template>






        </v-form>



        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Aziende
                            <v-btn class='closeSheet' icon @click="sheetAziende = !sheetAziende">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>

                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                </v-text-field>

                            </v-toolbar>

                        </div>

                        <v-divider></v-divider>

                        <div class="spacer" style="min-height:72px;"></div>

                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                    @click="manageClickAziende(item)">

                                    <v-img alt="Clienti Logo" class="  " contain src="@/assets/aziende-invoice.png"
                                        max-width="24" max-height="24" transition="scale-transition" />

                                    <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>
                                </v-list-item>

                                <div style="min-height:150px;height:150px">&nbsp;</div>


                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetClienti" class="vclientisheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetClienti" fixed style="position:fixed;">
                            Clienti
                            <v-btn class='closeSheet' icon @click="sheetClienti = !sheetClienti">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>

                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                    v-model="searchClienti">
                                </v-text-field>

                            </v-toolbar>

                        </div>

                        <v-divider></v-divider>

                        <div class="spacer" style="min-height:72px;"></div>

                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="item in filteredClienti" :key="item.companyid"
                                    @click="manageClickClienti(item)">

                                    <v-img alt="Clienti Logo" class="  " contain src="@/assets/customers.png"
                                        max-width="24" max-height="24" transition="scale-transition" />

                                    <v-list-item-title class='itemCliente'>{{ item.fld_name }}</v-list-item-title>
                                </v-list-item>

                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

        <div class="contpopuppesonetto">

            <template>
                <modal name="popupPesoNetto" :clickToClose="false" :width="300" :height="250">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px">Peso Netto</label>

                            <div id="d_peso_netto_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valorePesoNetto" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0',
                                                modifyValueOnWheel: false,
                                            }"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-peso-netto" contain
                                    src="@/assets/btn_cancel3_invoiceweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;margin-right:10px" transition="scale-transition" />

                                <v-img alt="" class="btn-confirm-peso-netto" contain
                                    src="@/assets/btn_confirm3_invoiceweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contpopuppesolordo">

            <template>
                <modal name="popupPesoLordo" :clickToClose="false" :width="300" :height="250">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px">Peso Lordo</label>

                            <div id="d_peso_lordo_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valorePesoLordo" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0',
                                                modifyValueOnWheel: false,
                                            }"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-peso-lordo" contain
                                    src="@/assets/btn_cancel3_invoiceweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;margin-right:10px" transition="scale-transition" />

                                <v-img alt="" class="btn-confirm-peso-lordo" contain
                                    src="@/assets/btn_confirm3_invoiceweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contpopuppeditdettaglio">

            <template>
                <modal name="popupEditDettaglio" :clickToClose="false" :width="300" :height="700">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            {{ titoloPopup }}

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="fattura_dettaglio.codice" label="Codice" id="codice">
                            </v-text-field>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="fattura_dettaglio.descrizione" label="Descrizione"
                                id="dettaglio_descrizione">
                            </v-text-field>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <ion-item id="itemUnitaMisura"
                                style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                    position="floating">Unità Di Misura </ion-label>

                                <ion-select id="idinvoice_unita_di_misura" name="idinvoice_unita_di_misura"
                                    class='idinvoice_unita_di_misura' cancel-Text="Chiudi" done-Text=""
                                    :value="fattura_dettaglio.idinvoice_unita_di_misura" interface="action-sheet">

                                    <ion-select-option v-for="item in unitaDiMisura"
                                        :key="item.idinvoice_unita_di_misura" :value='item.idinvoice_unita_di_misura'>
                                        {{ item.simbolo }}</ion-select-option>

                                </ion-select>
                            </ion-item>

                        </v-col>


                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div id="d_qta_dett"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute">Quantità</label>

                                            <vue-autonumeric v-model="fattura_dettaglio.quantita"
                                                id="dettaglio_quantita" :options="{
                                                    digitGroupSeparator: '.',
                                                    decimalCharacter: ',',
                                                    decimalCharacterAlternative: '.',
                                                    currencySymbol: '',
                                                    currencySymbolPlacement: 's',
                                                    roundingMethod: 'U',
                                                    minimumValue: '0',
                                                    modifyValueOnWheel: false,
                                                }"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div id="d_punit_dett"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute">Prezzo
                                                Unitario</label>

                                            <vue-autonumeric v-model="fattura_dettaglio.prezzo_unitario"
                                                id="dettaglio_pu" :options="{
                                                    digitGroupSeparator: '.',
                                                    decimalCharacter: ',',
                                                    decimalCharacterAlternative: '.',
                                                    currencySymbol: '',
                                                    currencySymbolPlacement: 's',
                                                    roundingMethod: 'U',
                                                    minimumValue: '0',
                                                    modifyValueOnWheel: false,
                                                }"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <ion-item id="itemValuta"
                                style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                    position="floating">Valuta </ion-label>

                                <ion-select id="idinvoice_valuta" name="idinvoice_valuta" class='idinvoice_valuta'
                                    cancel-Text="Chiudi" done-Text="" :value="fattura_dettaglio.idinvoice_valuta"
                                    interface="action-sheet">

                                    <ion-select-option v-for="item in valute" :key="item.idinvoice_valute"
                                        :value='item.idinvoice_valute'>
                                        {{ item.simbolo }}</ion-select-option>

                                </ion-select>
                            </ion-item>

                        </v-col>


                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <div id="d_punit_dett"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute">Totale</label>

                                            <vue-autonumeric v-model="totaleDettaglio" :disabled="true" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0',
                                                modifyValueOnWheel: false,
                                            }"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>




                    <!-- <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-confirm-edit-dettaglio" contain src="@/assets/btn_confirm3_invoiceweb.png"
                                    max-width="32" max-height="32" style="cursor:pointer;margin-right:10px"
                                    transition="scale-transition" />

                                <v-img alt="" class="btn-cancel-edit-dettaglio" contain src="@/assets/btn_cancel3_invoiceweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer"
                                    transition="scale-transition" />

                            </div>

                        </v-col>

                    </v-row> -->

                </modal>
            </template>

        </div>



        <div class="contpopuptrasporto">

            <template>
                <modal name="popupTrasporto" :clickToClose="false" :width="300" :height="250">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px">Trasporto</label>

                            <div id="d_trasporto_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreTrasporto" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0',
                                                modifyValueOnWheel: false,
                                            }"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-trasporto" contain
                                    src="@/assets/btn_cancel3_invoiceweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;margin-right:10px" transition="scale-transition" />

                                <v-img alt="" class="btn-confirm-trasporto" contain
                                    src="@/assets/btn_confirm3_invoiceweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>




    </v-container>
</template>

<script>
import apiusers from "../utils/users/apiusers";
import apibanche from "../utils/banche/apibanche";
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';
import apiinvoice from "../utils/invoice/apiinvoice";

import {
    bus
} from "../main";

import router from ".././router";

import Vue from "vue";
import VModal from 'vue-js-modal';


export default ({
    mounted() {

        this.$root.$children[0].checkLogout();


        Vue.use(VModal, {
            dialog: true
        });

        this.isAgila = window.$cookies.get("is_agila");


        /*eslint-disable no-undef*/

        if (window.innerWidth <= 768) {

            $("#btnUserInfo").css("transform", "translateX(25px)");

        }

        var pointerVue = this;

        this.$root.$children[0].normalDiv1 = true;
        this.$root.$children[0].normalDiv2 = true;

        this.$root.$children[0].invoiceDiv1 = false;
        this.$root.$children[0].invoiceDiv2 = false;
        this.$root.$children[0].invoiceDiv3 = false;
        this.$root.$children[0].invoiceDiv4 = false;
        this.$root.$children[0].invoiceDiv3Agila = false;

        this.$root.$children[0].calcolatorDiv1 = false;

        //  var namesurname = window.$cookies.get("namesurname");

        // this.$root.$children[0].curTitle = namesurname;

        window.numEventSaveDetail = 0;


        this.$root.$children[0].nomeAziendaSelezionata = "";


        //window.$cookies.get("aziende_user")

        this.setupButtons();

        if (this.$route.params.id == 0) {

            this.$root.$children[0].invoiceTitle = "Aggiungi Fattura Cliente";

            setTimeout(() => {

                this.initInsert();


            }, 100);

        }

        if (this.$route.params.id > 0) {

            this.$root.$children[0].invoiceTitle = "Modifica Fattura Cliente";

            setTimeout(() => {

                this.initEdit();


            }, 100);

            // setTimeout(() => {

            //     // this.syncAziende();

            //     // this.syncMailGroup();


            //     // setTimeout(() => {

            //     //     this.syncBanca();

            //     // }, 1000);

            //     this.syncBanca();


            // }, 100);

        }

        // if (this.$route.params.id > 0) {

        //     this.$root.$children[0].curTitle = "Modifica Utente";

        // }

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    this.validate();

                    break;

                case "btn_back":
                    //alert("btn_add_x");

                    // router.push({
                    //     path: "/fattureClienti"
                    // });

                    if (this.isAgila == 0) {

                        router.push({
                            path: "/fattureClienti"
                        });


                    }

                    if (this.isAgila == 1) {

                        router.push({
                            path: "/fattureClientiAgila"
                        });


                    }


                    break;


                case "btn_add":

                    window.operationDetail = 0; // insert detail



                    //this.fattura_dettaglio = [];

                    this.fattura_dettaglio.codice = "";
                    this.fattura_dettaglio.descrizione = "";
                    this.fattura_dettaglio.idinvoice_unita_di_misura = "0";
                    this.fattura_dettaglio.quantita = "0";
                    this.fattura_dettaglio.prezzo_unitario = "0";
                    this.fattura_dettaglio.idinvoice_valuta = this.idValutaDef;

                    this.totaleDettaglio = null;


                    this.titoloPopup = "Aggiungi Dettaglio";

                    this.$modal.show('popupEditDettaglio');

                    this.hideShowButtonsEditRowDetails(true);

                    break;

                case "btn_edit":

                    window.operationDetail = 1; // edit detail

                    this.syncDetail();


                    break;

                case "btn_delete":


                    this.deleteDetails();


                    break;


                case "btn_cancel":

                    this.$modal.hide('popupEditDettaglio');

                    this.hideShowButtonsSelRow();

                    break;

                case "btn_save_detail":

                    this.validateSaveDatail();

                    break;

                default:
                    break;
            }
        });

        $(document).ready(function () {



            $('body').on('click', '.btn-confirm-fido-ant', function (event) {
                console.log(event);

                pointerVue.banca.fido_anticipi = pointerVue.fidoAnt;

                pointerVue.$root.$children[0].setFieldActive("#d_fido_ant");

                pointerVue.$modal.hide('popupFidoAnt');

            });


            $('body').on('click', '.btn-cancel-fido-ant', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupFidoAnt');

            });

            $('body').on('click', '#d_fido_ant', function (event) {
                console.log(event);


                pointerVue.$modal.show('popupFidoAnt');

            });


            $('body').on('click', '#cont_companyid', function (event) {
                console.log(event);

                pointerVue.sheetAziende = true;

            });

            $('body').on('click', '#cont_customerid', function (event) {
                console.log(event);

                pointerVue.sheetClienti = true;

            });


            $('body').on('click', '#cont_idmailgroup', function (event) {
                console.log(event);

                pointerVue.sheetGruppi = true;

            });




            $('body').on('click', '#d_importo_user', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupImporto');

            });


            $('body').on('click', '.btn-confirm-massimale', function (event) {
                console.log(event);

                pointerVue.utente.max_easyer = pointerVue.valoreMassimale;

                pointerVue.$root.$children[0].setFieldActive("#d_importo_user");

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '.btn-cancel-massimale', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '.btn-confirm-fido-cassa', function (event) {
                console.log(event);

                pointerVue.banca.fido_cassa = pointerVue.fidoCassa;

                pointerVue.$root.$children[0].setFieldActive("#d_fido_cassa");

                pointerVue.$modal.hide('popupFidoCassa');

            });


            $('body').on('click', '.btn-cancel-fido-cassa', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupFidoCassa');

            });

            $('body').on('click', '#d_fido_cassa', function (event) {
                console.log(event);


                pointerVue.$modal.show('popupFidoCassa');

            });

            $('body').on('click', '.cl-del-date-fattura', function (event) {
                console.log(event);

                pointerVue.fattura.data = null;

                pointerVue.hideShowButtonsSelRow();

            });


            $('body').on('click', '.cl-del-date-scad-fattura', function (event) {
                console.log(event);

                pointerVue.fattura.data_scadenza = null;

                pointerVue.hideShowButtonsSelRow();

            });

            $('body').on('ionChange', '.idinvoice_tipologia', function (event) {
                console.log(event);

                pointerVue.fattura.idinvoice_tipologia = this.value;

                pointerVue.hideShowButtonsSelRow();

            });


            $('body').on('ionChange', '.idinvoice_reference_nc', function (event) {
                console.log(event);

                pointerVue.fattura.idinvoice_reference_nc = this.value;

                pointerVue.hideShowButtonsSelRow();

            });

            $('body').on('ionChange', '.idinvoice_tipo_pagamento_no_order', function (event) {
                console.log(event);

                pointerVue.fattura.idinvoice_tipo_pagamento_no_order = this.value;

                pointerVue.hideShowButtonsSelRow();

            });


            $('body').on('ionChange', '.iva', function (event) {
                console.log(event);

                pointerVue.fattura.idinvoice_partita_iva = this.value;

                pointerVue.updateTotalsGrid();

                pointerVue.hideShowButtonsSelRow();

            });


            $('body').on('ionChange', '.idinvoice_tipologia', function (event) {
                console.log(event);

                console.log("VAL: ", this.value);

                if (this.value == 3) {

                    pointerVue.viewBanca = true;
                    pointerVue.viewIban = true;
                    pointerVue.viewTipoPag = true;
                    pointerVue.viewFattRif = false;
                    pointerVue.viewSecOrdine = false;


                }
                else {

                    if (this.value == 2) {

                        pointerVue.viewBanca = false;
                        pointerVue.viewIban = false;
                        pointerVue.viewTipoPag = false;
                        pointerVue.viewSecOrdine = false;
                        pointerVue.viewFattRif = true;

                    }
                    else {

                        if ((this.value == 1) || (this.value == 6)) {

                            pointerVue.viewBanca = false;
                            pointerVue.viewIban = false;
                            pointerVue.viewTipoPag = false;
                            pointerVue.viewFattRif = false;
                            pointerVue.viewSecOrdine = true;

                        }
                        else {

                            pointerVue.viewBanca = false;
                            pointerVue.viewIban = false;
                            pointerVue.viewTipoPag = false;
                            pointerVue.viewFattRif = false;
                            pointerVue.viewSecOrdine = false;

                        }


                    }



                }

            });

            $('body').on('ionChange', '.idinvoice_banca_no_order', function (event) {
                console.log(event);


                if (this.value != "") {

                    var v_iban = "";
                    for (var i = 0; i <= (pointerVue.bancheTemp.length - 1); i++) {

                        console.log("ID BANCA: ", pointerVue.bancheTemp[i].idinvoice_banche);

                        if (parseInt(pointerVue.bancheTemp[i].idinvoice_banche) == parseInt(this.value)) {

                            v_iban = pointerVue.bancheTemp[i].iban;

                        }


                    }

                    console.log("IBAN: ", v_iban);

                    pointerVue.valIban = v_iban;

                }
                else {

                    pointerVue.valIban = "";


                }

                pointerVue.fattura.idinvoice_banca_no_order = this.value;


                pointerVue.hideShowButtonsSelRow();



            });



            $('body').on('ionChange', '.idinvoice_tipo_pagamento_no_order', function (event) {
                console.log(event);


                pointerVue.fattura.idinvoice_tipo_pagamento_no_order = this.value;


                pointerVue.hideShowButtonsSelRow();



            });






            $('body').on('ionChange', '.idinvoice_ordini', function (event) {
                console.log(event);

                pointerVue.fattura.idinvoice_ordini = this.value;

                pointerVue.dettagliOrdine();

                // setTimeout(() => {

                //     pointerVue.hideShowButtonsSelRow();

                // }, 600);




            });


            $('body').on('click', '#d_peso_netto', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupPesoNetto');

            });



            $('body').on('click', '.btn-confirm-peso-netto', function (event) {
                console.log(event);

                pointerVue.fattura.net_weight = pointerVue.valorePesoNetto;

                pointerVue.$root.$children[0].setFieldActive("#d_peso_netto");

                pointerVue.$modal.hide('popupPesoNetto');

            });

            $('body').on('click', '.btn-cancel-peso-netto', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupPesoNetto');

            });



            $('body').on('click', '#d_trasporto', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupTrasporto');

            });

            $('body').on('click', '.btn-confirm-trasporto', function (event) {
                console.log(event);

                pointerVue.fattura.totale_trasporto = pointerVue.valoreTrasporto;

                pointerVue.$root.$children[0].setFieldActive("#d_trasporto");

                pointerVue.$modal.hide('popupTrasporto');

                pointerVue.updateTotalsGrid();

            });

            $('body').on('click', '.btn-cancel-trasporto', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupTrasporto');

            });

            $('body').on('click', '#d_peso_lordo', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupPesoLordo');

            });

            $('body').on('click', '.btn-confirm-peso-lordo', function (event) {
                console.log(event);

                pointerVue.fattura.gross_weight = pointerVue.valorePesoLordo;

                pointerVue.$root.$children[0].setFieldActive("#d_peso_lordo");

                pointerVue.$modal.hide('popupPesoLordo');

            });

            $('body').on('click', '.btn-cancel-peso-lordo', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupPesoLordo');

            });






            $('body').on('click', '.btn-confirm-edit-dettaglio', function (event) {
                console.log(event);

                // if (window.numEventSaveDetail == 0) {

                //     window.numEventSaveDetail = window.numEventSaveDetail + 1;

                //     pointerVue.validateSaveDatail();

                // }


                pointerVue.validateSaveDatail();



            });

            $('body').on('click', '.btn-cancel-edit-dettaglio', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupEditDettaglio');

            });










            $('body').on('click', '.checkall_dett', function () {


                $(".val_status_dett").prop("checked", $(this).prop("checked"));

                console.log("checkall_dett: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row");

                    $("tr").eq(0).removeClass("active-row");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row");


                }

                pointerVue.hideShowButtonsSelRow();


            });



            $('body').on('blur', '#dettaglio_descrizione', function () {


                console.log("DESCRIZIONE");

                pointerVue.hideShowButtonsEditRowDetails(true);

            });



            $('body').on('ionChange', '.idinvoice_unita_di_misura', function (event) {
                console.log(event);

                pointerVue.fattura_dettaglio.idinvoice_unita_di_misura = this.value;

                pointerVue.hideShowButtonsEditRowDetails(true);

            });

            $('body').on('blur', '#dettaglio_quantita', function () {


                console.log("DESCRIZIONE");

                pointerVue.hideShowButtonsEditRowDetails(true);

            });

            $('body').on('blur', '#dettaglio_pu', function () {


                console.log("DESCRIZIONE");

                pointerVue.hideShowButtonsEditRowDetails(true);

            });


            $('body').on('ionChange', '.idinvoice_valuta', function (event) {
                console.log(event);

                pointerVue.fattura_dettaglio.idinvoice_valuta = this.value;

                pointerVue.hideShowButtonsEditRowDetails(true);

            });






            $('body').on('blur', '#numero', function () {


                console.log("NUMERO");

                pointerVue.hideShowButtonsSelRow();

            });

            $('body').on('blur', '#data', function () {


                console.log("DATA");

                pointerVue.fattura.data = this.value;

                setTimeout(() => {

                    pointerVue.hideShowButtonsSelRow();

                }, 300);


                // pointerVue.hideShowButtonsSelRow();

            });

            $('body').on('blur', '#data_scadenza', function () {


                console.log("DATA SCADENZA");


                pointerVue.fattura.data_scadenza = this.value;

                setTimeout(() => {

                    pointerVue.hideShowButtonsSelRow();

                }, 300);



            });




            $('body').on('blur', '#dettaglio_quantita', function () {


                console.log("QUANTITA: ", pointerVue.fattura_dettaglio.quantita);
                console.log("PREZZO U.: ", pointerVue.fattura_dettaglio.prezzo_unitario);

                var v_totale = parseFloat(pointerVue.fattura_dettaglio.quantita) * parseFloat(pointerVue.fattura_dettaglio.prezzo_unitario);

                pointerVue.totaleDettaglio = v_totale;

            });

            $('body').on('blur', '#dettaglio_pu', function () {


                console.log("QUANTITA: ", pointerVue.fattura_dettaglio.quantita);
                console.log("PREZZO U.: ", pointerVue.fattura_dettaglio.prezzo_unitario);

                var v_totale = parseFloat(pointerVue.fattura_dettaglio.quantita) * parseFloat(pointerVue.fattura_dettaglio.prezzo_unitario);

                pointerVue.totaleDettaglio = v_totale;

            });




        });


        setTimeout(() => {

            pointerVue.setupGrid();

        }, 600);




    },

    components: {
        VueAutonumeric,
    },


    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        filteredGruppi() {

            return _.orderBy(this.gruppi.filter(item => {
                if (!this.searchGruppi) return this.gruppi;
                return (item.name.toLowerCase().includes(this.searchGruppi.toLowerCase()));
            }), 'headline')
        },


        filteredClienti() {

            return _.orderBy(this.clienti.filter(item => {
                if (!this.searchClienti) return this.clienti;
                return (item.fld_name.toLowerCase().includes(this.searchClienti.toLowerCase()));
            }), 'headline')
        },

    },

    data: () => ({

        isAgila: 0,

        titoloPopup: "",

        idValutaDef: 0,

        valorePesoNetto: null,
        valorePesoLordo: null,

        valoreTrasporto: null,

        totaleDettaglio: null,

        viewBanca: false,
        viewIban: false,
        viewTipoPag: false,
        viewFattRif: false,

        viewSecOrdine: false,

        valIban: "",

        codiceOrdine: "",
        dataInizioOrdine: "",
        dataFineOrdine: "",
        importoTotaleOrdine: "",
        fatturatoOrdine: "",
        risorseOrdine: "",

        fidoAnt: null,
        fidoCassa: null,

        sheetGruppi: false,

        searchGruppi: "",

        banca: {},

        fattura: { idinvoice_partita_iva: "0", idinvoice_tipologia: "0", company: "0", data_scadenza: "", idinvoice_ordini: "0", idinvoice_banca_no_order: "0", idinvoice_tipo_pagamento_no_order: "0", idinvoice_reference_nc: "0", is_include_in_report: "S" },


        fattura_dettaglio: {},


        showPicker2: false,

        valoreMassimale: null,

        changePsw: false,

        titolo: "",
        currDipendenteObj: {},

        gruppi_checked: [],

        isCurrentUserSU: "N",

        aziende: [],

        clienti: [],

        prefissi: [],

        dipendenti: [],
        currAzienda: "",

        iva: [],
        ivaTemp: [],

        gruppi: [],

        tipologie: [],

        banche: [],
        bancheTemp: [],

        tipiPagamento: [],
        tipiPagamentoTemp: [],

        fattureRiferimento: [],
        fattureRiferimentoTemp: [],

        ordini: [],
        ordiniTemp: [],

        trasporto: [],
        trasportoTemp: [],

        tipiContenitore: [],


        unitaDiMisura: [],
        unitaDiMisuraTemp: [],

        valute: [],
        valuteTemp: [],

        selNuclei: null,

        tab: null,

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",
        searchClienti: "",
        sheetClienti: false,

        showPicker: false,
        utente: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Cognome richiesto",
        ],

        aziendaRules: [
            (v) => !!v || "Azienda richiesta",
        ],

        usernameRules: [
            (v) => !!v || "Username richiesto",
        ],

        select: null,

        // items: [
        //     'Informazioni Generali', 'Gruppo', 'Autenticazione', 'QR Code'
        // ],

        items: [
            'Informazioni', 'Gruppo', 'Nota Spese'
        ],

        checkbox: false,

        tipo_qrcode: [{
            "id": "USR_PWD",
            "value": "Utente E Password"
        },
        {
            "id": "FCODE_PWD",
            "value": "Utente E Codice Fiscale"
        }
        ]

    }),

    methods: {


        hideShowConfButtonDetail: function () {

            console.log("DETTAGLIO FATTURA: ", this.fattura_dettaglio);

            var enable = 1;

            if (this.fattura_dettaglio.descrizione == "") {
                enable = 0;
            }

            if (enable == 1) {

                if (this.fattura_dettaglio.idinvoice_unita_di_misura == "0") {

                    enable = 0;

                }

            }

            if (enable == 1) {

                if (this.fattura_dettaglio.quantita == "0") {

                    enable = 0;

                }

            }

            if (enable == 1) {

                if (this.fattura_dettaglio.prezzo_unitario == "0") {

                    enable = 0;

                }

            }

            if (enable == 1) {

                if (this.fattura_dettaglio.idinvoice_valuta == "0") {

                    enable = 0;

                }

            }



            return enable;

        },





        hideShowButtonsEditRowDetails: function (v_show) {

            if (v_show) {

                var v_show_conf = this.hideShowConfButtonDetail();

                console.log(v_show_conf);

                var pulsantis = [];

                if (v_show_conf == 1) {

                    pulsantis.push({
                        text: "Conferma",
                        icon: "mdi-content-save",
                        image: "https://app.emtool.eu/public/_lib/img/usr__NM__ico__NM__icon_ok_32.png",
                        link: "/timesheet",
                        id: "btn_save_detail",
                        disabled: false,
                        //class: "inverted",
                        title: "Salva",
                        width: 22
                    });

                }



                pulsantis.push({
                    text: "Conferma",
                    icon: "mdi-content-save",
                    image: "https://app.emtool.eu/public/_lib/img/cancel_32.png",
                    link: "/timesheet",
                    id: "btn_cancel",
                    disabled: false,
                    //class: "inverted",
                    title: "Annulla",
                    width: 21
                });

                this.$root.$children[0].bnavbuttons = pulsantis;


            }

            if (!v_show) {

                this.hideShowButtonsSelRow();

            }


        },


        updateTotalsGrid: function () {

            var trasporto = this.fattura.totale_trasporto;
            var id_iva = this.fattura.idinvoice_partita_iva;

            console.log("TRASPORTO: ", trasporto);
            console.log("ID_IVA: ", id_iva);
            console.log("ELE_IVA: ", this.ivaTemp);

            if (trasporto != null) {

                $("#v_tot_trasporto").text(trasporto.toString().replace(".", ","));
                $('#v_tot_trasporto').number(true, 2, ',', '.');

            }

            var subtotale = 0;
            $(".p_subtotale").each(function () {
                subtotale = subtotale + parseFloat($(this).text().replace(".", "").replace(",", "."));
            });

            this.fattura.totale = subtotale;

            $("#v_tot_subtotale").text(subtotale.toString().replace(".", ","));
            $('#v_tot_subtotale').number(true, 2, ',', '.');


            if (trasporto == null) {
                trasporto = 0;
            }

            var totale = parseFloat(subtotale) + parseFloat(trasporto);

            this.fattura.totale_parziale = totale;

            $("#v_tot_totale").text(totale.toString().replace(".", ","));
            $('#v_tot_totale').number(true, 2, ',', '.');


            var valore_iva = 0;

            if (id_iva != null) {



                var perc_iva = 0;

                for (var x = 0; x <= this.ivaTemp.length - 1; x++) {

                    if (parseInt(this.ivaTemp[x].idinvoice_partita_iva) == parseInt(id_iva)) {
                        perc_iva = this.ivaTemp[x].valore;
                    }

                }

                console.log("PERC IVA: ", perc_iva);

                valore_iva = (subtotale * perc_iva) / 100;

                console.log("VALORE IVA: ", valore_iva);


            }

            this.fattura.totale_iva = valore_iva;

            $("#v_tot_iva").text(valore_iva.toString().replace(".", ","));
            $('#v_tot_iva').number(true, 2, ',', '.');


            var totale_pagare = totale + valore_iva;

            this.fattura.totale_pagare = totale_pagare;

            $("#v_tot_pagare").text(totale_pagare.toString().replace(".", ","));
            $('#v_tot_pagare').number(true, 2, ',', '.');



        },


        deleteDetails: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.$swal

                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare gli elementi selezionati ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_invoiceweb.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_invoiceweb.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {

                        var ids = "";

                        $(".val_status_dett").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });

                        console.log("IDS SEL:", ids);



                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;



                        var response = await apiinvoice.deleteDetail(
                            v_token,
                            ids
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteDetail", res);

                            that.$swal({
                                icon: "success",
                                text: "Dettagli eliminati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.setupButtons();

                                window.table.ajax.reload();


                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },


        validateSaveDatail: function () {

            var errore = "";

            console.log("FATT DET: ", this.fattura_dettaglio);

            if ($("#dettaglio_descrizione").val() == "") {
                errore = "Specificare la descrizione";
            }

            if (errore == "") {

                if ($("#idinvoice_unita_di_misura").val() == "") {
                    errore = "Specificare l'unità di misura";
                }

            }

            if (errore == "") {

                if (this.fattura_dettaglio.quantita == 0) {
                    errore = "Specificare una quantita maggiore di 0";
                }

            }

            if (errore == "") {

                if (this.fattura_dettaglio.prezzo_unitario == 0) {
                    errore = "Specificare una prezzo unitario maggiore di 0";
                }

            }

            if (errore == "") {

                if ($("#idinvoice_valuta").val() == "") {
                    errore = "Specificare la valuta";
                }

            }



            if (errore == "") {

                this.saveDetail();

            }
            else {

                window.numEventSaveDetail = 0;

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });


            }



        },

        hideShowSaveButton: function () {

            console.log("FATTURA: ", this.fattura);

            var enable = 1;

            if (this.fattura.idinvoice_tipologia == 0) {

                console.log("1");

                enable = 0;
            }

            if (enable == 1) {


                if (this.fattura.numero == "") {

                    console.log("2");

                    enable = 0;

                }

            }

            if (enable == 1) {


                if (this.fattura.companyid == "0") {

                    console.log("3");

                    enable = 0;

                }

            }

            if (enable == 1) {


                if (this.fattura.company == "0") {

                    console.log("4");

                    enable = 0;

                }

            }

            if (enable == 1) {


                if ((this.fattura.data == "") || (this.fattura.data == null)) {

                    console.log("5");

                    enable = 0;

                }

            }

            if (enable == 1) {

                console.log("LEN DATA SCADENZA: ", this.fattura.data_scadenza.length);


                if ((this.fattura.data_scadenza == "") || (this.fattura.data_scadenza == null)) {

                    console.log("6");

                    enable = 0;

                }

            }

            if (enable == 1) {


                if (this.fattura.idinvoice_partita_iva == 0) {

                    console.log("7");

                    enable = 0;

                }

            }


            if (enable == 1) {

                if ((this.fattura.idinvoice_tipologia == 1) || (this.fattura.idinvoice_tipologia == 6)) {

                    if (this.fattura.idinvoice_ordini == 0) {

                        console.log("8");

                        enable = 0;

                    }


                }


            }

            if (enable == 1) {

                if (this.fattura.idinvoice_tipologia == 3) {

                    if (this.fattura.idinvoice_banca_no_order == 0) {

                        console.log("9");

                        enable = 0;

                    }


                }


            }

            if (enable == 1) {

                if (this.fattura.idinvoice_tipologia == 2) {

                    if (this.fattura.idinvoice_reference_nc == 0) {

                        console.log("10");

                        enable = 0;

                    }


                }


            }

            if (enable == 1) {

                if (this.fattura.idinvoice_tipologia == 3) {

                    if (this.fattura.idinvoice_tipo_pagamento_no_order == 0) {

                        console.log("11");

                        enable = 0;

                    }


                }


            }

            if (enable == 1) {

                if (this.fattura.totale > 0) {

                    if (this.fattura.idinvoice_partita_iva == 0) {

                        console.log("12");

                        enable = 0;

                    }


                }


            }

            return enable;

        },

        hideShowButtonsSelRow: function () {

            var tot = 0;

            $(".val_status_dett").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                }

            });

            var v_view_save_btn = this.hideShowSaveButton();

            setTimeout(() => {

                var pulsantis = [];



                if (v_view_save_btn == 1) {

                    pulsantis.push({
                        text: "Salva",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_save",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/save_update_data-invoiceweb.png",
                        title: "Salva",
                        width: 30

                    });


                }



                pulsantis.push({
                    text: "Add",
                    icon: "mdi-home-circle",
                    image: "https://app.emtool.eu/public/_lib/img/xbox-cross-invoiceweb.png",
                    link: "/settings",
                    id: "btn_add",
                    disabled: false,
                    title: "Aggiungi Dettaglio",
                    width: 30

                });

                if (tot == 1) {

                    pulsantis.push({
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/edit-row-invoiceweb.png",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                        title: "Modifica Dettaglio",
                        width: 30


                    });

                }

                if (tot > 0) {

                    pulsantis.push({
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/delete-invoiceweb.png",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        title: "Elimina Dettaglio",
                        width: 30


                    });

                }

                pulsantis.push({
                    text: "Indietro",
                    icon: "mdi-arrow-left",
                    link: "/confOrariChoice",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro",
                    image: "https://app.emtool.eu/public/_lib/img/back-invoiceweb.png",
                    width: 35

                });





                this.$root.$children[0].bnavbuttons = pulsantis;



            }, 100);



        },




        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },

        setupGrid: function () {

            // var that = this;
            //  var pathLocal = that.$withBase('/');

            var pointerVue = this;




            // var filtri = ``;



            const date = new Date().toJSON().slice(0, 10)


            $(document).ready(function () {

                var v_token = window.$cookies.get("token");

                window.columns = [


                    {
                        data: 'sel_row',
                        name: '',
                        width: '1%',
                    },


                    {
                        data: 'codice',
                        name: 'codice',
                        width: '1%',
                    },

                    {
                        data: 'descrizione',
                        name: 'descrizione',
                        width: '1%',
                    },

                    {
                        data: 'simbolo',
                        name: 'simbolo',
                        width: '1%',
                    },

                    {
                        data: 'quantita',
                        name: 'quantita',
                        width: '1%',
                    },

                    {
                        data: 'prezzo_unitario',
                        name: 'prezzo_unitario',
                        width: '1%',
                    },

                    {
                        data: 'simb_val',
                        name: 'simb_val',
                        width: '1%',
                    },

                    {
                        data: 'totale',
                        name: 'totale',
                        width: '1%',
                    },


                ];




                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: false,
                    paging: false,
                    serverSide: false,

                    "sDom": "lfrti",

                    data: window.dataset,


                    ajax: {
                        url: pointerVue.getUrlDomain("InvoiceWebClientiGetInvDetailsApi") + '?username=' + v_token,
                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start = window.perpage;

                            valori.start = 0;
                            valori.length = window.perpage;
                            valori.currDay = window.currDay;
                            valori.currDayTo = window.currDayTo;
                            valori.curStatus = window.curStatus;
                            valori.curPiano = window.curPiano;

                            valori.curCustomer = window.curCustomer;
                            valori.curPayment = window.curPayment;

                            valori.dateScadDa = window.dateScadDa;
                            valori.dateScadA = window.dateScadA;

                            valori.dateEmissDa = window.dateEmissDa;
                            valori.dateEmissA = window.dateEmissA;



                            var valSearch = $("#myTable_filter input[type='search']").val();

                            var search = {
                                "value": $("#myTable_filter input[type='search']").val(),
                                "regex": false
                            };

                            if (valSearch.length > 0) {
                                valori.search = search;
                                valori.columns = window.columns;
                                console.log(window.columns);
                            }



                            window.valori = valori;
                            return valori;

                        }

                    },




                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: ''
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        setTimeout(() => {

                            pointerVue.showSpinner = false;
                            pointerVue.$root.$children[0].showProgress = false;


                        }, 2000);





                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {

                            //   $("#myTable_filter").width($("#myTable").width());

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();


                        $('.p_prezzo_unitario, .p_subtotale').number(true, 2, ',', '.');

                        $('.p_quantita').number(true, 3, ',', '.');




                        pointerVue.updateTotalsGrid();


                    }

                });

                //Events
                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();
                            var valori = window.valori;
                            valori.start = window.start;

                            $.ajax({
                                url: pointerVue.getUrlDomain("InvoiceWebClientiGetInvDetailsApi") + '?username=' + v_token,
                                data: window.valori,
                                success: function (data) {
                                    $(".spin").hide();
                                    window.loading = 0;
                                    window.start += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log(index, val);
                                        window.table.row.add(val).draw(false);
                                        //  window.dataset.push(val);
                                    }
                                    console.log(data.data);
                                },
                            });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                //alert (pathLocal);

                //                 $("#myTable_filter").prepend(`
                //     <div class="cont_buttons">

                //         <div class='wrap_buttons'><b>Dettagli</b>


                //         ` + filtri + `

                //         </div>

                //     </div>
                // `);




                $('body').on('click', '.selcliente', function () {

                    console.log(("EVENTO CLICK SELCLIENTE"));

                    pointerVue.sheetClienti = true;

                });


                $('body').on('click', '.selpdffatture', function () {

                    console.log(("EVENTO CLICK SELPDFFATTURE"));

                    var errore = "";

                    var tot = 0;
                    var ids = "";

                    $(".val_status_dett").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }

                            ids = ids + $(this).attr("data-id");

                            tot = tot + 1;

                        }

                    });


                    if (tot == 0) {

                        errore = "Selezionare almeno una fattura";

                    }

                    if (errore == "") {

                        if (tot > 1) {

                            errore = "Selezionare solo una fattura";

                        }


                    }


                    if (errore == "") {

                        pointerVue.pdfFattura(ids);


                    }

                    if (errore != "") {

                        pointerVue.$swal({
                            icon: "error",
                            text: errore,
                            showConfirmButton: false,
                            timer: 2000
                        });



                    }

                });




                $('body').on('click', '.selpdfanticipi', function () {

                    console.log(("EVENTO CLICK SELPDFANTICIPI"));

                    var errore = "";

                    var tot = 0;
                    var ids = "";

                    $(".val_status_dett").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }

                            ids = ids + $(this).attr("data-id");

                            tot = tot + 1;

                        }

                    });


                    if (tot == 0) {

                        errore = "Selezionare almeno una fattura";

                    }

                    if (errore == "") {

                        if (tot > 1) {

                            errore = "Selezionare solo una fattura";

                        }


                    }


                    if (errore == "") {

                        pointerVue.pdfAnticipo(ids);


                    }

                    if (errore != "") {

                        pointerVue.$swal({
                            icon: "error",
                            text: errore,
                            showConfirmButton: false,
                            timer: 2000
                        });



                    }






                });



                $('body').on('click', '.selscadenziario', function () {

                    console.log(("EVENTO CLICK SELSCADENZIARIO"));

                    var tot = 0;
                    var ids = "";

                    $(".val_status_dett").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }

                            ids = ids + $(this).attr("data-id");

                            tot = tot + 1;

                        }

                    });

                    var errore = "";

                    if (tot == 0) {
                        errore = "Selezionare almeno una elemento";
                    }

                    if (errore == "") {

                        if (tot > 1) {
                            errore = "Selezionare solo un elemento";
                        }

                    }



                    if (errore == "") {

                        window.$cookies.set("type_fatt_from", "Clienti", "9y");

                        router.push({
                            path: "/scadenziario/" + ids
                        });

                    }
                    else {

                        pointerVue.$swal({
                            icon: "error",
                            text: errore,
                            showConfirmButton: false,
                            timer: 2000
                        });


                    }


                });



                $('body').on('click', '.selexportgridpdf', function () {
                    console.log("EVENT SELEXPORTGRIDPDF");


                    pointerVue.exportGridToPdf();

                });


                $('body').on('click', '.inviamessaggio', function () {

                    console.log("EVENT INVIAMESSAGGIO");

                    var tot = 0;
                    var ids = "";

                    $(".val_status_dett").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }

                            ids = ids + $(this).attr("data-id");

                            tot = tot + 1;

                        }

                    });

                    console.log("IDS: ", ids);

                    if (tot > 0) {

                        window.$cookies.set("id_users_doc_send", ids, "9y");

                        router.push({
                            path: "/sendMessage"
                        });

                    }

                    if (tot == 0) {

                        pointerVue.$swal({
                            icon: "error",
                            text: "Select the users to send the message to",
                            showConfirmButton: false,
                            timer: 2000
                        });


                    }



                });



                $('body').on('click', '.paginate_button', function () {
                    $(".spin").show();

                });

                $('body').on('click', '.refreshButton', function () {

                    $(".spin").show();
                    window.table._fnDraw();

                });


                $('body').on('click', '.cont_dettagli_fattura tr', function () {

                    console.log("TR-ACTIVE");

                    setTimeout(() => {

                        var row_data = window.table.row(this).data();

                        if (row_data != null) {


                            console.log("ROW DATA: ", row_data);


                            var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            var id_sel = $(this).find(".id_row").attr("data-id");


                            var set_cls_active = 0;

                            if (last_id_sel == "undefined") {
                                set_cls_active = 1;
                            } else {
                                if (last_id_sel != id_sel) {
                                    set_cls_active = 1;
                                }
                            }

                            console.log("ROM IS ACTIVE: ", set_cls_active);



                            console.log("THIS: ", this);

                            var hasClassActive = $(this).hasClass("active-row");

                            if (hasClassActive) {

                                console.log("ACTIVE-ROW");

                                $(this).find(".val_status_dett").prop("checked", false);

                                $(this).removeClass("active-row");

                                //  pointerVue.updateCalculator(row_data.totale_pagare_calc, "+");

                            }

                            if (!hasClassActive) {

                                console.log("NOT ACTIVE-ROW");

                                $(this).find(".val_status_dett").prop("checked", true);

                                $(this).addClass("active-row");

                                //  pointerVue.updateCalculator(row_data.totale_pagare_calc, "-");


                            }

                            window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            if (window.id_par_sel == undefined) {
                                window.id_par_sel = id_sel;
                            }

                            console.log("ID SELECTED: ", window.id_par_sel);


                            console.log("FIND val_status_dett: ", $(this).find(".val_status_dett"));

                            var find_check = $(this).find(".val_status_dett");
                            if (find_check.is(":checked")) {
                                console.log("SELEZIONATO");

                            }

                            if (!find_check.is(":checked")) {
                                console.log("DESELEZIONATO");

                            }


                            pointerVue.hideShowButtonsSelRow();


                        }



                    }, 100);

                });


                $(".selcambiaclientifornitori").on("ionChange", function (opt) {
                    console.log(opt);



                    if (this.value == 2) {


                        router.push({
                            path: "/fattureFornitori"
                        });



                    }


                });



                $('body').on('click', '.val_status_dett', function (event) {
                    event.stopPropagation();

                    var checked = $(this).is(":checked");

                    console.log("CHECKED: ", checked);

                    var v_row = $(this).parent().parent();

                    console.log("V_ROW: ", v_row);

                    if (checked) {

                        $(v_row).addClass("active-row");

                        var item = window.table.row(v_row).data();

                        console.log("ITEM ROW SEL: ", item.totale_pagare_calc);

                        window.id_par_sel = item.id;


                        //     pointerVue.updateCalculator(item.totale_pagare_calc, "+");


                    }

                    if (!checked) {

                        var item2 = window.table.row(v_row).data();

                        console.log("ITEM ROW DESEL: ", item2.totale_pagare_calc);

                        $(v_row).removeClass("active-row");


                        //  pointerVue.updateCalculator(item2.totale_pagare_calc, "-");



                    }

                    pointerVue.hideShowButtonsSelRow();

                });



                // }

                //setup ionic calendar top
                setTimeout(function () {

                    $("body").on("ionChange", ".dateBook", function (opt) {

                        console.log(opt);
                        var date = this.value;
                        //var dateMysql = date.toJSON().slice(0, 19).replace('T', ' ')   ;
                        dateMysql = date.substr(0, 10);

                        console.log(dateMysql);

                        $.ajax({
                            url: "{{ route('smartbook.updateDate') }}",
                            data: {
                                "id": $(this).data("id"),
                                "date": dateMysql
                            },
                        });

                    });

                    $(".selectstatusall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curStatus = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    $(".selectpianiall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curPiano = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    window.currDay = date;
                    window.currDayTo = date;

                }, 600);

                window.parentiAddedEvents = true;

            });

        },



        setValute: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("VALUTE TMP: ", this.valuteTemp);

            var Valute = [];

            if (id_azienda != "") {

                for (var i = 0; i < this.valuteTemp.length; i++) {

                    if (parseInt(this.valuteTemp[i].companyid) == parseInt(id_azienda)) {

                        Valute.push({
                            idinvoice_valute: this.valuteTemp[i].idinvoice_valute,
                            simbolo: this.valuteTemp[i].simbolo
                        });

                    }

                }


            }


            console.log("VALUTE SEL: ", Valute);

            this.valute = Valute;

        },





        setIVA: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("UTENTI TMP: ", this.ivaTemp);

            var Iva = [];

            if (id_azienda != "") {

                for (var i = 0; i < this.ivaTemp.length; i++) {

                    if (parseInt(this.ivaTemp[i].companyid) == parseInt(id_azienda)) {

                        Iva.push({
                            idinvoice_partita_iva: this.ivaTemp[i].idinvoice_partita_iva,
                            desc_valuta: this.ivaTemp[i].desc_valuta
                        });

                    }

                }


            }


            console.log("UT ASS SEL: ", Iva);

            this.iva = Iva;

        },

        setBanche: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("UTENTI TMP: ", this.bancheTemp);

            var Banche = [];

            if (id_azienda != "") {

                for (var i = 0; i < this.bancheTemp.length; i++) {

                    if (parseInt(this.bancheTemp[i].companyid) == parseInt(id_azienda)) {

                        Banche.push({
                            idinvoice_banche: this.bancheTemp[i].idinvoice_banche,
                            nome: this.bancheTemp[i].nome
                        });

                    }

                }


            }


            console.log("UT ASS SEL: ", Banche);

            this.banche = Banche;

        },

        setTipiPagamento: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("UTENTI TMP: ", this.tipiPagamentoTemp);

            var TipiPagamento = [];

            if (id_azienda != "") {

                for (var i = 0; i < this.tipiPagamentoTemp.length; i++) {

                    if (parseInt(this.tipiPagamentoTemp[i].companyid) == parseInt(id_azienda)) {

                        TipiPagamento.push({
                            idinvoice_tipo_pagamento: this.tipiPagamentoTemp[i].idinvoice_tipo_pagamento,
                            descrizione: this.tipiPagamentoTemp[i].descrizione
                        });

                    }

                }

            }


            console.log("UT ASS SEL: ", TipiPagamento);

            this.tipiPagamento = TipiPagamento;

        },


        setTrasporti: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("UTENTI TMP: ", this.trasportoTemp);

            var Trasporto = [];

            if (id_azienda != "") {

                for (var i = 0; i < this.trasportoTemp.length; i++) {

                    if (parseInt(this.trasportoTemp[i].our_company) == parseInt(id_azienda)) {

                        Trasporto.push({
                            idwarehouse_trasportation_methods: this.trasportoTemp[i].idwarehouse_trasportation_methods,
                            description: this.trasportoTemp[i].description
                        });

                    }

                }


            }


            console.log("UT ASS SEL: ", Trasporto);

            this.trasporto = Trasporto;

        },

        setFattureRiferimento: function (id_azienda, id_cliente) {

            console.log("AZI SEL 2: ", id_azienda);
            console.log("CLI SEL 2: ", id_cliente);

            console.log("UTENTI TMP: ", this.fattureRiferimentoTemp);

            var FattureRiferimento = [];

            if ((id_azienda != "") && (id_cliente != "")) {

                for (var i = 0; i < this.fattureRiferimentoTemp.length; i++) {

                    if ((parseInt(this.fattureRiferimentoTemp[i].companyid) == parseInt(id_azienda)) && (parseInt(this.fattureRiferimentoTemp[i].company) == parseInt(id_cliente))) {

                        FattureRiferimento.push({
                            idinvoice: this.fattureRiferimentoTemp[i].idinvoice,
                            numero: this.fattureRiferimentoTemp[i].numero
                        });

                    }

                }


            }


            console.log("UT ASS SEL: ", FattureRiferimento);

            this.fattureRiferimento = FattureRiferimento;

        },

        setOrdine: function (id_azienda, id_cliente) {

            console.log("AZI SEL 2: ", id_azienda);
            console.log("CLI SEL 2: ", id_cliente);

            console.log("UTENTI TMP: ", this.ordiniTemp);

            var Ordini = [];

            if ((id_azienda != "") && (id_cliente != "")) {

                for (var i = 0; i < this.ordiniTemp.length; i++) {

                    if ((parseInt(this.ordiniTemp[i].company) == parseInt(id_azienda)) && (parseInt(this.ordiniTemp[i].companyid) == parseInt(id_cliente))) {

                        Ordini.push({
                            idinvoice_ordini: this.ordiniTemp[i].idinvoice_ordini,
                            num: this.ordiniTemp[i].num
                        });

                    }

                }


            }


            console.log("UT ASS SEL: ", Ordini);

            this.ordini = Ordini;

        },

        setUM: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("UTENTI TMP: ", this.unitaDiMisuraTemp);

            var UnitaDiMisura = [];

            if (id_azienda != "") {

                for (var i = 0; i < this.unitaDiMisuraTemp.length; i++) {

                    if (parseInt(this.unitaDiMisuraTemp[i].companyid) == parseInt(id_azienda)) {

                        UnitaDiMisura.push({
                            idinvoice_unita_di_misura: this.unitaDiMisuraTemp[i].idinvoice_unita_di_misura,
                            simbolo: this.unitaDiMisuraTemp[i].simbolo
                        });

                    }

                }


            }


            console.log("UT ASS SEL: ", UnitaDiMisura);

            this.unitaDiMisura = UnitaDiMisura;

        },



        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.fattura.companyid = azienda.companyid;

            this.setIVA(azienda.companyid);

            this.setBanche(azienda.companyid);

            this.setTipiPagamento(azienda.companyid);

            this.setFattureRiferimento(azienda.companyid, this.fattura.company);

            this.setOrdine(azienda.companyid, this.fattura.company);

            this.setTrasporti(azienda.companyid);

            this.setUM(azienda.companyid);


            this.hideShowButtonsSelRow();


        },


        manageClickClienti: async function (cliente) {

            console.log("CLI SEL: ", cliente);

            this.sheetClienti = false;

            this.fattura.company = cliente.companyid;

            this.setFattureRiferimento(this.fattura.companyid, cliente.companyid);

            this.setOrdine(this.fattura.companyid, cliente.companyid);

            this.hideShowButtonsSelRow();

        },



        manageClickGruppi: async function (gruppo) {

            console.log("GRP SEL: ", gruppo);

            this.sheetGruppi = false;

            this.banca.idmailgroup = gruppo.idmailgroup;

        },

        setupButtons: async function () {

            console.log("SETUP BUTTONS");

            var v_view_save_btn = this.hideShowSaveButton();

            //  console.log(v_save_btn);


            setTimeout(() => {

                var pulsantis = [];

                if (v_view_save_btn == 1) {
                    pulsantis.push({
                        text: "Salva",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_save",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/save_update_data-invoiceweb.png",
                        title: "Salva",
                        width: 30

                    });
                }


                pulsantis.push({
                    text: "Add",
                    icon: "mdi-home-circle",
                    image: "https://app.emtool.eu/public/_lib/img/xbox-cross-invoiceweb.png",
                    link: "/settings",
                    id: "btn_add",
                    disabled: false,
                    title: "Aggiungi Dettaglio",
                    width: 30
                });

                pulsantis.push({
                    text: "Parenti",
                    icon: "mdi-arrow-left",
                    link: "/parenti",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro",
                    image: "https://app.emtool.eu/public/_lib/img/back-invoiceweb.png",
                    width: 35

                });


                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var that = this;

            console.log("FATTURA: ", that.fattura);

            var errore = "";

            if (that.fattura.idinvoice_tipologia == 0) {

                errore = "Specificare la tipologia nella sezione informazioni generali";

            }

            if (errore == "") {


                if (that.fattura.numero == "") {

                    errore = "Specificare il numero nella sezione informazioni generali";

                }

            }

            if (errore == "") {


                if (that.fattura.companyid == "0") {

                    errore = "Specificare la nostra azienda nella sezione informazioni generali";

                }

            }

            if (errore == "") {


                if (that.fattura.company == "0") {

                    errore = "Specificare il cliente nella sezione informazioni generali";

                }

            }

            if (errore == "") {


                if (that.fattura.data == "") {

                    errore = "Specificare la data nella sezione informazioni generali";

                }

            }

            if (errore == "") {


                if (that.fattura.data_scadenza == "") {

                    errore = "Specificare la data di scadenza nella sezione informazioni generali";

                }

            }

            if (errore == "") {


                if (that.fattura.idinvoice_partita_iva == 0) {

                    errore = "Specificare il valore dell'iva nella sezione informazioni generali";

                }

            }


            if (errore == "") {

                if ((that.fattura.idinvoice_tipologia == 1) || (that.fattura.idinvoice_tipologia == 6)) {

                    if (that.fattura.idinvoice_ordini == 0) {

                        errore = "Specificare il numero ordine nella sezione ordini";

                    }


                }


            }

            if (errore == "") {

                if (that.fattura.idinvoice_tipologia == 3) {

                    if (that.fattura.idinvoice_banca_no_order == 0) {

                        errore = "Specificare la banca nella sezione informazioni generali";

                    }


                }


            }

            if (errore == "") {

                if (that.fattura.idinvoice_tipologia == 2) {

                    if (that.fattura.idinvoice_reference_nc == 0) {

                        errore = "Specificare la fattura di riferimento nella sezione informazioni generali";

                    }


                }


            }

            if (errore == "") {

                if (that.fattura.idinvoice_tipologia == 3) {

                    if (that.fattura.idinvoice_tipo_pagamento_no_order == 0) {

                        errore = "Specificare i termini pagamento nella sezione informazioni generali";

                    }


                }


            }

            if (errore == "") {

                if (that.fattura.totale > 0) {

                    if (that.fattura.idinvoice_partita_iva == 0) {

                        errore = "Specificare il valore dell'iva nella sezione informazioni generali";

                    }


                }


            }


            if (errore == "") {


                console.log("ok");

                that.saveData();

            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });


            }

        },


        dettagliOrdineOnEdit: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            console.log("TOKEN: ", v_token);
            console.log("DATA: ", that.fattura.data);
            console.log("ID ORDINE: ", that.fattura.idinvoice_ordini);


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiinvoice.getDettagliOrdine(
                that.fattura.data,
                that.fattura.idinvoice_ordini,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getDettagliOrdine", res);

                that.codiceOrdine = res.data.CodeOrder;
                that.dataInizioOrdine = res.data.DateStartOrder;
                that.dataFineOrdine = res.data.DateEndOrder;
                that.importoTotaleOrdine = res.data.AmountOrder;
                that.fatturatoOrdine = res.data.InvoicedOrder;
                that.risorseOrdine = res.data.ResourceOrder;



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },



        dettagliOrdine: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            console.log("TOKEN: ", v_token);
            console.log("DATA: ", that.fattura.data);
            console.log("ID ORDINE: ", that.fattura.idinvoice_ordini);


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiinvoice.getDettagliOrdine(
                that.fattura.data,
                that.fattura.idinvoice_ordini,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getDettagliOrdine", res);

                that.codiceOrdine = res.data.CodeOrder;
                that.dataInizioOrdine = res.data.DateStartOrder;
                that.dataFineOrdine = res.data.DateEndOrder;
                that.importoTotaleOrdine = res.data.AmountOrder;
                that.fatturatoOrdine = res.data.InvoicedOrder;
                that.risorseOrdine = res.data.ResourceOrder;

                that.fattura.data_scadenza = res.data.DeliveryDate;


                setTimeout(() => {

                    that.hideShowButtonsSelRow();

                }, 600);



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },


        saveDetail: async function () {

            var that = this;

            that.$modal.hide('popupEditDettaglio');

            that.fattura_dettaglio.idinvoice_unita_di_misura = $("#idinvoice_unita_di_misura").val();
            that.fattura_dettaglio.idinvoice_valuta = $("#idinvoice_valuta").val();
            that.fattura_dettaglio.totale = that.totaleDettaglio;

            console.log("FATT DET: ", that.fattura_dettaglio);

            var v_token = window.$cookies.get("token");
            var v_id_azienda = window.$cookies.get("sel_filter_azienda_fatture");

            if (window.operationDetail == 0) { // insert

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                response = await apiinvoice.insertDetail(
                    that.fattura_dettaglio,
                    v_token,
                    v_id_azienda,
                    $("#idinvoice_ordini").val()
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from insertDetail", res);

                    that.$swal({
                        icon: "success",
                        text: "Dettaglio aggiunto con successo",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        window.table.ajax.reload();

                        that.hideShowButtonsSelRow();

                    }, 200);

                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );

            }

            if (window.operationDetail == 1) { // edit

                var ids = "";

                $(".val_status_dett").each(function (index) {

                    console.log(index);

                    if ($(this).prop("checked")) {

                        if (ids != "") {
                            ids = ids + ",";
                        }
                        ids = ids + $(this).attr("data-id");
                    }

                });

                console.log("IDS SEL:", ids);


                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                response = await apiinvoice.updateDetail(
                    that.fattura_dettaglio,
                    v_token,
                    v_id_azienda,
                    $("#idinvoice_ordini").val(),
                    ids
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from updateDetail", res);

                    that.$swal({
                        icon: "success",
                        text: "Dettaglio modificato con successo",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        window.table.ajax.reload();

                    }, 200);

                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );

            }





            window.numEventSaveDetail = 0;

        },



        syncDetail: async function () {

            var ids = "";

            $(".val_status_dett").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);


            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiinvoice.getDetail(
                ids,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getDetail", res);



                setTimeout(() => {

                    that.fattura_dettaglio = res.data.Result;

                    that.totaleDettaglio = res.data.Result.totale;

                    that.titoloPopup = "Modifica Dettaglio";

                    that.$modal.show('popupEditDettaglio');


                    that.hideShowButtonsEditRowDetails(true);


                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );





        },


        saveData: async function () {

            var that = this;

            console.log("FATTURA: ", that.fattura);

            var v_token = window.$cookies.get("token");

            var response = null;


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            response = await apiinvoice.checkNumber(
                that.fattura.numero,
                that.fattura.companyid,
                that.$route.params.id,
                v_token
            ).then(async (res) => {


                if (res.data.Result == "OK") {




                    var response2 = null;

                    response2 = await apiinvoice.saveInvoice(
                        that.fattura,
                        v_token,
                        that.$route.params.id
                    ).then((res2) => {

                        console.log(response2);

                        that.$root.$children[0].showProgress = false;
                        console.log("res from saveInvoice", res2);

                        that.$swal({
                            icon: "success",
                            text: "Dati salvati correttamente",
                            showConfirmButton: false,
                            timer: 2000
                        });

                        setTimeout(() => {

                            that.dialogMsg = false;
                            that.setupButtons();

                            window.$cookies.set("is_from_edit_fatt", 1, "9y");


                            if (this.isAgila == 0) {

                                router.push({
                                    path: "/fattureClienti"
                                });


                            }

                            if (this.isAgila == 1) {

                                router.push({
                                    path: "/fattureClientiAgila"
                                });


                            }


                        }, 200);


                    }).catch(err2 => {
                        that.$root.$children[0].showProgress = false;
                        console.log(err2);
                        var msg = err2.response.data.Error;

                        that.$swal({
                            icon: "error",
                            text: msg,
                            showConfirmButton: false,
                            timer: 8000
                        });
                        console.log("ERrori", "Non è stato possibile salvare i dati");
                        console.log("response", response);

                    }

                    );


                }
                else {

                    that.$root.$children[0].showProgress = false;

                    that.$swal({
                        icon: "error",
                        text: res.data.Errore,
                        showConfirmButton: false,
                        timer: 2000
                    });



                }




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );





        },



        saveDataOld: async function () {

            var that = this;

            console.log("FATTURA: ", that.fattura);

            var v_token = window.$cookies.get("token");

            var response = null;


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            response = await apiinvoice.saveInvoice(
                that.fattura,
                v_token,
                that.$route.params.id
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from saveInvoice", res);

                that.$swal({
                    icon: "success",
                    text: "Dati salvati correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.dialogMsg = false;
                    that.setupButtons();

                    // router.push({
                    //     path: "/fattureClienti"
                    // });

                    if (this.isAgila == 0) {

                        router.push({
                            path: "/fattureClienti"
                        });


                    }

                    if (this.isAgila == 1) {

                        router.push({
                            path: "/fattureClientiAgila"
                        });


                    }





                }, 200);


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );












        },

        syncUtente: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getUtente(
                that.$route.params.id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getUtente", res);

                try {

                    this.aziende = res.data.aziende;
                    this.prefissi = res.data.prefix;
                    this.gruppi = res.data.models;
                    this.utente = res.data.curr_user;

                    this.utente.codice_fiscale = res.data.val_codice_fiscale;

                    if (res.data.curr_user.models != null) {

                        var grp_selected = res.data.curr_user.models.split(",");

                        console.log("GRP SEL: ", grp_selected);

                        if (grp_selected.length > 0) {

                            for (var i = 0; i < this.gruppi.length; i++) {

                                if (this.gruppi[i] !== undefined) {

                                    for (var x = 0; x < grp_selected.length; x++) {

                                        console.log("GRP SEL 2: ", grp_selected[x]);

                                        if (parseInt(this.gruppi[i].id) == parseInt(grp_selected[x])) {

                                            this.gruppi[i].checked = this.gruppi[i].id;

                                        }

                                    }

                                }

                            }

                        }


                    }




                    $("#itemAzienda").addClass("item-has-value");


                    console.log("USER INFO EASYER: ", res.data.info_easyer);


                    if (res.data.info_easyer != null) {

                        this.utente.enable_easyer = res.data.info_easyer.enable;

                        this.utente.max_easyer = res.data.info_easyer.max_value;

                        this.utente.datestart_easyer = res.data.info_easyer.period_from;

                        this.utente.dateend_easyer = res.data.info_easyer.period_to;


                    }






                    //console.log("ok");

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.createUtenteApi(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from syncAziende", res);

                try {

                    this.aziende = res.data.aziende;

                    this.prefissi = res.data.prefix;

                    this.gruppi = res.data.models;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



        initInsert: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");
            var v_id_azienda = window.$cookies.get("sel_filter_azienda_fatture");



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiinvoice.initInsertClienti(
                v_token,
                v_id_azienda,
                "invoice"
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initInsertClienti", res);


                this.tipologie = res.data.tipologia;
                this.aziende = res.data.our_company;
                this.clienti = res.data.customer;
                this.ivaTemp = res.data.vat;
                this.bancheTemp = res.data.banks;
                this.tipiPagamentoTemp = res.data.type_paym;
                this.fattureRiferimentoTemp = res.data.invoice_nc;
                this.ordiniTemp = res.data.ordine;
                this.trasportoTemp = res.data.trasporto;
                this.tipiContenitore = res.data.type_container;
                this.unitaDiMisuraTemp = res.data.um;
                this.valuteTemp = res.data.valute;

                this.fattura.companyid = v_id_azienda;

                this.idValutaDef = res.data.id_valuta_def;

                this.setIVA(v_id_azienda);

                var today = new Date();
                var year = today.getFullYear();
                var month = (today.getMonth() + 1);
                var day = today.getDate();
                if (month < 10) {
                    month = "0" + month;
                }
                if (day < 10) {
                    day = "0" + day;
                }
                var date = year + '-' + month + '-' + day;

                this.fattura.data = date;

                this.fattura.numero = res.data.new_num_fatt;

                this.setBanche(v_id_azienda);

                this.setTipiPagamento(v_id_azienda);

                this.setFattureRiferimento(v_id_azienda, "");

                this.setOrdine(v_id_azienda, "");

                this.setTrasporti(v_id_azienda);

                this.setUM(v_id_azienda);

                this.setValute(v_id_azienda);


                // iva di default 22
                this.fattura.idinvoice_partita_iva = res.data.id_iva_def;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



        initEdit: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");
            // var v_id_azienda = window.$cookies.get("sel_filter_azienda_fatture");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiinvoice.initEditClienti(
                v_token,
                this.$route.params.id,
                "invoice"
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initEditClienti", res);

                this.tipologie = res.data.tipologia;
                this.aziende = res.data.our_company;
                this.clienti = res.data.customer;
                this.ivaTemp = res.data.vat;
                this.bancheTemp = res.data.banks;
                this.tipiPagamentoTemp = res.data.type_paym;
                this.fattureRiferimentoTemp = res.data.invoice_nc;
                this.ordiniTemp = res.data.ordine;
                this.trasportoTemp = res.data.trasporto;
                this.tipiContenitore = res.data.type_container;
                this.unitaDiMisuraTemp = res.data.um;
                this.valuteTemp = res.data.valute;

                this.setIVA(res.data.fattura.companyid);

                this.setBanche(res.data.fattura.companyid);

                this.setTipiPagamento(res.data.fattura.companyid);

                this.setFattureRiferimento(res.data.fattura.companyid, res.data.fattura.company);

                this.setOrdine(res.data.fattura.companyid, res.data.fattura.company);

                this.setTrasporti(res.data.fattura.companyid);

                this.setUM(res.data.fattura.companyid);

                this.setValute(res.data.fattura.companyid);



                // this.viewSecOrdine = true;

                if (res.data.fattura.idinvoice_tipologia == 3) {

                    this.viewBanca = true;
                    this.viewIban = true;
                    this.viewTipoPag = true;
                    this.viewFattRif = false;
                    this.viewSecOrdine = false;


                }
                else {

                    if (res.data.fattura.idinvoice_tipologia == 2) {

                        this.viewBanca = false;
                        this.viewIban = false;
                        this.viewTipoPag = false;
                        this.viewSecOrdine = false;
                        this.viewFattRif = true;

                    }
                    else {

                        if ((res.data.fattura.idinvoice_tipologia == 1) || (res.data.fattura.idinvoice_tipologia == 6)) {

                            this.viewBanca = false;
                            this.viewIban = false;
                            this.viewTipoPag = false;
                            this.viewFattRif = false;
                            this.viewSecOrdine = true;

                        }
                        else {

                            this.viewBanca = false;
                            this.viewIban = false;
                            this.viewTipoPag = false;
                            this.viewFattRif = false;
                            this.viewSecOrdine = false;

                        }


                    }



                }


                this.fattura = res.data.fattura;




                this.fattura.idinvoice_partita_iva = res.data.id_partita_iva;

                this.updateTotalsGrid();






                setTimeout(() => {

                    if ((res.data.fattura.idinvoice_tipologia == 1) || (res.data.fattura.idinvoice_tipologia == 6)) {

                        this.dettagliOrdineOnEdit();

                    }

                    this.hideShowButtonsSelRow();

                }, 300);





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },




        syncMailGroup: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apibanche.getGruppi(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from syncMailGroup", res);

                this.gruppi = res.data.invoice_mail_group;



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



        syncBanca: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apibanche.getBanca(
                that.$route.params.id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getBanca", res);

                this.aziende = res.data.aziende;

                this.gruppi = res.data.invoice_mail_group;

                this.banca = res.data.banca;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



        syncPrefix: async function () {

            var that = this;

            //var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getPrefix().then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPrefix", res);

                try {

                    this.prefissi = res.data.tel_prefix;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.cl_importo {
    padding-left: 0px !important;
}


.itemAzienda {
    margin-left: 15px;
}

.itemCliente {
    margin-left: 15px;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetClienti {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.itemGruppo {
    margin-left: 15px;
}

.headerSheetGruppi {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.w-container-fattura {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitleEditFattura {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 4;
    top: 65px;
    border: 1px solid black;
    background: white !important;
}

.editFatturaCliente .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}

.editFatturaCliente .v-input {
    font-size: 1.2em !important;
}

.editFatturaCliente .v-select {
    font-size: 1.2em !important;
}

.editFatturaCliente .v-label {
    font-size: 1em !important;
}

.v-tabs:not(.v-tabs--vertical) .v-tab {
    white-space: normal;
    font-size: 12px;
    padding: 0px;
}

.cl-del-date-fattura {
    width: 15px;
    transform: translate(395px, -54px);
    cursor: pointer;
}

.cl-del-date-scad-fattura {
    width: 15px;
    transform: translate(395px, -54px);
    cursor: pointer;
}




.cont_dettagli_fattura .dataTable tbody tr,
.cont_dettagli_fattura .dataTable tbody tr td {
    vertical-align: middle !important;
    padding-top: 5px !important;
}

/* .cont_dettagli_fattura #myTable {
    margin-right: 3px;
    width: 1500px !important;
} */

.cont_dettagli_fattura #myTable {
    margin-right: 3px;
    width: 400px !important;
}

.cont_dettagli_fattura table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_dettagli_fattura table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
    padding-top: 5px !important;
    vertical-align: middle;
}

.cont_dettagli_fattura th {
    padding-left: 5px !important;
}

.cont_dettagli_fattura ion-datetime.dateBook {
    margin-left: 16px;
}


.cont_dettagli_fattura .table-responsive {
    padding: 0px;
    min-width: 466px;
    max-width: 466px;
}





.cont_dettagli_fattura #myTable_filter {
    position: relative;
    left: 0px;
    min-width: 100%;
    z-index: 2;
    background-color: white;
    display: none
}

.cont_dettagli_fattura div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}


.cont_dettagli_fattura #myTable_wrapper {
    min-width: initial;
    min-width: 466px !important;
    max-width: 466px !important;
    width: 466px !important;
    margin-left: 0px !important;
    min-height: 600px !important;
}


.cont_dettagli_fattura .text_title {
    background-color: white;
    color: black;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 7px;
    font-size: 10px;
    min-width: 50px;
    text-align: left;
}

.cont_dettagli_fattura .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}


.cont_dettagli_fattura .cl_checkall_dett {
    padding-left: 1px;
    padding-top: 2px;
}

.cont_dettagli_fattura .checkall_dett {
    width: 13px;
    height: 13px;
}

.cont_dettagli_fattura .inviamessaggio {
    max-width: 25px;
    min-height: 25px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.cont_dettagli_fattura .cont_buttons {
    display: flex !important;
    align-items: center !important;
    float: left !important;
    padding-left: 10px !important;
    justify-content: inherit !important;
    width: 100% !important;
}

.cont_dettagli_fattura .img_centered {
    width: 100% !important;
    text-align: center !important;
    min-width: 36px;
}


.cont_dettagli_fattura .cont-status {
    position: relative !important;
    overflow: hidden !important;
    width: 30px !important;
    height: 10px !important;
    margin-left: 22px !important;
}

.cont_dettagli_fattura .status {
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 50%;
    top: -11px;
    right: -11px;
}

.cont_dettagli_fattura .green {
    background: green;
}

.cont_dettagli_fattura .yellow {
    background: #ecec06;
}

.cont_dettagli_fattura .red {
    background: red;
}

.cont_dettagli_fattura .lightblue {
    background: lightblue;
}

.cont_dettagli_fattura .orange {
    background: orange;
}

.cont_dettagli_fattura .text_right {
    width: 100% !important;
    text-align: right !important;
}

.cont_dettagli_fattura .text_right2 {
    width: 100% !important;
    text-align: right !important;
}


.cont_dettagli_fattura .selcambiaclientifornitori {
    max-width: 30px !important;
    min-height: 30px !important;
    margin-left: -5px !important;
    color: black !important;
    padding: 0px 0px !important;
    min-width: 14px !important;
    text-indent: -119px !important;
    background-size: 29px !important;
    background-repeat: no-repeat !important;
    background-image: url(/invoiceweb/img/change_invoiceweb2.png) !important;
    z-index: 2 !important;
    border: none !important;
    cursor: pointer !important;
    margin-top: 2px !important;
}

.cont_dettagli_fattura .selcliente {
    max-width: 28px;
    min-height: 28px;
    margin-left: -1px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}

.cont_dettagli_fattura .selanticipi {
    max-width: 28px;
    min-height: 28px;
    margin-left: 1px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_dettagli_fattura .seleliminaanticipi {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_dettagli_fattura .selscadenziario {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}


.cont_dettagli_fattura .selpagamento {
    max-width: 28px;
    min-height: 28px;
    margin-left: 1px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.editFatturaCliente .cl-checkbox i {
    font-size: 13px !important;
}


.cont_dettagli_fattura div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0.5em !important;
    display: inline-block !important;
    width: auto !important;
    z-index: 99999 !important;
    min-width: 30px !important;
    min-height: 30px !important;
    background: white !important;
    border-radius: 50pxv;
    border: 1px solid black !important;
}

.cont_dettagli_fattura .wrap_buttons {
    max-height: 40px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.cont_dettagli_fattura .cl_valuta {
    font-weight: bold;
    text-align: center
}

.cont_dettagli_fattura .col_footer {
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    padding-left: 6px;
    border-top: 1px solid black
}

.cont_dettagli_fattura .col_footer_no_border {
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    padding-left: 6px;
}

.cont_dettagli_fattura .col_footer_right {
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    padding-left: 6px;
    border-top: 1px solid black;
    text-align: right;
    padding-right: 6px;
}

.cont_dettagli_fattura .val_status_dett {
    width: 10px !important;
}

.cont_dettagli_fattura .selpdffatture {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_dettagli_fattura .selpdfanticipi {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_dettagli_fattura .silinvfattcust {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_dettagli_fattura .sendmailalertcli {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_dettagli_fattura .selexportgridpdf {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_dettagli_fattura .selcopiafatt {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}


.cont_dettagli_fattura .text_right {
    width: 100% !important;
    text-align: right !important;
}

.cont_dettagli_fattura .cl_checkall_dett {
    padding-left: 1px;
    padding-top: 2px;
}

.cont_dettagli_fattura .checkall {
    width: 13px;
    height: 13px;
}

.cont_dettagli_fattura .col_footer {
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    padding-left: 6px;
    border-top: 1px solid black
}

.cont_dettagli_fattura .col_footer_right {
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    padding-left: 6px;
    border-top: 1px solid black;
    text-align: right;
    padding-right: 6px;
}

.cont_dettagli_fattura .col_footer_right_no_border {
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    padding-left: 6px;
    text-align: right;
    padding-right: 6px;
}

.cont_dettagli_fattura input[type='search'] {
    visibility: hidden;
}

.cl_title_details {
    transform: translate(4px, 48px);
    font-weight: bold;
}

@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }



    .cont_dettagli_fattura #myTable_filter {
        position: relative !important;
        left: 0px !important;
        min-width: 100% !important;
        z-index: 2 !important;
        background-color: white !important;
        display: none
    }

    .cont_dettagli_fattura div.dataTables_wrapper div.dataTables_filter input {
        /* margin-top:5px!important */
    }

    .cont_dettagli_fattura #myTable_wrapper {
        min-width: initial;
        min-width: 366px !important;
        max-width: 366px !important;
        width: 366px !important;
        margin-left: 0px !important;
        min-height: 600px !important;
    }

    .cont_dettagli_fattura .table-responsive {
        padding: 0px !important;
        min-width: 366px !important;
        max-width: 366px !important;
    }

    .mainTitleEditFattura {
        padding: 20px !important;
        position: fixed !important;
        min-width: 386px !important;
        z-index: 4 !important;
        top: 65px !important;
        border: 1px solid black !important;
        background: white !important;
        margin-top: -8px !important;
    }


    .cont_dettagli_fattura #myTable {
        margin-right: 3px;
        width: 366px !important;
    }

}

.theme--light.v-tabs>.v-tabs-bar,
.theme--light.v-tabs-items {
    background-color: transparent;
}
</style>
