<template>
    <v-container class="w-container v-overflow filterFattAnt"
        style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);">


        <v-form style="max-width:500px;" v-model="valid" ref="form" lazy-validation>

            <v-row>

                <v-col cols="12" md="12">

                    <div id="cont_companyid" style="cursor:pointer">
                        <ion-item
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                position="floating">Azienda </ion-label>

                            <ion-select id="companyid" name="companyid" class='companyid' cancel-Text="Chiudi" done-Text=""
                                :value="selAzienda" interface="action-sheet" disabled readonly>

                                <ion-select-option v-for="item in aziende" :key="item.companyid" :value='item.companyid'> {{
                                    item.fld_name }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>


                </v-col>

            </v-row>

            <v-row style="margin-top:0px!important" v-if="selAzienda != null">

                <v-col cols="12" md="12" style="margin-top:0px!important;padding-top:0px!important">

                    <v-menu v-model="showPicker4" :close-on-content-click="false" transitions="scale-transition" offset-y
                        max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field id="fldInpData" v-model="dataScadA" label="Data Scadenza Al" persistent-hint
                                readonly v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="dataScadA" no-title @input="showPicker4 = false" locale="it">
                        </v-date-picker>
                    </v-menu>

                </v-col>

            </v-row>



        </v-form>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Aziende
                            <v-btn class='closeSheet' icon @click="sheetAziende = !sheetAziende">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>

                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                </v-text-field>

                            </v-toolbar>

                        </div>


                        <v-divider></v-divider>

                        <div class="spacer" style="min-height:72px;"></div>

                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                    @click="manageClickAziende(item)">

                                    <v-img alt="Clienti Logo" class="  " contain src="@/assets/aziende-invoice.png"
                                        max-width="24" max-height="24" transition="scale-transition" />

                                    <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>
                                </v-list-item>

                                <div style="min-height:150px;height:150px">&nbsp;</div>


                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

    </v-container>
</template>

<script>
//import apiparenti from "../utils/profile/apiparenti";

//import apimedico from "../utils/medico/apimedico";
//import apiconfigurazioneorari from "../utils/configurazioneorari/apiconfigurazioneorari";
//import apimailgruppi from "../utils/mailgruppi/apimailgruppi";
//import apiusers from "../utils/users/apiusers";

import apiusers from "../utils/users/apiusers";
import apiinvoice from "../utils/invoice/apiinvoice";

import {
    bus
} from "../main";

import router from ".././router";
import $ from 'jquery';

export default ({
    mounted() {

        /*eslint-disable no-undef*/

        // console.log("PARAMS: ", this.$route.params.id);

        // console.log("GEN SETTINGS: ", window.$cookies.get("gen_settings").nuclei);

        // this.cur_id = this.$route.params.id;

        this.$root.$children[0].checkLogout();


        this.isAgila = window.$cookies.get("is_agila");


        window.currentMonth = 0;
        window.currentYear = new Date().getFullYear();

        var pointerVue = this;

        pointerVue.$root.$children[0].normalDiv1 = true;
        pointerVue.$root.$children[0].normalDiv2 = true;
        pointerVue.$root.$children[0].normalDiv3 = false;

        pointerVue.$root.$children[0].invoiceDiv1 = false;
        pointerVue.$root.$children[0].invoiceDiv2 = false;
        pointerVue.$root.$children[0].invoiceDiv3 = false;
        pointerVue.$root.$children[0].invoiceDiv4 = false;


        pointerVue.$root.$children[0].curTitle = "Contabilità";
        pointerVue.$root.$children[0].invoiceTitle = "";


        var lastDay = pointerVue.getLastDayMonth();

        console.log("LAST MONTH DAY: ", lastDay);

        pointerVue.dataScadA = lastDay;


        this.setupButtons();

        setTimeout(() => {

            this.syncAziende();

        }, 100);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_confirm":
                    //alert("btn_add_tms");
                    this.validate();

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/medici"
                    });
                    break;

                default:
                    break;
            }
        });

        $(document).ready(function () {




            $('body').on('click', '#cont_companyid', function (event) {
                console.log(event);

                pointerVue.sheetAziende = true;

            });


            $(".selanno").on("ionChange", function (opt) {
                console.log(opt);

                console.log("VALUE ANNO: ", this.value)

                window.anno = this.value;

                pointerVue.hideShowButtons(false);

                pointerVue.showSpinner = true;
                pointerVue.$root.$children[0].showProgress = true;

                window.table.ajax.reload();



            });

            $(".selmese").on("ionChange", function (opt) {
                console.log(opt);

                console.log("VALUE MESE: ", this.value)

                window.mese = this.value;

                pointerVue.hideShowButtons(false);

                pointerVue.showSpinner = true;
                pointerVue.$root.$children[0].showProgress = true;

                window.table.ajax.reload();



            });



            $('body').on('ionChange', '.anno', function (event) {
                console.log(event);

                pointerVue.selAnno = this.value;

                if ((pointerVue.selAnno != null) && (pointerVue.selMese != null) && (pointerVue.selAzienda != null)) {

                    pointerVue.hideShowButtons(true);

                }



            });


            $('body').on('ionChange', '.mese', function (event) {
                console.log(event);

                pointerVue.selMese = this.value;

                if ((pointerVue.selAnno != null) && (pointerVue.selMese != null) && (pointerVue.selAzienda != null)) {

                    pointerVue.hideShowButtons(true);

                }



            });



        });

        pointerVue.hideShowButtons(false);

    },

    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },

    data: () => ({



        dataScadA: null,

        showPicker4: false,


        anni: [],

        mesi: [],

        isAgila: 0,

        titolo: "",
        currDipendenteObj: {},
        aziende: [],
        dipendenti: [],
        currAzienda: "",

        selAzienda: null,

        selAnno: null,

        selMese: null,

        selNuclei: null,

        cur_id: 0,

        tipPrenot: [],

        sheetAziende: false,

        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        medico: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Cognome richiesto",
        ],

        // nucleiRules

        select: null,

        //gruppi: window.$cookies.get("gen_settings").gruppi,

        checkbox: false,
    }),

    methods: {

        getFirstDayMonth: function () {

            var date = new Date();
            var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            var elements = firstDay.toString().split(" ");
            var today = new Date();
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();


            return yyyy + "-" + mm + "-" + elements[2];


        },


        getLastDayMonth: function () {

            var date = new Date();

            var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

            var elements = lastDay.toString().split(" ");
            var today = new Date();
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();


            return yyyy + "-" + mm + "-" + elements[2];


        },

        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.selAzienda = azienda.companyid;

            console.log("AZIENDA: ", this.selAzienda);
            console.log("DATA: ", this.dataScadA);

            if ((this.dataScadA != null) && (this.selAzienda != null)) {

                this.hideShowButtons(true);

            }


        },

        hideShowButtons: async function (v_enable) {

            console.log("ENABKLE: ", v_enable);


            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis = [];

                }

                if (v_enable == true) {

                    pulsantis = [{
                        text: "Conferma",
                        icon: "mdi-content-save",
                        image: "https://app.emtool.eu/public/_lib/img/conferma_docsign.png",
                        link: "/timesheet",
                        id: "btn_confirm",
                        disabled: false,
                        //class: "inverted",
                        title: "Conferma",
                        width: 30
                    },

                    ];

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Conferma",
                    icon: "mdi-content-save",
                    image: "https://app.emtool.eu/public/_lib/img/usr__NM__ico__NM__icon_ok_32.png",
                    link: "/timesheet",
                    id: "btn_confirm",
                    disabled: false,
                    class: "inverted",
                },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            // var that = this;

            // console.log("ANNO: ", that.selAnno);
            // console.log("MESE: ", that.selMese);


            // window.$cookies.set("sel_filter_fant_anno", that.selAnno, "9y");
            // window.$cookies.set("sel_filter_fant_mese", that.selMese, "9y");


            window.currentMonth = 0;
            window.currentYear = new Date().getFullYear();

            window.$cookies.set("is_from_scad", 0, "9y");
            window.$cookies.set("is_from_edit_fatt", 0, "9y");
            window.$cookies.set("is_from_suppliers", 0, "9y");


            setTimeout(() => {

                this.invoiceCustomers();

            }, 100);


        },

        invoiceCustomers: async function () {

            var that = this;

         //   var v_token = window.$cookies.get("token");


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiinvoice.setContab(
                that.selAzienda,
                that.dataScadA
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from setContab", res);


                // var lastDay = that.getLastDayMonth();

                window.$cookies.set("sel_filter_inv_date_scad_from", "", "9y");
                //window.$cookies.set("sel_filter_inv_date_scad_to", lastDay, "9y");
                window.$cookies.set("sel_filter_inv_date_scad_to", that.dataScadA, "9y");

                window.$cookies.set("sel_filter_inv_date_emiss_from", "", "9y");
                window.$cookies.set("sel_filter_inv_date_emiss_to", "", "9y");

                window.$cookies.set("sel_filter_azienda_fatture", that.selAzienda, "9y");

                router.push({
                    path: "/detailsContab"
                });



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );






        },

        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getAziende(
                v_token,
                "invoice"
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getAziende", res);

                that.aziende = res.data.company;

                that.anni = res.data.anni;

                that.mesi = res.data.mesi;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.itemAzienda {
    margin-left: 15px;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.w-container {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.filterFattAnt .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}

.filterFattAnt .v-input {
    font-size: 1.2em !important;
}

.filterFattAnt .v-select {
    font-size: 1.2em !important;
}

.filterFattAnt .v-label {
    font-size: 1em !important;
}

@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}
</style>
