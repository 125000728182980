<template>
    <div class="cont-table">

        <div class="spin">
            <ion-spinner name="crescent"></ion-spinner>
        </div>

        <div class="row cont_tickets cont_fornitori_fatt">
            <div class=" " style="margin:0 auto;">
                <div class="card">

                    <div class="card-body" style="padding:0px">

                        <div class="table-responsive" style="">

                            <table id="myTable" style="margin-top:57px">
                                <thead>
                                    <tr>

                                        <th class='all'>
                                            <div class="cl_checkall">
                                                <ion-checkbox style="width:10px!important;height:10px!important"
                                                    name='emailgestoreall' class='checkall' slot='start'>
                                                </ion-checkbox>
                                            </div>
                                        </th>
                                        <th class='all'>
                                            <div class='cl_num'>N.</div>
                                        </th>

                                        <th class='desktop' style="text-align:center">All.</th>
                                        <th class='desktop'>Tipo</th>
                                        <th class='all'>Numero</th>
                                        <th class='all'>Cliente</th>
                                        <th class='desktop'>Stato</th>
                                        <th class='desktop'>Data</th>
                                        <th class='desktop'>Data Scad.</th>
                                        <th class='desktop'>N. Ordine</th>
                                        <th class='desktop' style="text-align:center">Valuta</th>
                                        <th class='desktop' style="text-align:right;padding-right:5px">Subtotale</th>
                                        <th class='desktop' style="text-align:right;padding-right:5px">Iva</th>
                                        <th class='all cl-tot-header'>Totale</th>
                                        <th class='desktop' style="text-align:right;padding-right:5px">Pagato Parz.</th>
                                        <th class='desktop' style="text-align:right;padding-right:5px">Pagato</th>
                                        <th class='desktop' style="text-align:right;padding-right:5px">Rimanente</th>
                                        <th class='desktop'>Note</th>
                                        <th class='desktop' style="text-align:center">Completata</th>

                                    </tr>
                                </thead>

                                <tfoot>

                                    <tr>

                                        <th class='all col_footer'>&nbsp;</th>

                                        <th class='all col_footer'>
                                            <div class="div_n">&nbsp;</div>
                                        </th>

                                        <th class='desktop col_footer'>&nbsp;</th>

                                        <th class='desktop col_footer'>&nbsp;</th>

                                        <th class='all col_footer'>Totale (€)</th>

                                        <th class='all col_footer'>&nbsp;</th>

                                        <th class='desktop col_footer'>&nbsp;</th>

                                        <th class='desktop col_footer'>&nbsp;</th>

                                        <th class='desktop col_footer'>&nbsp;</th>

                                        <th class='desktop col_footer'>&nbsp;</th>

                                        <th class='desktop col_footer'>&nbsp;</th>

                                        <th class='desktop col_footer_right' id="v_tot_subtotale">0,00</th>

                                        <th class='desktop col_footer_right' id="v_tot_iva">0,00</th>

                                        <th class='all col_footer_right' id="v_tot_totale">0,00</th>

                                        <th class='desktop col_footer'>&nbsp;</th>

                                        <th class='desktop col_footer'>&nbsp;</th>

                                        <th class='desktop col_footer_right' id="v_tot_rimanente">0,00</th>

                                        <th class='desktop col_footer'>&nbsp;</th>

                                        <th class='desktop col_footer'>&nbsp;</th>

                                    </tr>
                                </tfoot>

                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="contfornitori">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetFornitori" class="vfornitorisheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetFornitori" fixed style="position:fixed;">
                                Clienti
                                <v-btn class='closeSheet align_close_sheet' icon
                                    @click="sheetFornitori = !sheetFornitori">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchFornitori">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredFornitori"
                                        :key="item.companyid" @click="manageClickFornitori(item)">

                                        <v-img alt="Fornitori Logo" class="  " contain src="@/assets/custom_filter.png"
                                            max-width="24" max-height="24" transition="scale-transition" />

                                        <v-list-item-title class='itemFornitore'>{{ item.fld_name }}</v-list-item-title>

                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>


        <div class="contpagamenti">

            <template>
                <div class="text-center">

                    <v-bottom-sheet v-model="sheetPagamenti">
                        <v-sheet class="text-center" height="430px" style="padding-bottom:100px;">

                            <div style="text-align:right!important">

                                <v-btn class='closePagamentiSheet' icon @click="sheetPagamenti = !sheetPagamenti">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                            </div>

                            <div class="py-3 c_title_pagamenti">
                                Pagamenti
                            </div>

                            <v-list-item v-for="tile in allPayments" :key="tile.title"
                                @click="filterPagamento(tile.title)">
                                <v-list-item-avatar>

                                    <v-avatar size="25px" tile>

                                        <v-img alt="" contain :src="tile.img" max-width="25" max-height="25" />

                                    </v-avatar>

                                </v-list-item-avatar>
                                <v-list-item-title style="text-align:left!important;font-size:9px!important">{{
                        tile.title
                    }}</v-list-item-title>
                            </v-list-item>

                        </v-sheet>
                    </v-bottom-sheet>

                </div>
            </template>

        </div>



        <div class="contpopupdatefrom" style="display:none">

            <template>
                <modal name="popupDateFrom" :clickToClose="false" :width="300" :height="600">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Periodo Emissione

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <v-menu v-model="showPicker3" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpData" v-model="dataEmissDa" label="Data Da" persistent-hint
                                        readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataEmissDa" no-title @input="showPicker3 = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Periodo Scadenza

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <v-menu v-model="showPicker" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpData" v-model="dataScadDa" label="Data Da" persistent-hint
                                        readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataScadDa" no-title @input="showPicker = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <label style="font-weight:bold;margin-left:11px">Pagamenti</label>

                            <v-radio-group v-model="selPag">
                                <v-radio v-for="item in allPayments" :key="item.id" :label="item.title" :value="item.id"
                                    :id="item.id_elem"></v-radio>
                            </v-radio-group>


                        </v-col>

                    </v-row>






                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-date-from" contain
                                    src="@/assets/btn_cancel3_invoiceweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Annulla" />

                                <v-img alt="" class="btn-reset-date-from" contain src="@/assets/reset.png"
                                    max-width="25" max-height="25"
                                    style="cursor:pointer;margin-right:10px;margin-top:3px"
                                    transition="scale-transition" title="Pulisci" />

                                <v-img alt="" class="btn-confirm-date-from" contain
                                    src="@/assets/btn_confirm3_invoiceweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Conferma" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



        <div class="contpopupdateto" style="display:none">

            <template>
                <modal name="popupDateTo" :clickToClose="false" :width="300" :height="600">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Periodo Emissione

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <v-menu v-model="showPicker3" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpData" v-model="dataEmissA" label="Data A" persistent-hint
                                        readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataEmissA" no-title @input="showPicker3 = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Periodo Scadenza

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <v-menu v-model="showPicker" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpData" v-model="dataScadA" label="Data A" persistent-hint
                                        readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataScadA" no-title @input="showPicker = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <label style="font-weight:bold;margin-left:11px">Pagamenti</label>

                            <v-radio-group v-model="selPag">
                                <v-radio v-for="item in allPayments" :key="item.id" :label="item.title" :value="item.id"
                                    :id="item.id_elem"></v-radio>
                            </v-radio-group>


                        </v-col>

                    </v-row>






                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-date-to" contain
                                    src="@/assets/btn_cancel3_invoiceweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Annulla" />


                                <v-img alt="" class="btn-reset-date-to" contain src="@/assets/reset.png" max-width="25"
                                    max-height="25" style="cursor:pointer;margin-right:10px;margin-top:3px"
                                    transition="scale-transition" title="Pulisci" />

                                <v-img alt="" class="btn-confirm-date-to" contain
                                    src="@/assets/btn_confirm3_invoiceweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Conferma" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupsearch" style="display:none">

            <template>
                <modal name="popupSearch" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            <v-img alt="" src="@/assets/2recr_ricerca3.png" max-width="25" max-height="25" />

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="textSearch" label="">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain
                                    src="@/assets/btn_cancel3_invoiceweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Annulla" @click="btnCancelSearch" />

                                <v-img alt="" class="btn-delete-search" contain src="@/assets/delete-invoiceweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Elimina filtro" @click="btnResetSearch" />

                                <v-img alt="" class="btn-confirm-search" contain
                                    src="@/assets/btn_confirm3_invoiceweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Conferma"
                                    @click="btnConfirmSearch" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopuperror" style="display:none">

            <template>
                <modal name="popupError" :clickToClose="false" :width="350" :height="480">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:140px">

                            <v-img alt="" src="@/assets/error.png" max-width="60" max-height="60" />

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div id="txtError"
                                style="padding-left:10px;padding-right:10px;font-weight:bold;font-size:12px">
                            </div>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">


                                <v-img alt="" class="btn-confirm-error" contain
                                    src="@/assets/btn_confirm3_invoiceweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Conferma"
                                    @click="btnConfirmError" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contpopupcalculator" style="display:none">

            <template>
                <modal name="popupCalculator" :clickToClose="false" :width="350" :height="303">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-left:10px;margin-top:10px!important;font-weight:bold">

                            Totale Fatture

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="6" md="6">
                            <div style="margin-left:10px">
                                Fatture Selezionate
                            </div>
                        </v-col>

                        <v-col cols="6" md="6">

                            <div id="divFattSel"
                                style="width:100%;min-width:150px;max-width:150px;height:70px;text-align:right;overflow-y:auto;font-weight:bold">
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="6" md="6">
                            <div style="margin-left:10px">
                                Subtotale (€)
                            </div>
                        </v-col>

                        <v-col cols="6" md="6">

                            <div id="divSubSel"
                                style="font-weight:bold;width:100%;min-width:150px;max-width:150px;text-align:right">
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="6" md="6">
                            <div style="margin-left:10px">
                                Totale Iva (€)
                            </div>
                        </v-col>

                        <v-col cols="6" md="6">

                            <div id="divIvaSel"
                                style="font-weight:bold;width:100%;min-width:150px;max-width:150px;text-align:right">
                            </div>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="6" md="6">
                            <div style="margin-left:10px">
                                Totale (€)
                            </div>
                        </v-col>

                        <v-col cols="6" md="6">

                            <div id="divTotSel"
                                style="font-weight:bold;width:100%;min-width:150px;max-width:150px;text-align:right">
                            </div>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">


                                <v-img alt="" class="btn-cancel-calculator" contain
                                    src="@/assets/btn_cancel3_invoiceweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Chiudi"
                                    @click="btnCancelCalculator" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



        <div class="contpopupdate" style="display:none">

            <template>
                <modal name="popupDate" :clickToClose="false" :width="300" :height="561">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Periodo Emissione

                        </v-col>

                    </v-row>

                    <v-row style="margin-top:0px!important">

                        <v-col cols="12" md="12" style="margin-top:0px!important;padding-top:0px!important">

                            <v-menu v-model="showPicker" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpData" v-model="dataEmissDa" label="Data Da" persistent-hint
                                        readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataEmissDa" no-title @input="showPicker = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>


                    <v-row style="margin-top:0px!important">

                        <v-col cols="12" md="12" style="margin-top:0px!important;padding-top:0px!important">

                            <v-menu v-model="showPicker2" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpData" v-model="dataEmissA" label="Data A" persistent-hint
                                        readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataEmissA" no-title @input="showPicker2 = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>


                    <v-row style="margin-top:0px!important">

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Periodo Scadenza

                        </v-col>

                    </v-row>

                    <v-row style="margin-top:0px!important">

                        <v-col cols="12" md="12" style="margin-top:0px!important;padding-top:0px!important">

                            <v-menu v-model="showPicker3" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpData" v-model="dataScadDa" label="Data Da" persistent-hint
                                        readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataScadDa" no-title @input="showPicker3 = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>


                    <v-row style="margin-top:0px!important">

                        <v-col cols="12" md="12" style="margin-top:0px!important;padding-top:0px!important">

                            <v-menu v-model="showPicker4" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpData" v-model="dataScadA" label="Data A" persistent-hint
                                        readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataScadA" no-title @input="showPicker4 = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>


                    <v-row>

                        <div style="font-weight:bold;margin-left:24px">
                            Pagamenti
                        </div>


                    </v-row>

                    <v-row>

                        <div style="display:flex;margin-left:53px;width:85%;margin-top:22px">

                            <div style="width:13%">Tutti</div>
                            <div style="width:13%;text-align:center">Si</div>
                            <div style="width:13%;text-align:center">No</div>
                            <div style="width:24%;text-align:center">No / Parziali</div>
                            <div style="width:20%;text-align:center">Parziali</div>
                            <div style="width:17%;text-align:center"></div>

                        </div>

                    </v-row>

                    <v-row>

                        <div style="display:flex;margin-left:24px;width:85%;margin-top:2px">

                            <v-radio-group v-model="selPag" row>

                                <div style="width:13%;transform: translate(20px, -17px);">
                                    <v-radio class="cl-radio" id="ele_tutti" value="1"></v-radio>
                                </div>
                                <div style="width:13%;transform: translate(31px, -17px);">
                                    <v-radio class="cl-radio" id="ele_si" value="2"></v-radio>
                                </div>
                                <div style="width:13%;transform: translate(34px, -17px);">
                                    <v-radio class="cl-radio" id="ele_no" value="3"></v-radio>
                                </div>
                                <div style="width:24%;transform: translate(54px, -17px);">
                                    <v-radio class="cl-radio" id="ele_no_parziali" value="5"></v-radio>
                                </div>
                                <div style="width:20%;transform: translate(55px, -17px);">
                                    <v-radio class="cl-radio" id="ele_parziali" value="4"></v-radio>
                                </div>

                                <div style="width:17%">
                                </div>

                            </v-radio-group>



                        </div>

                    </v-row>



                    <v-row style="margin-top:0px!important">

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-date" contain src="@/assets/btn_cancel3_invoiceweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelFilterDate" />


                                <v-img alt="" class="btn-reset-date" contain src="@/assets/reset.png" max-width="25"
                                    max-height="25" style="cursor:pointer;margin-right:10px;margin-top:3px"
                                    transition="scale-transition" title="Pulisci" @click="btnResetFilterDate" />

                                <v-img alt="" class="btn-confirm-date" contain
                                    src="@/assets/btn_confirm3_invoiceweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Conferma"
                                    @click="btnConfirmFilterDate" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



        <div class="contaziende">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Aziende
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetAziende = !sheetAziende">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchAziende">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                        @click="manageClickAziende(item)">

                                        <v-img alt="Clienti Logo" class="  " contain src="@/assets/aziende-invoice.png"
                                            max-width="24" max-height="24" transition="scale-transition" />

                                        <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>

                                    </v-list-item>

                                    <div style="min-height:150px;height:150px">&nbsp;</div>

                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>






    </div>
</template>

<script>
import apiinvoice from "../utils/invoice/apiinvoice";
import apiusers from "../utils/users/apiusers";

import {
    bus
} from "../main";
import router from ".././router";
/* import $ from 'jquery'; */



export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {
        /*     let self = this;
        fetch('https://jsonplaceholder.typicode.com/posts')
          .then(response => response.json())
          .then(json => self.$data.items = json) */

        //var res = apiprofile.getProfile(1,2);

        /*eslint-disable no-undef*/

        this.isAgila = window.$cookies.get("is_agila");



        var pointerVue = this;

        pointerVue.$root.$children[0].normalDiv1 = false;
        pointerVue.$root.$children[0].normalDiv2 = false;

        pointerVue.$root.$children[0].invoiceDiv1 = true;
        pointerVue.$root.$children[0].invoiceDiv2 = true;
        pointerVue.$root.$children[0].invoiceDiv3 = true;
        pointerVue.$root.$children[0].invoiceDiv4 = true;
        pointerVue.$root.$children[0].calcolatorDiv1 = false;

        window.company_id = window.$cookies.get("sel_filter_azienda_fatture");

        window.numTotBanche = 0;
        window.numTotTipiPag = 0;
        window.numTotValute = 0;
        window.numTotIva = 0;
        window.numTotUDM = 0;
        window.numTotClienti = 0;
        window.numTotFornitori = 0;

        pointerVue.$root.$children[0].invoiceTitle = "Fatture";

        pointerVue.$root.$children[0].viewLeftArrowSelMonthExpired = false;
        pointerVue.$root.$children[0].viewSelMonthExpired = false;
        pointerVue.$root.$children[0].viewRightArrowSelMonthExpired = false;


        if (this.isAgila == 1) {

            pointerVue.$root.$children[0].viewLeftArrowSelMonthExpired = false;
            pointerVue.$root.$children[0].viewSelMonthExpired = false;
            pointerVue.$root.$children[0].viewRightArrowSelMonthExpired = false;

            pointerVue.$root.$children[0].viewCalCancelPeriod = false;

            // valori default
            window.dateEmissDa = window.$cookies.get("sel_filter_inv_date_emiss_from");
            window.dateEmissA = window.$cookies.get("sel_filter_inv_date_emiss_to");
            window.dateScadDa = window.$cookies.get("sel_filter_inv_date_scad_from");
            window.dateScadA = window.$cookies.get("sel_filter_inv_date_scad_to");

            window.curPayment = "";

            window.curSupplier = "-1"; // default tutti i fornitori


        }
        else {


            var is_from_scad = window.$cookies.get("is_from_scad");
            var is_from_edit_fatt = window.$cookies.get("is_from_edit_fatt");
            var is_from_customers = window.$cookies.get("is_from_customers");

            if ((is_from_scad == 0) && (is_from_edit_fatt == 0)) {


                if (is_from_customers == 0) {

                    pointerVue.$root.$children[0].viewLeftArrowSelMonthExpired = false;
                    pointerVue.$root.$children[0].viewSelMonthExpired = false;
                    pointerVue.$root.$children[0].viewRightArrowSelMonthExpired = false;


                    window.dateEmissDa = "";
                    window.dateEmissA = "";
                    window.dateScadDa = "";
                    window.dateScadA = "";

                    window.curPayment = "";

                    window.curSupplier = "-1"; // default tutti i fornitori


                }



            }
            else {

                pointerVue.$root.$children[0].viewLeftArrowSelMonthExpired = true;
                pointerVue.$root.$children[0].viewSelMonthExpired = true;
                pointerVue.$root.$children[0].viewRightArrowSelMonthExpired = true;

                var array_months2 = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];


                var eleDate2 = "";

                if (window.dateScadA != "") {

                    eleDate2 = window.dateScadA.split("-");

                }
                else {

                    eleDate2 = window.$cookies.get("sel_filter_inv_date_scad_to").split("-");

                }


                var descMese2 = array_months2[parseInt(eleDate2[1] - 1)];

                window.currentMonth = eleDate2[1];

                console.log("MESE: ", descMese2);

                pointerVue.$root.$children[0].meseSelezionato = descMese2;

                window.$cookies.set("is_from_scad", 0, "9y");


            }



        }





        pointerVue.$root.$children[0].valDateFrom = window.$cookies.get("sel_filter_inv_date_scad_from");
        pointerVue.$root.$children[0].valDateTo = window.$cookies.get("sel_filter_inv_date_scad_to");

        window.totale_selezionato = 0;

        window.curSrcText = "";

        //window.curPayment = "NP"; // default fatture non pagate

        // window.curPayment = "";

        // window.curSupplier = "-1"; // default tutti i fornitori

        if (window.currentMonth == 0) {

            var array_months = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];

            //var eleDate = window.dateScadA.split("-");

            var eleDate = "";

            if (window.dateScadA != "") {

                eleDate = window.dateScadA.split("-");

            }
            else {

                eleDate = window.$cookies.get("sel_filter_inv_date_scad_to").split("-");

            }


            var descMese = array_months[parseInt(eleDate[1] - 1)];

            window.currentMonth = eleDate[1];

            console.log("MESE: ", descMese);

            pointerVue.$root.$children[0].meseSelezionato = descMese;


        }

        window.perpage = 30;
        bus.$off("bottomIconClickEvent");

        // var b = window.$cookies.get("b");
        // var $rotta = "";


        $(document).ready(
            function () {

                $("body").off();

                // window.uname = window.$cookies.get("username");
                // var uname = window.uname;

                // const args = [uname, true, ['bar', 5], {
                //     foo: 'baz'
                // }];
                // try {
                //     console.log("entering user");
                //     window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                //     console.log("passed");

                // } catch (error) {
                //     // alert(error);
                // }

            }
        );

        $(document).ready(function () {

            $('body').on('click', '.selscadenziario', function () {

                console.log(("EVENTO CLICK SELSCADENZIARIO"));

                var tot = 0;
                var ids = "";

                $(".val_status").each(function (index) {

                    console.log(index);

                    if ($(this).prop("checked")) {

                        if (ids != "") {
                            ids = ids + ",";
                        }

                        ids = ids + $(this).attr("data-id");

                        tot = tot + 1;

                    }

                });

                var errore = "";

                if (tot == 0) {
                    errore = "Selezionare almeno una elemento";
                }

                if (errore == "") {

                    if (tot > 1) {
                        errore = "Selezionare solo un elemento";
                    }

                }



                if (errore == "") {

                    window.$cookies.set("type_fatt_from", "Fornitori", "9y");

                    router.push({
                        path: "/scadenziario/" + ids
                    });

                }
                else {

                    pointerVue.$swal({
                        icon: "error",
                        text: errore,
                        showConfirmButton: false,
                        timer: 2000
                    });


                }


            });


            $('body').on('click', '.mesesucc', function (event) {
                console.log(event);

                window.currentMonth = parseInt(window.currentMonth) + 1;

                console.log("CUR MONTH: ", window.currentMonth);

                if (window.currentMonth == 13) {
                    window.currentMonth = 1;

                    window.currentYear = parseInt(window.currentYear) + 1;

                }

                var date_sel = pointerVue.getLastDayMonthSel(window.currentMonth, window.currentYear);

                console.log("DATE SEL: ", date_sel);

                var array_months = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];

                var descMese = array_months[parseInt(parseInt(window.currentMonth) - 1)];


                console.log("MESE: ", descMese);

                pointerVue.$root.$children[0].meseSelezionato = descMese;

                window.dateScadA = date_sel;
                window.dateEmissA = "";
                window.dateScadDa = "";
                window.dateEmissDa = "";

                window.$cookies.set("sel_filter_inv_date_scad_to", date_sel, "9y");
                window.$cookies.set("sel_filter_inv_date_emiss_to", "", "9y");

                window.$cookies.set("sel_filter_inv_date_scad_from", "", "9y");
                window.$cookies.set("sel_filter_inv_date_emiss_from", "", "9y");

                pointerVue.showSpinner = true;
                pointerVue.$root.$children[0].showProgress = true;

                window.table.ajax.reload();



            });


            $('body').on('click', '.meseprec', function (event) {
                console.log(event);

                window.currentMonth = parseInt(window.currentMonth) - 1;

                console.log("CUR MONTH: ", window.currentMonth);

                if (window.currentMonth == 0) {
                    window.currentMonth = 12;

                    window.currentYear = parseInt(window.currentYear) - 1;

                }

                var date_sel = pointerVue.getLastDayMonthSel(window.currentMonth, window.currentYear);

                console.log("DATE SEL: ", date_sel);

                var array_months = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];

                var descMese = array_months[parseInt(parseInt(window.currentMonth) - 1)];


                console.log("MESE: ", descMese);

                pointerVue.$root.$children[0].meseSelezionato = descMese;

                window.dateScadA = date_sel;
                window.dateEmissA = "";
                window.dateScadDa = "";
                window.dateEmissDa = "";

                window.$cookies.set("sel_filter_inv_date_scad_to", date_sel, "9y");
                window.$cookies.set("sel_filter_inv_date_emiss_to", "", "9y");

                window.$cookies.set("sel_filter_inv_date_scad_from", "", "9y");
                window.$cookies.set("sel_filter_inv_date_emiss_from", "", "9y");

                pointerVue.showSpinner = true;
                pointerVue.$root.$children[0].showProgress = true;

                window.table.ajax.reload();



            });




            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });

            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));

                console.log("CHECKALL: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row");

                    $("tr").eq(0).removeClass("active-row");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row");


                }

                pointerVue.hideShowButtonsSelRow();

                //  pointerVue.updateCalculatorGlobal();


            });


            $('body').on('click', '.selpagamento', function () {

                pointerVue.sheetPagamenti = true;

            });





            $('body').on('click', '.btn-cancel-date-from', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupDateFrom');

            });

            $('body').on('click', '.btn-reset-date-from', function (event) {
                console.log(event);

                pointerVue.dataScadDa = null;
                pointerVue.dataEmissDa = null;


            });


            $('body').on('click', '.btn-confirm-date-from', function (event) {
                console.log(event);

                window.dateScadDa = pointerVue.dataScadDa;
                window.dateEmissDa = pointerVue.dataEmissDa;

                pointerVue.$modal.hide('popupDateFrom');

                pointerVue.showSpinner = true;
                pointerVue.$root.$children[0].showProgress = true;


                var val_data_scad_from = "";
                if (pointerVue.dataScadDa != null) {
                    val_data_scad_from = pointerVue.dataScadDa;
                }

                var val_data_emiss_from = "";
                if (pointerVue.dataEmissDa != null) {
                    val_data_emiss_from = pointerVue.dataEmissDa;
                }

                pointerVue.$root.$children[0].valDateFrom = val_data_scad_from;

                window.$cookies.set("sel_filter_inv_date_scad_from", val_data_scad_from, "9y");

                window.$cookies.set("sel_filter_inv_date_emiss_from", val_data_emiss_from, "9y");


                console.log("SEL PAG: ", pointerVue.selPag);

                var v_filter = "";

                if (pointerVue.selPag == 2) {
                    v_filter = "S";
                }
                else if (pointerVue.selPag == 3) {
                    v_filter = "N";
                }
                else if (pointerVue.selPag == 4) {
                    v_filter = "P";
                }


                window.curPayment = v_filter;


                $(".checkall").prop("checked", false);

                pointerVue.$root.$children[0].calcolatorDiv1 = false;

                window.totale_selezionato = 0;




                window.table.ajax.reload();


            });



            $('body').on('click', '.canceldate', function (event) {
                console.log(event);

                window.dateEmissDa = "";
                window.dateEmissA = "";
                window.dateScadDa = "";
                window.dateScadA = "";

                pointerVue.$root.$children[0].viewLeftArrowSelMonthExpired = false;
                pointerVue.$root.$children[0].viewSelMonthExpired = false;
                pointerVue.$root.$children[0].viewRightArrowSelMonthExpired = false;

                pointerVue.showSpinner = true;
                pointerVue.$root.$children[0].showProgress = true;


                window.table.ajax.reload();

            });




            $('body').on('click', '.seldate', function (event) {
                console.log(event);


                pointerVue.dataEmissDa = window.$cookies.get("sel_filter_inv_date_emiss_from");
                pointerVue.dataEmissA = window.$cookies.get("sel_filter_inv_date_emiss_to");

                pointerVue.dataScadDa = window.$cookies.get("sel_filter_inv_date_scad_from");
                pointerVue.dataScadA = window.$cookies.get("sel_filter_inv_date_scad_to");



                $(".contpopupdate").show();


                pointerVue.selPag = "5";

                pointerVue.$modal.show('popupDate');



            });


            $('body').on('click', '.btn-cancel-date-to', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupDateTo');

            });

            $('body').on('click', '.btn-reset-date-to', function (event) {
                console.log(event);

                pointerVue.dataScadA = null;
                pointerVue.dataEmissA = null;


            });


            $('body').on('click', '.btn-confirm-date-to', function (event) {
                console.log(event);

                window.dateScadA = pointerVue.dataScadA;
                window.dateEmissA = pointerVue.dataEmissA;

                pointerVue.$modal.hide('popupDateTo');

                pointerVue.showSpinner = true;
                pointerVue.$root.$children[0].showProgress = true;


                var val_data_scad_to = "";
                if (pointerVue.dataScadA != null) {
                    val_data_scad_to = pointerVue.dataScadA;
                }

                var val_data_emiss_to = "";
                if (pointerVue.dataEmissA != null) {
                    val_data_emiss_to = pointerVue.dataEmissA;
                }

                pointerVue.$root.$children[0].valDateTo = val_data_scad_to;

                window.$cookies.set("sel_filter_inv_date_scad_to", val_data_scad_to, "9y");

                window.$cookies.set("sel_filter_inv_date_emiss_to", val_data_emiss_to, "9y");


                console.log("SEL PAG: ", pointerVue.selPag);

                var v_filter = "";

                if (pointerVue.selPag == 2) {
                    v_filter = "S";
                }
                else if (pointerVue.selPag == 3) {
                    v_filter = "N";
                }
                else if (pointerVue.selPag == 4) {
                    v_filter = "P";
                }


                window.curPayment = v_filter;


                $(".checkall").prop("checked", false);

                pointerVue.$root.$children[0].calcolatorDiv1 = false;

                window.totale_selezionato = 0;




                window.table.ajax.reload();


            });




        });

        setTimeout(() => {

            this.syncAziendaFiltro();

            this.syncFornitori();

            this.syncAziende();


        }, 100);


        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_delete":

                    this.deleteRow();

                    break;

                case "btn_scadenziario":

                    ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + $(this).attr("data-id");
                        }

                    });

                    window.$cookies.set("type_fatt_from", "Fornitori", "9y");

                    router.push({
                        path: "/scadenziario/" + ids
                    });


                    break;

                case "btn_add":


                    // console.log("N_TOT_BANCHE:", window.numTotBanche);
                    // console.log("N_TOT_TIPI_PAG:", window.numTotTipiPag);
                    // console.log("N_TOT_VALUTE:", window.numTotValute);
                    // console.log("N_TOT_IVA:", window.numTotIva);
                    // console.log("N_TOT_UDM:", window.numTotUDM);
                    // console.log("N_TOT_CLIENTI:", window.numTotClienti);
                    // console.log("N_TOT_FORNITORI:", window.numTotFornitori);

                    var msg_error = "";

                    // if (window.numTotBanche == 0) {
                    //     msg_error = msg_error + "Inserire almeno una banca nella sezione Impostazioni > Banche";
                    // }

                    // if (window.numTotTipiPag == 0) {

                    //     if (msg_error.length > 0) {
                    //         msg_error = msg_error + "<br><br>";
                    //     }

                    //     msg_error = msg_error + "Inserire almeno un tipo pagamento nella sezione Impostazioni > Condizioni Pagamento";
                    // }


                    // if (window.numTotValute == 0) {

                    //     if (msg_error.length > 0) {
                    //         msg_error = msg_error + "<br><br>";
                    //     }

                    //     msg_error = msg_error + "Inserire almeno una valuta nella sezione Impostazioni > Valute";
                    // }

                    // if (window.numTotIva == 0) {

                    //     if (msg_error.length > 0) {
                    //         msg_error = msg_error + "<br><br>";
                    //     }

                    //     msg_error = msg_error + "Inserire almeno un valore di iva nella sezione Impostazioni > Iva";
                    // }

                    // if (window.numTotUDM == 0) {

                    //     if (msg_error.length > 0) {
                    //         msg_error = msg_error + "<br><br>";
                    //     }

                    //     msg_error = msg_error + "Inserire almeno un'unità di misura nella sezione Impostazioni > Unità di Misura";
                    // }


                    // if (window.numTotFornitori == 0) {

                    //     if (msg_error.length > 0) {
                    //         msg_error = msg_error + "<br><br>";
                    //     }

                    //     msg_error = msg_error + "Inserire almeno un fornitore nella sezione Impostazioni > Clienti/Fornitori";
                    // }




                    if (msg_error.length == 0) {

                        console.log("ok1");

                        router.push({
                            path: "/editFornitoreFattura/0"
                        });

                    }

                    // if (msg_error.length > 0) {



                    //     $(".contpopuperror").show();

                    //     this.$modal.show('popupError');

                    //     setTimeout(() => {

                    //         $("#txtError").html(msg_error);

                    //     }, 150);

                    // }



                    break;

                case "btn_edit":



                    var ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + $(this).attr("data-id");
                        }

                    });

                    console.log("IDS SEL:", ids);

                    router.push({
                        path: "/editFornitoreFattura/" + ids
                    });

                    break;

                case "btn_back":
                    router.push({
                        path: "/filterFatture"
                    });
                    break;


                case "btn_calculator":

                    var list_fatt_sel = "";
                    var tot_sub = 0;
                    var tot_iva = 0;
                    var tot = 0;

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {


                            var v_row = $(this).parent().parent();

                            var item = window.table.row(v_row).data();

                            console.log("ROM SELECTED: ", item);

                            if (list_fatt_sel.length > 0) {
                                list_fatt_sel = list_fatt_sel + "<br>";
                            }
                            list_fatt_sel = list_fatt_sel + item.full_num_order;



                            var val = item.totale_calc;
                            var v_imp = val.toString().replace(".", "");
                            v_imp = v_imp.toString().replace(",", ".");
                            tot_sub = parseFloat(tot_sub) + parseFloat(v_imp);

                            val = item.totale_iva_calc;
                            v_imp = val.toString().replace(".", "");
                            v_imp = v_imp.toString().replace(",", ".");
                            tot_iva = parseFloat(tot_iva) + parseFloat(v_imp);


                            val = item.totale_pagare_calc;
                            v_imp = val.toString().replace(".", "");
                            v_imp = v_imp.toString().replace(",", ".");
                            tot = parseFloat(tot) + parseFloat(v_imp);


                        }

                    });


                    // divTotSel  

                    $(".contpopupcalculator").show();

                    this.$modal.show('popupCalculator');

                    setTimeout(() => {

                        $("#divFattSel").html(list_fatt_sel);

                        $("#divSubSel").html(this.formatMoney(tot_sub));

                        $("#divIvaSel").html(this.formatMoney(tot_iva));

                        $("#divTotSel").html(this.formatMoney(tot));


                    }, 200);





                    break;





                default:
                    break;

            }
        });

        // this.setupButtons();

        this.setupButtonsGrid();

        //wait loading
        /* var v_token = window.$cookies.get("token");
        var v_company = window.$cookies.get("sel_filter_azienda_utenti"); */
        window.start = 0;
        //console.log("DOMAIN: " + pointerVue.getUrlDomain("getListingRSA"));

        // console.log("HOSTNAME: " + $(location).attr('hostname'));
        // console.log("PROTOCOL: " + $(location).attr('protocol'));
        //var pointerVue = this;

        /*         $.ajax({
                    url: pointerVue.getUrlDomain("getUtenti") + '?username=' + v_token + "&company=" + v_company,
                    data: {

                        currDay: window.currDay,
                        currDayTo: window.currDayTo,
                        curStatus: window.curStatus,
                        curPiano: window.curPiano,
                        start: window.start,
                        length: 30,

                    },
                    success: function (dati) {

                        window.dataset = dati.data;
                        window.loading = 0;
                        window.start = +30;
                        pointerVue.setupGrid();

                    }
                }); */

        /*  window.loading = 0;
         window.start = +30; */
        pointerVue.setupGrid();

        this.hideShowButtons(false);

    },
    data: () => ({

        sheetAziende: false,
        searchAziende: "",


        aziende: [],




        isAgila: 0,

        textSearch: "",

        selPag: null,

        dataScadA: null,
        dataScadDa: null,
        showPicker2: null,

        dataEmissA: null,
        dataEmissDa: null,
        showPicker3: null,
        showPicker4: null,


        allPayments: [
            {
                img: 'https://app.emtool.eu/public/_lib/img/filter_all_easyer.png',
                title: 'Tutti',
                "id": 1,
                icon: "mdi-account-lock-open",
                "color": "red"
            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/approvato_easyer.png',
                title: 'Si',
                "id": 2,
                icon: "mdi-account-lock-open",
                "color": "red"
            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/negato.png',
                title: 'No',
                "id": 3,
                icon: "mdi-account-lock-open",
                "color": "red"
            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/negato-parziali.png',
                title: 'No / Parziali',
                "id": 5,
                icon: "mdi-account-lock-open",
                "color": "red",
                "id_elem": "ele_no_parziali"


            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/in_approv_easyer.png',
                title: 'Parziali',
                "id": 4,
                icon: "mdi-account-lock-open",
                "color": "red"
            },


        ],

        sheetPagamenti: false,

        sheetFornitori: false,
        searchFornitori: "",

        fornitori: [],

        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    computed: {

        filteredFornitori() {
            return _.orderBy(this.fornitori.filter(item => {
                if (!this.searchFornitori) return this.fornitori;
                return (item.fld_name.toLowerCase().includes(this.searchFornitori.toLowerCase()));
            }), 'headline')
        },

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.searchAziende) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.searchAziende.toLowerCase()));
            }), 'headline')
        },



    },


    methods: {

        btnResetSearch: function () {

            window.curSrcText = "";



            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();
        },

        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getAziende(
                v_token,
                "invoice"
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getAziende", res);

                this.aziende = res.data.company;

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            window.$cookies.set("sel_filter_azienda_fatture", azienda.companyid, "9y");

            window.company_id = azienda.companyid;

            this.syncAziendaFiltro();



            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            $(".checkall").prop("checked", false);

            this.$root.$children[0].calcolatorDiv1 = false;

            window.totale_selezionato = 0;

            window.table.ajax.reload();


        },


        getLastDayMonthSelOld: function (v_month) {

            // console.log(v_month);

            var date = new Date();

            var lastDay = new Date(date.getFullYear(), v_month, 0);

            var elements = lastDay.toString().split(" ");
            var today = new Date();
            var mm = String(v_month).padStart(2, '0');
            var yyyy = today.getFullYear();


            return yyyy + "-" + mm + "-" + elements[2];


        },


        getLastDayMonthSel: function (v_month, v_year) {

            console.log("MESE SEL: ", v_month);
            console.log("ANNO SEL: ", v_year);

            var lastDay = new Date(v_year, v_month, 0);

            var elements = lastDay.toString().split(" ");
            var mm = String(v_month).padStart(2, '0');
            var yyyy = v_year;


            return yyyy + "-" + mm + "-" + elements[2];


        },



        btnCancelFilterDate: function () {

            this.$modal.hide('popupDate');

        },

        btnResetFilterDate: function () {

            this.dataEmissDa = null;
            this.dataEmissA = null;
            this.dataScadDa = null;
            this.dataScadA = null;

        },

        btnConfirmFilterDate: function () {



            console.log(event);

            window.dateScadA = this.dataScadA;
            window.dateEmissA = this.dataEmissA;
            window.dateScadDa = this.dataScadDa;
            window.dateEmissDa = this.dataEmissDa;

            this.$modal.hide('popupDate');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;


            var val_data_scad_to = "";
            if (this.dataScadA != null) {
                val_data_scad_to = this.dataScadA;
            }

            var val_data_emiss_to = "";
            if (this.dataEmissA != null) {
                val_data_emiss_to = this.dataEmissA;
            }

            this.$root.$children[0].valDateTo = val_data_scad_to;

            window.$cookies.set("sel_filter_inv_date_scad_to", val_data_scad_to, "9y");

            window.$cookies.set("sel_filter_inv_date_emiss_to", val_data_emiss_to, "9y");

            var val_data_scad_from = "";
            if (this.dataScadDa != null) {
                val_data_scad_from = this.dataScadDa;
            }

            var val_data_emiss_from = "";
            if (this.dataEmissDa != null) {
                val_data_emiss_from = this.dataEmissDa;
            }

            this.$root.$children[0].valDateFrom = val_data_scad_from;

            window.$cookies.set("sel_filter_inv_date_scad_from", val_data_scad_from, "9y");

            window.$cookies.set("sel_filter_inv_date_emiss_from", val_data_emiss_from, "9y");





            console.log("SEL PAG: ", this.selPag);

            var v_filter = "";

            if (this.selPag == 2) {
                v_filter = "S";
            }
            else if (this.selPag == 3) {
                v_filter = "N";
            }
            else if (this.selPag == 4) {
                v_filter = "P";
            }
            else if (this.selPag == 5) {
                v_filter = "NP";
            }


            window.curPayment = v_filter;


            $(".checkall").prop("checked", false);

            this.$root.$children[0].calcolatorDiv1 = false;

            window.totale_selezionato = 0;


            this.$root.$children[0].viewLeftArrowSelMonthExpired = false;
            this.$root.$children[0].viewSelMonthExpired = false;
            this.$root.$children[0].viewRightArrowSelMonthExpired = false;

            if (this.dataScadA != null) {


                console.log("DATA SCAD A: ", this.dataScadA);

                this.$root.$children[0].viewLeftArrowSelMonthExpired = true;
                this.$root.$children[0].viewSelMonthExpired = true;
                this.$root.$children[0].viewRightArrowSelMonthExpired = true;


                var array_months = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];

                var eleDate = "";


                console.log("DATA: ", window.dateScadA.toString().split("-"));



                eleDate = window.dateScadA.toString().split("-");



                var descMese = array_months[parseInt(eleDate[1] - 1)];

                window.currentMonth = eleDate[1];

                console.log("MESE: ", descMese);

                this.$root.$children[0].meseSelezionato = descMese;





            }

            window.table.ajax.reload();

        },


        btnCancelCalculator: function () {

            this.$modal.hide('popupCalculator');

        },


        exportGridToPdf: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");
            //var v_id_azienda = window.$cookies.get("sel_filter_azienda_fatture");


            var v_id_azienda = window.company_id;


            var type_subject = 2;

            console.log("TOKEN: ", v_token);
            console.log("ID_AZIENDA: ", v_id_azienda);
            console.log("TYPE_SUBJECT: ", type_subject);
            console.log("FILTER SUPPLIER: ", window.curSupplier);
            console.log("FILTER PAYMENT: ", window.curPayment);
            console.log("FILTER DATA SCAD DA: ", window.dateScadDa);
            console.log("FILTER DATA SCAD A: ", window.dateScadA);
            console.log("FILTER DATA EMISS DA: ", window.dateEmissDa);
            console.log("FILTER DATA EMISS A: ", window.dateEmissA);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiinvoice.exportGridPdfFornFatt(
                v_token,
                v_id_azienda,
                type_subject,
                window.curSupplier,
                window.curPayment,
                window.dateScadDa,
                window.dateScadA,
                window.dateEmissDa,
                window.dateEmissA
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from exportGridPdfFornFatt", res);



                var file_path = res.data.url_file;
                var a = document.createElement('A');
                a.href = file_path;
                a.target = "_blank";
                a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        btnConfirmError: function () {

            this.$modal.hide('popupError');


        },



        syncAziendaFiltro: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");
            var v_company = window.$cookies.get("sel_filter_azienda_fatture");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiinvoice.getAziendaFiltro(
                v_token,
                v_company
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getAziendaFiltro", res);


                that.$root.$children[0].nomeAziendaSelezionata = res.data.azienda;


                window.numTotBanche = res.data.n_banche;
                window.numTotTipiPag = res.data.n_tipi_pagamento;
                window.numTotValute = res.data.n_valute;
                window.numTotIva = res.data.n_iva;
                window.numTotUDM = res.data.n_udm;
                window.numTotClienti = res.data.n_customer;
                window.numTotFornitori = res.data.n_supplier;

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        btnConfirmSearch: function () {

            window.curSrcText = this.textSearch;

            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;


            window.table.ajax.reload();


        },

        btnCancelSearch: function () {

            this.$modal.hide('popupSearch');

        },

        pdfAnticipo: async function (id_fatt) {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiinvoice.validatePdfAnticipo(
                v_token,
                id_fatt
            ).then(async (res) => {

                // that.$root.$children[0].showProgress = false;
                console.log("res from validatePdfAnticipo", res);


                if (res.data.Result == "OK") {



                    var response2 = await apiinvoice.createPdfAnticipo(
                        v_token,
                        id_fatt
                    ).then((res2) => {

                        that.$root.$children[0].showProgress = false;
                        console.log("res from createPdfAnticipo", res2);


                        if (res2.data.Result == "OK") {


                            console.log(response2);

                            window.open(res2.data.val_url, "_blank");




                        }
                        else {

                            that.$root.$children[0].showProgress = false;

                            that.$swal({
                                icon: "error",
                                text: res2.data.Errore,
                                showConfirmButton: false,
                                timer: 2000
                            });


                        }





                    }).catch(err2 => {
                        that.$root.$children[0].showProgress = false;
                        console.log(err2);
                        var msg2 = er2.response.data.Error;

                        that.$swal({
                            icon: "error",
                            text: msg2,
                            showConfirmButton: false,
                            timer: 8000
                        });
                        console.log("Errori", "Non è stato possibile caricare i dati");
                        console.log("response", response);

                    }

                    );





                }
                else {

                    that.$root.$children[0].showProgress = false;

                    that.$swal({
                        icon: "error",
                        text: res.data.Errore,
                        showConfirmButton: false,
                        timer: 2000
                    });


                }





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        formatMoney: function (number, decPlaces, decSep, thouSep) {
            decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
                decSep = typeof decSep === "undefined" ? "," : decSep;
            thouSep = typeof thouSep === "undefined" ? "." : thouSep;
            var sign = number < 0 ? "-" : "";
            var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
            var j = (j = i.length) > 3 ? j % 3 : 0;

            return sign +
                (j ? i.substr(0, j) + thouSep : "") +
                i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
                (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");

        },

        updateCalculatorGlobal: function () {

            var tot = 0;

            window.totale_selezionato = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                    var v_row = $(this).parent().parent();

                    var item = window.table.row(v_row).data();

                    console.log("ROM SELECTED: ", item);


                    var val = item.totale_pagare_calc;

                    var v_imp = val.toString().replace(".", "");
                    v_imp = v_imp.toString().replace(",", ".");


                    window.totale_selezionato = parseFloat(window.totale_selezionato) + parseFloat(v_imp);


                }

            });

            if (tot > 1) {

                this.$root.$children[0].calcolatorDiv1 = true;

                setTimeout(() => {

                    $("#vTotCalc").html(this.formatMoney(window.totale_selezionato));

                }, 200);



            }
            else {

                this.$root.$children[0].calcolatorDiv1 = false;


            }



            // if (tot > 0) {




            // }


            // if (tot == 0) {


            //     this.$root.$children[0].calcolatorDiv1 = false;

            // }

        },

        pdfFattura: async function (id_fatt) {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiinvoice.validatePdfFattura(
                v_token,
                id_fatt
            ).then(async (res) => {

                // that.$root.$children[0].showProgress = false;
                console.log("res from validatePdfFattura", res);


                if (res.data.Result == "OK") {



                    var response2 = await apiinvoice.createPdfFattura(
                        v_token,
                        id_fatt
                    ).then((res2) => {

                        that.$root.$children[0].showProgress = false;
                        console.log("res from createPdfFattura", res2);


                        if (res2.data.Result == "OK") {


                            console.log(response2);

                            window.open(res2.data.val_url, "_blank");




                        }
                        else {

                            that.showSpinner = true;
                            that.$root.$children[0].showProgress = true;

                            that.$swal({
                                icon: "error",
                                text: res2.data.Errore,
                                showConfirmButton: false,
                                timer: 2000
                            });


                        }





                    }).catch(err2 => {
                        that.$root.$children[0].showProgress = false;
                        console.log(err2);
                        var msg2 = er2.response.data.Error;

                        that.$swal({
                            icon: "error",
                            text: msg2,
                            showConfirmButton: false,
                            timer: 8000
                        });
                        console.log("Errori", "Non è stato possibile caricare i dati");
                        console.log("response", response);

                    }

                    );





                }
                else {

                    that.$root.$children[0].showProgress = false;

                    that.$swal({
                        icon: "error",
                        text: res.data.Errore,
                        showConfirmButton: false,
                        timer: 2000
                    });


                }





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        updateCalculator: function (val, op) {

            var v_imp = val.toString().replace(".", "");
            v_imp = v_imp.toString().replace(",", ".");


            if (op == "+") {

                window.totale_selezionato = parseFloat(window.totale_selezionato) + parseFloat(v_imp);

            }

            if (op == "-") {

                window.totale_selezionato = parseFloat(window.totale_selezionato) - parseFloat(v_imp);

            }

            console.log("CALC TOT. ", window.totale_selezionato);

            var tot = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                }

            });


            console.log("TOT SEL. ", tot);

            if (tot > 1) {

                this.$root.$children[0].calcolatorDiv1 = true;

                setTimeout(() => {

                    $("#vTotCalc").html(this.formatMoney(window.totale_selezionato));

                }, 200);



            }
            else {

                this.$root.$children[0].calcolatorDiv1 = false;


            }




        },

        filterPagamento: async function (status) {

            console.log(status);

            this.sheetPagamenti = false;

            var v_filter = "";

            if (status == "Si") {
                v_filter = "S";
            }
            else if (status == "No") {
                v_filter = "N";
            }
            else if (status == "Parziali") {
                v_filter = "P";
            }
            else if (status == "No / Parziali") {
                v_filter = "NP";
            }


            window.curPayment = v_filter;

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            $(".checkall").prop("checked", false);

            this.$root.$children[0].calcolatorDiv1 = false;

            window.totale_selezionato = 0;

            window.table.ajax.reload();



        },


        manageClickFornitori: async function (fornitore) {

            console.log("FORN SEL: ", fornitore);

            this.sheetFornitori = false;

            window.curSupplier = fornitore.companyid;

            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;


            $(".checkall").prop("checked", false);

            this.$root.$children[0].calcolatorDiv1 = false;

            window.totale_selezionato = 0;

            window.table.ajax.reload();


        },





        syncFornitori: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiinvoice.getClientiFattureFornitori(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getClientiFattureFornitori", res);

                this.fornitori = res.data.supplier;



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },

        hideShowButtonsSelRow: function () {

            var tot = 0;
            var has_ordine = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                    has_ordine = $(this).attr("has-ordine");

                    // console.log("HAS ORDINE1: ", has_ordine);

                }


            });

            console.log("HAS ORDINE2: ", has_ordine);

            setTimeout(() => {

                var pulsantis = [];

                if (tot == 0) {

                    pulsantis.push({
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/xbox-cross-invoiceweb.png",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                        title: "Aggiungi",
                        width: 30

                    });


                }


                if (tot == 1) {

                    if (has_ordine > 0) {


                        pulsantis.push({
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/edit-row-invoiceweb.png",
                            link: "/info",
                            id: "btn_edit",
                            disabled: false,
                            title: "Modifica",
                            width: 30
                        });


                    }





                }




                this.$root.$children[0].bnavbuttons = pulsantis;



            }, 100);



        },

        setupGrid: function () {

            // var that = this;
            //  var pathLocal = that.$withBase('/');


            var pointerVue = this;


            var filtri = ``;


            filtri = `<img src='/invoiceweb/img/update-left-rotation.png' style='border:0!important;' class='selaggiorna' title='Aggiorna' /><div class='separator'></div><img src='/invoiceweb/img/aziende-invoice.png' style='border:0!important;' class='selazienda' title='Azienda' /><div class='separator'></div><img src='/invoiceweb/img/customers.png' style='border:0!important;' class='selfornitore' title='Clienti' /><div class='separator'></div><img src='/invoiceweb/img/pagamento.png' style='border:0!important;' class='selpagamento' title='Pagamento' /><div class='separator'></div><img src='/invoiceweb/img/usr__NM__ico__NM__icon_grid_to_pdf_32.png' style='border:0!important;' class='selexportgridpdf' title='Esporta Griglia In Pdf' />`;






            const date = new Date().toJSON().slice(0, 10)

            //console.log("DATE: " + date) //2015-07-23

            //  var $futureTime = dateTest2;

            $(document).ready(function () {

                var v_token = window.$cookies.get("token");
                // var v_company = window.$cookies.get("sel_filter_azienda_fatture");

                window.columns = [

                    {
                        data: 'sel_row',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },

                    {
                        data: 'DT_RowIndex',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },


                    {
                        data: 'download_attachment',
                        orderable: false,
                        name: 'download_attachment',
                        width: '1%'
                    },

                    {
                        data: 'codice_inv',
                        orderable: true,
                        name: 'codice_inv',
                        width: '1%'
                    },

                    {
                        data: 'full_num_order',
                        orderable: true,
                        name: 'full_num_order',
                        width: '3%'
                    },


                    {
                        data: 'desc_customer',
                        orderable: true,
                        name: 'desc_customer',
                        width: '6%'
                    },

                    {
                        data: 'stato_pagamento',
                        orderable: false,
                        name: 'stato_pagamento',
                        width: '2%'
                    },


                    {
                        data: 'data_start_inv',
                        orderable: true,
                        name: 'data_start_inv',
                        width: '3%'
                    },

                    {
                        data: 'data_scadenza_inv',
                        orderable: false,
                        name: 'data_scadenza_inv',
                        width: '3%'
                    },

                    {
                        data: 'numero_ordine_fornitore',
                        orderable: false,
                        name: 'numero_ordine_fornitore',
                        width: '3%'
                    },

                    {
                        data: 'desc_currency',
                        orderable: false,
                        name: 'desc_currency',
                        width: '2%'
                    },

                    {
                        data: 'totale_fornitore',
                        orderable: false,
                        name: 'totale_fornitore',
                        width: '1%'
                    },

                    {
                        data: 'totale_iva_fornitore',
                        orderable: false,
                        name: 'totale_iva_fornitore',
                        width: '3%'
                    },

                    {
                        data: 'totale_pagare_fornitore',
                        orderable: false,
                        name: 'totale_pagare_fornitore',
                        width: '3%'
                    },

                    {
                        data: 'partially_paid',
                        orderable: false,
                        name: 'partially_paid',
                        width: '3%'
                    },

                    {
                        data: 'paid',
                        orderable: false,
                        name: 'paid',
                        width: '3%'
                    },

                    {
                        data: 'remaining_fornitore',
                        orderable: false,
                        name: 'remaining_fornitore',
                        width: '3%'
                    },



                    {
                        data: 'note_supp',
                        orderable: false,
                        name: 'note_supp',
                        width: '3%'
                    },

                    {
                        data: 'status_supplier_inv',
                        orderable: false,
                        name: 'status_supplier_inv',
                        width: '1%'
                    },





                ];



                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: false,
                    paging: false,
                    serverSide: false,

                    "sDom": "lfrti",


                    data: window.dataset,

                    ajax: {


                        url: pointerVue.getUrlDomain("InvoiceWebGetFornitoriFattureApi") + '?username=' + v_token + "&type_subject=2",

                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start = window.perpage;

                            valori.start = 0;
                            valori.length = window.perpage;
                            valori.currDay = window.currDay;
                            valori.currDayTo = window.currDayTo;
                            valori.curStatus = window.curStatus;
                            valori.curPiano = window.curPiano;

                            valori.curSupplier = window.curSupplier;
                            valori.curPayment = window.curPayment;

                            valori.dateScadDa = window.dateScadDa;
                            valori.dateScadA = window.dateScadA;

                            valori.dateEmissDa = window.dateEmissDa;
                            valori.dateEmissA = window.dateEmissA;


                            valori.curSrcText = window.curSrcText;

                            valori.company_id = window.company_id;


                            window.valori = valori;
                            return valori;

                        }

                    },



                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();


                        setTimeout(() => {

                            pointerVue.showSpinner = false;
                            pointerVue.$root.$children[0].showProgress = false;


                        }, 2000);

                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {

                            //   $("#myTable_filter").width($("#myTable").width());

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();



                        $('.p_subtotale, .p_totale_iva, .p_totale_pagare, .p_remaining').number(true, 2, ',', '.');

                        var subtotale = 0;
                        $(".p_subtotale").each(function () {
                            subtotale = subtotale + parseFloat($(this).text().replace(".", "").replace(",", "."));
                        });

                        var totale_iva = 0;
                        $(".p_totale_iva").each(function () {
                            totale_iva = totale_iva + parseFloat($(this).text().replace(".", "").replace(",", "."));
                        });

                        var totale_pagare = 0;
                        $(".p_totale_pagare").each(function () {
                            totale_pagare = totale_pagare + parseFloat($(this).text().replace(".", "").replace(",", "."));
                        });

                        var remaining = 0;
                        $(".p_remaining").each(function () {
                            remaining = remaining + parseFloat($(this).text().replace(".", "").replace(",", "."));
                        });


                        $("#v_tot_subtotale").text(subtotale.toString().replace(".", ","));
                        $('#v_tot_subtotale').number(true, 2, ',', '.');

                        $("#v_tot_iva").text(totale_iva.toString().replace(".", ","));
                        $('#v_tot_iva').number(true, 2, ',', '.');

                        $("#v_tot_totale").text(totale_pagare.toString().replace(".", ","));
                        $('#v_tot_totale').number(true, 2, ',', '.');

                        $("#v_tot_rimanente").text(remaining.toString().replace(".", ","));
                        $('#v_tot_rimanente').number(true, 2, ',', '.');

                    }

                });

                //Events
                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();

                            setTimeout(() => {

                                $(".spin").hide();

                            }, 500);

                            var valori = window.valori;
                            valori.start = window.start;

                            $.ajax({


                                url: pointerVue.getUrlDomain("InvoiceWebGetFornitoriFattureApi") + '?username=' + v_token + "&type_subject=2",

                                data: window.valori,
                                success: function (data) {
                                    // $(".spin").hide();
                                    window.loading = 0;
                                    window.start += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log(index, val);
                                        window.table.row.add(val).draw(false);
                                        //  window.dataset.push(val);
                                    }
                                    console.log(data.data);
                                },
                            });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                //alert (pathLocal);

                $("#myTable_filter").find("label").remove();

                $("#myTable_filter").prepend(`
                <div class="cont_buttons">
                
                    <div class='wrap_buttons'>
                    
                    ` + filtri + `
                    
                    </div>

                 
                </div>
                <div><img src='/invoiceweb/img/ricerca_invoiceweb.png' style='border:0!important;' class='selricerca' title='Ricerca' /></div>

            `);


                $('body').on('click', '.selexportgridpdf', function () {
                    console.log("EVENT SELEXPORTGRIDPDF");


                    pointerVue.exportGridToPdf();

                });


                $(".selricerca").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.textSearch = "";

                    $(".contpopupsearch").show();

                    pointerVue.$modal.show('popupSearch');

                });



                $('body').on('click', '.selfornitore', function () {

                    console.log(("EVENTO CLICK SELFORNITORE"));

                    pointerVue.sheetFornitori = true;

                });


                $('body').on('click', '.selazienda', function () {

                    console.log(("EVENTO CLICK SELAZIENDA"));

                    pointerVue.sheetAziende = true;

                });



                $('body').on('click', '.selpdffatture', function () {

                    console.log(("EVENTO CLICK SELPDFFATTURE"));

                    var errore = "";

                    var tot = 0;
                    var ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }

                            ids = ids + $(this).attr("data-id");

                            tot = tot + 1;

                        }

                    });


                    if (tot == 0) {

                        errore = "Selezionare almeno una fattura";

                    }

                    if (errore == "") {

                        if (tot > 1) {

                            errore = "Selezionare solo una fattura";

                        }


                    }


                    if (errore == "") {

                        pointerVue.pdfFattura(ids);


                    }

                    if (errore != "") {

                        pointerVue.$swal({
                            icon: "error",
                            text: errore,
                            showConfirmButton: false,
                            timer: 2000
                        });



                    }






                });



                $('body').on('click', '.selpdfanticipi', function () {

                    console.log(("EVENTO CLICK SELPDFANTICIPI"));

                    var errore = "";

                    var tot = 0;
                    var ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }

                            ids = ids + $(this).attr("data-id");

                            tot = tot + 1;

                        }

                    });


                    if (tot == 0) {

                        errore = "Selezionare almeno una fattura";

                    }

                    if (errore == "") {

                        if (tot > 1) {

                            errore = "Selezionare solo una fattura";

                        }


                    }


                    if (errore == "") {

                        pointerVue.pdfAnticipo(ids);


                    }

                    if (errore != "") {

                        pointerVue.$swal({
                            icon: "error",
                            text: errore,
                            showConfirmButton: false,
                            timer: 2000
                        });



                    }






                });




                $('body').on('click', '.selaggiorna', function () {

                    $(".checkall").prop("checked", false);

                    pointerVue.$root.$children[0].calcolatorDiv1 = false;

                    window.totale_selezionato = 0;

                    pointerVue.showSpinner = true;
                    pointerVue.$root.$children[0].showProgress = true;

                    window.table.ajax.reload();

                });

                $('body').on('click', '.inviamessaggio', function () {

                    console.log("EVENT INVIAMESSAGGIO");

                    var tot = 0;
                    var ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }

                            ids = ids + $(this).attr("data-id");

                            tot = tot + 1;

                        }

                    });

                    console.log("IDS: ", ids);

                    if (tot > 0) {

                        window.$cookies.set("id_users_doc_send", ids, "9y");

                        router.push({
                            path: "/sendMessage"
                        });

                    }

                    if (tot == 0) {

                        pointerVue.$swal({
                            icon: "error",
                            text: "Select the users to send the message to",
                            showConfirmButton: false,
                            timer: 2000
                        });


                    }



                });



                $('body').on('click', '.paginate_button', function () {
                    $(".spin").show();

                });

                $('body').on('click', '.refreshButton', function () {

                    $(".spin").show();
                    window.table._fnDraw();

                });


                $('body').on('click', '.cont_fornitori_fatt tr', function () {

                    console.log("TR-ACTIVE");

                    setTimeout(() => {

                        var row_data = window.table.row(this).data();

                        if (row_data != null) {


                            var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            var id_sel = $(this).find(".id_row").attr("data-id");

                            //$("tr").removeClass("active-row");

                            var set_cls_active = 0;

                            if (last_id_sel == "undefined") {
                                set_cls_active = 1;
                            } else {
                                if (last_id_sel != id_sel) {
                                    set_cls_active = 1;
                                }
                            }

                            console.log("ROM IS ACTIVE: ", set_cls_active);



                            console.log("THIS: ", this);

                            var hasClassActive = $(this).hasClass("active-row");

                            if (hasClassActive) {

                                console.log("ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", false);

                                $(this).removeClass("active-row");

                            }

                            if (!hasClassActive) {

                                console.log("NOT ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", true);

                                $(this).addClass("active-row");



                            }

                            window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            if (window.id_par_sel == undefined) {
                                window.id_par_sel = id_sel;
                            }

                            console.log("ID SELECTED: ", window.id_par_sel);

                            console.log("FIND VAL_STATUS: ", $(this).find(".val_status"));

                            var find_check = $(this).find(".val_status");
                            if (find_check.is(":checked")) {
                                console.log("SELEZIONATO");

                                //   pointerVue.updateCalculator(row_data.totale_pagare_calc, "+");
                            }

                            if (!find_check.is(":checked")) {
                                console.log("DESELEZIONATO");

                                // pointerVue.updateCalculator(row_data.totale_pagare_calc, "-");
                            }



                            pointerVue.hideShowButtonsSelRow();


                        }



                    }, 100);

                });

                $(".selcambiaclientifornitori").on("ionChange", function (opt) {
                    console.log(opt);

                    $(".checkall").prop("checked", false);

                    pointerVue.$root.$children[0].calcolatorDiv1 = false;

                    window.totale_selezionato = 0;


                    if (this.value == 1) {

                        var v_agila = window.$cookies.get("is_agila");

                        window.$cookies.set("is_from_suppliers", 1, "9y");

                        console.log("IS AGILA: ", v_agila);


                        if (v_agila == 0) {

                            router.push({
                                path: "/fattureClienti"
                            });


                        }

                        if (v_agila == 1) {

                            router.push({
                                path: "/fattureClientiAgila"
                            });


                        }



                        // router.push({
                        //     path: "/fattureClienti"
                        // });



                    }


                });



                $('body').on('click', '.val_status', function (event) {
                    event.stopPropagation();

                    var checked = $(this).is(":checked");

                    console.log("CHECKED: ", checked);

                    var v_row = $(this).parent().parent();

                    console.log("V_ROW: ", v_row);

                    if (checked) {

                        $(v_row).addClass("active-row");

                        var item = window.table.row(v_row).data();

                        console.log("ITEM ROW: ", item);


                        window.id_par_sel = item.id;

                        //pointerVue.updateCalculator(item.totale_pagare_calc, "+");

                    }

                    if (!checked) {

                        // var item2 = window.table.row(v_row).data();

                        $(v_row).removeClass("active-row");

                        // pointerVue.updateCalculator(item2.totale_pagare_calc, "-");

                    }

                    pointerVue.hideShowButtonsSelRow();

                });



                // }

                //setup ionic calendar top
                setTimeout(function () {

                    $("body").on("ionChange", ".dateBook", function (opt) {

                        console.log(opt);
                        var date = this.value;
                        //var dateMysql = date.toJSON().slice(0, 19).replace('T', ' ')   ;
                        dateMysql = date.substr(0, 10);

                        console.log(dateMysql);

                        $.ajax({
                            url: "{{ route('smartbook.updateDate') }}",
                            data: {
                                "id": $(this).data("id"),
                                "date": dateMysql
                            },
                        });

                    });

                    $(".selectstatusall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curStatus = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    $(".selectpianiall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curPiano = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    window.currDay = date;
                    window.currDayTo = date;

                }, 600);

                window.parentiAddedEvents = true;

            });

        },


        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/xbox-cross-invoiceweb.png",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            title: "Aggiungi",
                            width: 30

                        },

                        // {
                        //     text: "Indietro",
                        //     icon: "mdi-arrow-left",
                        //     link: "/confOrariChoice",
                        //     //class: "inverted",
                        //     id: "btn_back",
                        //     disabled: false,
                        //     title: "Indietro",
                        //     image: "https://app.emtool.eu/public/_lib/img/back-invoiceweb.png",
                        //     width: 35

                        // },

                    ];

                }


                if (v_enable == true) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/xbox-cross-invoiceweb.png",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            title: "Aggiungi",
                            width: 30

                        },

                        {
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/edit-row-invoiceweb.png",
                            link: "/info",
                            id: "btn_edit",
                            disabled: false,
                            title: "Modifica",
                            width: 30

                        },

                        {
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/delete-invoiceweb.png",
                            link: "/info",
                            id: "btn_delete",
                            disabled: false,
                            title: "Elimina",
                            width: 30

                        },

                        // {
                        //     text: "Indietro",
                        //     icon: "mdi-arrow-left",
                        //     link: "/confOrariChoice",
                        //     //class: "inverted",
                        //     id: "btn_back",
                        //     disabled: false,
                        //     title: "Indietro",
                        //     image: "https://app.emtool.eu/public/_lib/img/back-invoiceweb.png",
                        //     width: 35

                        // },

                    ];

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/xbox-cross.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                    },

                    {
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/usr__NM__icon_edit_row_16.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                    },

                    {
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/usr__NM__icon_delete_32.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                    },

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/qrconfirm",
                        id: "btn_qrcode",
                        image: "https://app.smart-book.it/public/smartbook/ICON_3.png",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                    {
                        text: "Pdf",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/sb-export-pdf-50.png",
                        class: "inverted",
                        link: "/pdf",
                        id: "btn_pdf",
                        disabled: false,
                    },

                ];

                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },



        deleteRow: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.$swal

                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare gli elementi selezionati ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_invoiceweb.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_invoiceweb.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });

                        console.log("IDS SEL:", ids);



                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;



                        var response = await apiinvoice.deleteFattureFornitori(
                            v_token,
                            ids
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteFattureFornitori", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.hideShowButtons(false);

                                window.table.ajax.reload();


                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },

    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.cont_fornitori_fatt {
    overflow-x: auto !important;
}


.cont-table .spin {

    display: flex;
    position: absolute;
    z-index: 100;
    min-width: 100vw;
    min-height: 100vh;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
}

.cont_fornitori_fatt .dataTable tbody tr,
.cont_fornitori_fatt .dataTable tbody tr td {
    vertical-align: middle !important;
    padding-top: 5px !important;
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #f5cf2e;

}

.pay a {
    color: #f5cf2e;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #f5cf2e;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.table-responsive {
    max-width: 100vw;
}

ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    /* border-radius: 12px; */
    /* min-height: 12px; */
    /* transform: translateY(-2px); */
    background-image: url(/smartb/img/FiltroNucleo.png);
    /* padding: 1px 11px; */
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);
}

.cont_fornitori_fatt #myTable {
    margin-right: 3px;
    width: 1500px !important;
}

.cont_fornitori_fatt table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_fornitori_fatt table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
    padding-top: 5px !important
}

.cont_fornitori_fatt th {
    padding-left: 5px !important;
}

.cont_fornitori_fatt ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_fornitori_fatt .table-responsive {
    padding: 0px;
    min-width: 80vw;
    max-width: 96vw;
}

.cont_fornitori_fatt #myTable_filter {
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    background-color: white;
}

.cont_fornitori_fatt div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cont_fornitori_fatt #myTable_wrapper {
    min-width: initial;
    min-width: 1600px;
    max-width: 1600px;
    width: 1600px;
}


.cont_fornitori_fatt .text_title {
    background-color: white;
    color: black;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 7px;
    font-size: 10px;
    min-width: 50px;
    text-align: left;
}

.cont_fornitori_fatt .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}

.swal2-styled.swal2-cancel {
    border: 0 !important;
    border-radius: .25em !important;
    background: initial !important;
    background-color: #fff !important;
    color: #fff !important;
    font-size: 1em !important;
}

.swal2-styled.swal2-confirm {
    background-color: #fff !important;
    color: black !important;
    background: initial !important;
}


.cont_fornitori_fatt .cl_checkall {
    padding-left: 1px;
    padding-top: 2px;
}

.cont_fornitori_fatt .checkall {
    width: 13px;
    height: 13px;
}

.cont_fornitori_fatt .inviamessaggio {
    max-width: 25px;
    min-height: 25px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.cont_fornitori_fatt .cont_buttons {
    display: flex;
    align-items: center;
    float: left;
    /* min-width: 50px; */
    padding-left: 10px;
    justify-content: inherit;
    width: 100% !important;
}

.cont_fornitori_fatt .img_centered {
    width: 100% !important;
    text-align: center !important;
    min-width: 36px;
}


.cont_fornitori_fatt .cont-status {
    position: relative !important;
    overflow: hidden !important;
    width: 20px !important;
    height: 10px !important;
    margin-left: -10px !important;
}

.cont_fornitori_fatt .status {
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 50%;
    top: -11px;
    right: -11px;
}

.cont_fornitori_fatt .green {
    background: green;
}

.cont_fornitori_fatt .yellow {
    background: #ecec06;
}

.cont_fornitori_fatt .red {
    background: red;
}

.cont_fornitori_fatt .lightblue {
    background: lightblue;
}

.cont_fornitori_fatt .orange {
    background: orange;
}

.cont_fornitori_fatt .text_right {
    width: 100% !important;
    text-align: right !important;
}



.cont_fornitori_fatt .selcambiaclientifornitori {
    max-width: 30px !important;
    min-height: 30px !important;
    margin-left: -5px !important;
    color: black !important;
    padding: 0px 0px !important;
    min-width: 14px !important;
    text-indent: -119px !important;
    background-size: 29px !important;
    background-repeat: no-repeat !important;
    background-image: url(/invoiceweb/img/change_invoiceweb2.png) !important;
    z-index: 2 !important;
    border: none !important;
    cursor: pointer !important;
    margin-top: 2px !important;
}

.cont_fornitori_fatt .selfornitore {
    max-width: 28px;
    min-height: 28px;
    margin-left: -1px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}

.cont_fornitori_fatt .selanticipi {
    max-width: 28px;
    min-height: 28px;
    margin-left: 1px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_fornitori_fatt .seleliminaanticipi {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_fornitori_fatt .selscadenziario {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}


.cont_fornitori_fatt .selpagamento {
    max-width: 28px;
    min-height: 28px;
    margin-left: 1px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}


.cont_fornitori_fatt .selpdffatture {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_fornitori_fatt .selpdfanticipi {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_fornitori_fatt .silinvfattcust {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_fornitori_fatt .sendmailalertcli {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_fornitori_fatt .selexportgridpdf {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_fornitori_fatt .selcopiafatt {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}





.cont_fornitori_fatt .seldate {
    max-width: 28px;
    min-height: 28px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}




.headerSheetFornitori {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.itemFornitore {
    margin-left: 15px;
}



.cont_fornitori_fatt div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0.5em !important;
    display: inline-block !important;
    width: auto !important;
    z-index: 99999 !important;
    min-width: 30px !important;
    min-height: 30px !important;
    background: white !important;
    border-radius: 50pxv;
    border: 1px solid black !important;
}


.cont_fornitori_fatt .wrap_buttons {
    max-height: 40px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}


.cont_fornitori_fatt .cl_valuta {
    font-weight: bold;
    text-align: center
}


.cont_fornitori_fatt .text_right2 {
    width: 100% !important;
    text-align: right !important;
}



.cont_fornitori_fatt .col_footer {
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    padding-left: 6px;
    border-top: 1px solid black
}

.cont_fornitori_fatt .col_footer_right {
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    padding-left: 6px;
    border-top: 1px solid black;
    text-align: right;
    padding-right: 6px;
}

.cont_fornitori_fatt .val_status {
    width: 10px !important;
}

.cl-div-hid {
    display: none;
}

.cont_fornitori_fatt .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}

.cont_fornitori_fatt .selazienda {
    max-width: 26px;
    min-height: 26px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 26px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 2px;

}


.cl-tot-header {
    text-align: right;
    padding-right: 5px
}

@media screen and (max-width: 960px) {

    .cont_fornitori_fatt .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_fornitori_fatt #myTable {
        /* margin-top:54px; */
    }

    .cont_fornitori_fatt #myTable_filter {
        top: 56px;
        position: fixed;

    }

    .cont_fornitori_fatt .cont_buttons {
        /* max-width: 218px; */
        height: 40px;
        overflow-y: hidden;
        overflow-x: auto;
        padding-left: 0px;
    }

    .cont_fornitori_fatt .wrap_buttons {
        max-height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .cont_fornitori_fatt .text_title {
        background-color: white;
        color: black;
        font-weight: bold;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 9px;
        font-size: 10px;
        min-width: 50px;
        text-align: left;
        height: 100%;
        display: flex;
        align-items: center;
    }

}

@media screen and (max-width: 1024px) {

    .contIcon {
        display: inherit;
    }

    .cont_fornitori_fatt .table-responsive {
        padding: 0px;
        min-width: 80vw;
        max-width: 96vw;
        left: 0px;
        position: fixed;
    }

    .cont_fornitori_fatt #myTable {
        max-width: 100vw !important;
        margin-right: 0px;
        min-width: 100vw !important;
        position: relative;
        width: 100vw !important;
    }

    .cont_fornitori_fatt #myTable_wrapper {
        min-width: initial;
        min-width: 100vw !important;
        max-width: 100vw !important;
        width: 100vw !important;
        overflow-x: hidden !important;
    }


    .cont_fornitori_fatt .cl_num {
        margin-left: -2px !important;
    }

    .cont_fornitori_fatt .cl_checkall {
        margin-left: 19px !important;
    }

    .cont_fornitori_fatt .img_centered {
        display: inline;
    }


    .cont_fornitori_fatt .cl_valuta {
        display: inline;
    }


    .cont_fornitori_fatt .text_right {
        display: inline;
    }


    .cont_fornitori_fatt .col_footer {
        padding-top: 15px !important;
        padding-bottom: 5px !important;
        padding-left: 6px;
        border-top: none;
        color: white;
    }

    .cont_fornitori_fatt .col_footer_right {
        padding-top: 15px !important;
        padding-bottom: 5px !important;
        padding-left: 6px;
        border-top: none;
        text-align: right;
        color: white;
    }


    .cl-tot-header {
        text-align: right;
        padding-right: 5px
    }

    .cl-tot-header:after {
        content: ' (€)';
    }

}
</style>
